<template>
  <div class="graph_payment">
    <Bar
      v-if="graphData.labels.length"
      :data="graphData"
      :options="graphOptions"
      style="height: 400px; width: 100%"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';

import { Bar } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';

import { formatCurrency } from '@/utils/commonUtils/utils';

Chart.register(...registerables);

const props = defineProps({
  items: Array,
});

const graphData = ref({
  labels: [],
  datasets: [
    {
      backgroundColor: '#173B57',
      borderRadius: 6,
      data: [],
      label: 'проценты',
    },
    {
      backgroundColor: '#04CCD9',
      borderRadius: 6,
      data: [],
      label: 'возврат дс',
    },
  ],
});
const graphOptions = ref({
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 5,
        pointStyle: 'circle',
        usePointStyle: true,
        color: '#10273D',
      },
    },
    tooltip: {
      backgroundColor: '#ffffff',
      titleColor: '#10273D',
      bodyColor: '#818E99',
      padding: 10,
      borderColor: 'rgba(13, 39, 65, 0.12)',
      borderWidth: 1,
      cornerRadius: 8,
      callbacks: {
        label: (value) => {
          return `${formatCurrency(value.raw, 0)} ₽ - ${value.dataset.label}`;
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `${formatCurrency(value, 0)} ₽`;
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
});

const graphLabels = computed(() => {
  return props.items.map((i) => i.date);
});
const graphMainDebtValues = computed(() => {
  return props.items.map((i) => i.mainDebt);
});
const graphPercentageValues = computed(() => {
  return props.items.map((i) => i.percentage);
});

onMounted(() => {
  graphData.value.labels = graphLabels.value;
  graphData.value.datasets[0].data = graphPercentageValues.value;
  graphData.value.datasets[1].data = graphMainDebtValues.value;
});
</script>
<style lang="scss" scoped>
.graph_payment {
  margin-top: 20px;
  background-color: white;
  padding: 30px;
  border-radius: 14px;
}
</style>
