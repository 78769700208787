<template>
  <div class="image_upload">
    <div>
      <img-inputer
        ref="imgInputer"
        v-model="selectedFile"
        :img-src="fileUrl"
        :file-type="fileType"
        :accept="mimeType"
        :placeholder="props.placeholder"
        :max-size="20000"
        bottom-text="Нажмите чтобы изменить"
        exceed-size-text="Файл не более: "
        theme="light"
        @on-change="onChangeSelectedFile"
      />
    </div>
    <div v-if="props.disabled" class="image_upload_shadow"></div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import ImgInputer from '@/libs/vue-img-inputer';
import { parseFilename } from '@/utils/commonUtils/utils';
import server from '@/server/index';

const props = defineProps(['fileId', 'placeholder', 'disabled']);
const emits = defineEmits(['selectedFile']);

const selectedFile = ref(null);
const mimeType = 'image/png, image/jpeg, image/heic,.pdf';
const fileLoadedUrl = ref(null);
const filename = ref(null);
const fileType = ref(null);
const loading = ref(false);

const fileUrl = computed(() => {
  if (!selectedFile.value && fileLoadedUrl.value) {
    return fileLoadedUrl.value;
  }
  return null;
});
watch(
  () => {
    return props.fileId;
  },
  () => {
    if (props.fileId) {
      downloadDocumentAsync(props.fileId);
    }
  },
);

onMounted(() => {
  if (props.fileId) {
    downloadDocumentAsync(props.fileId);
  }
});

const onChangeSelectedFile = () => {
  // Костыль для того что бы библиотека загрузки изображения корректно отобразило превью картинки с форматом .heic
  if (/(?:heic)$/i.test(String(selectedFile.value.name))) {
    Object.defineProperty(selectedFile.value, 'type', {
      value: 'image',
      configurable: true,
      writable: true,
      enumerable: true,
    });

    setTimeout(() => {
      Object.defineProperty(selectedFile.value, 'type', {
        value: 'image/heic',
        configurable: true,
        writable: true,
        enumerable: true,
      });
      emits('selectedFile', selectedFile.value);
    }, 0);
  } else {
    emits('selectedFile', selectedFile.value);
  }
};
const downloadDocumentAsync = (id) => {
  loading.value = true;
  const params = {
    id,
  };
  return server.getDocument
    .send(null, { params })
    .pipe(
      onDownloadDocumentAsyncSuccess.bind(this),
      onDownloadDocumentAsyncError.bind(this),
    )
    .exec();
};
const onDownloadDocumentAsyncSuccess = ({ data, response }) => {
  loading.value = false;
  if (response.status === 200) {
    if (/^image/.test(data.type)) {
      fileType.value = 'image';
    }
    if (/pdf/.test(data.type)) {
      fileType.value = 'pdf';
    }
    filename.value = parseFilename(response);
    fileLoadedUrl.value = URL.createObjectURL(data);
  }
};
const onDownloadDocumentAsyncError = () => {
  loading.value = false;
};
</script>
<style lang="scss" scoped>
.image_upload {
  position: relative;
}
.image_upload_shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

:deep(.img-inputer) {
  .img-inputer__err {
    bottom: -25px;
  }
}
</style>
