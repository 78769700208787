<template>
  <div>
    <div class="step_title">Выберите форму собственности</div>
    <p class="step_description" style="text-transform: none">
      В Потоке вы можете инвестировать как физическое лицо, индивидуальный
      предприниматель, а также от нескольких юридических лиц, в которых вы
      являетесь генеральным директором.
    </p>
    <div>
      <div
        class="item_legal"
        :class="{
          item_legal_active: selectedCompanyTypeEntrepreneur === true,
          item_legal_non_active: isHasEntrepreneur,
        }"
        @click="selectedCompanyTypeEntrepreneur = true"
      >
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 subtitle">
            Индивидуальный предприниматель
          </div>
          <div
            class="col-md-2 col-sm-2 col-xs-2"
            style="display: flex; justify-content: flex-end"
          >
            <Checkbox
              v-if="!isHasEntrepreneur"
              v-model="selectedCompanyTypeEntrepreneur"
            />
            <div v-if="isHasEntrepreneur" class="text-add">Уже добавлен</div>
          </div>
        </div>
      </div>

      <div
        class="item_legal"
        :class="{ item_legal_active: selectedCompanyTypeCompany === true }"
        style="margin-top: 20px"
        @click="selectedCompanyTypeCompany = true"
      >
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 subtitle">
            Юридическое лицо
          </div>
          <div
            class="col-md-2 col-sm-2 col-xs-2"
            style="display: flex; justify-content: flex-end"
          >
            <Checkbox v-model="selectedCompanyTypeCompany" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <PotokDivider margin-y="20" />
      <button
        class="button button-secondary"
        type="button"
        :disabled="isDisabledContinue"
        @click="onClickContinue"
      >
        Продолжить
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import Checkbox from '@/components/_generic/Checkbox.vue';
import constants from '@/constants';

import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const emits = defineEmits(['nextStep']);
const selectedCompanyTypeEntrepreneur = ref(null);
const selectedCompanyTypeCompany = ref(null);

const companies = computed(() => {
  return store.companies;
});
const isDisabledContinue = computed(() => {
  return (
    !selectedCompanyTypeEntrepreneur.value && !selectedCompanyTypeCompany.value
  );
});
const isHasEntrepreneur = computed(() => {
  return !!companies.value.find(
    (i) => i.typeId === constants.companyTypes.entrepreneur,
  );
});

watch(selectedCompanyTypeEntrepreneur, () => {
  if (selectedCompanyTypeEntrepreneur.value) {
    selectedCompanyTypeCompany.value = false;
  }
});
watch(selectedCompanyTypeCompany, () => {
  if (selectedCompanyTypeCompany.value) {
    selectedCompanyTypeEntrepreneur.value = false;
  }
});

const onClickContinue = () => {
  if (selectedCompanyTypeEntrepreneur.value) {
    emits('nextStep', {
      nextStepId: '2_1',
    });
  }
  if (selectedCompanyTypeCompany.value) {
    emits('nextStep', {
      nextStepId: '2_2',
    });
  }
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #313132;
}
.item_legal {
  background: #ffffff;
  border-radius: 14px;
  padding: 30px 18px;
  border: 1px solid #dedee1;
  cursor: pointer;
}
.item_legal_active {
  box-shadow: 0px 16px 64px rgba(183, 203, 228, 0.5);
}
.active_item_legal {
  border: 1px solid #04ccd9;
}
.item_legal_non_active {
  .subtitle {
    color: #999999 !important;
  }
}
.text-add {
  font-size: 14px;
  line-height: 20px;
  color: #5c5c5c;
  white-space: nowrap;
  @media (max-width: $size_767) {
    white-space: initial;
    text-align: center;
  }
}
</style>
