<template>
  <div class="swicher_slider_radio">
    <div
      :class="['radio', { active: props.modelValue }]"
      @click="toggleSwitcher"
    />

    <div v-if="props.titleOff && props.modelValue" class="swicher_slider_text">
      {{ props.titleOff }}
    </div>
    <div v-if="props.titleOn && !props.modelValue" class="swicher_slider_text">
      {{ props.titleOn }}
    </div>
    <div v-if="props.disabled" class="swicher_slider_shadow"></div>
  </div>
</template>
<script setup>
const props = defineProps(['modelValue', 'titleOff', 'titleOn', 'disabled']);
const emit = defineEmits(['update:modelValue']);

const toggleSwitcher = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.swicher_slider_radio {
  display: flex;
  align-items: center;
  position: relative;

  .swicher_slider_shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__text {
    color: #717d83;
  }

  .radio {
    margin: 0;
    width: 40px;
    height: 20px;
    background: #eee;
    border-radius: 20px;
    font-size: 0;
    position: relative;
    transition: 0.3s;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      top: 1px;
      left: 0;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 50%;
      margin: 0;
      transition: 0.3s;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    }

    &.active {
      background: $breakwater;

      &::after {
        left: 21px;
      }
    }
  }
}
</style>
