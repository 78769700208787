<template>
  <PotokPage title="пополнение счета">
    <template v-if="loading">
      <div class="icon_loading"></div>
    </template>
    <template v-if="!loading">
      <Refill :payment-methods="paymentMethods" />
    </template>
  </PotokPage>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Refill from '@/components/Refill/Refill.vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { PotokPage } from 'potok-uikit';

const store = useStateStore();

const loading = ref(false);
const paymentMethods = ref([]);

const getPaymentMethodsAsync = () => {
  loading.value = true;
  let query = {
    companyId: store.selectedCompanyId,
  };
  return server.getPaymentMethods
    .send(query)
    .pipe(
      (obj) => {
        loading.value = false;
        if (!obj) {
          return;
        }
        paymentMethods.value = obj.data || [];
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

onMounted(() => {
  getPaymentMethodsAsync();
});
</script>
<style lang="scss" scoped>
</style>
