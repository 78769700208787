import { format as formatdatetime, parseISO } from 'date-fns';
import formatPhoneWithMask from '@/utils/phone/formatPhoneWithMask';

/**
 * Throws an error when a value is falsely
 * @param {*} value
 * @param {String} [message]
 */
export const assert = function (value, message = '') {
  if (!value) {
    throw new Error(message);
  }
};

/**
 * @see https://stackoverflow.com/a/2117523
 * @return {String}
 */
export const uuidv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
/**
 * Используется для выбора окончания например ['день', 'дня', 'дней']
 * @param {Number} number
 * @param {String} titles
 * @return {String}
 */
export const pluralize = function (_number, titles) {
  let number = Math.abs(_number);
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

/**
 * Возвращает список чисел в указаном интервале включая конечное
 * @param {Number} maxOrMin
 * @param {Number} [minOrMax=0]
 * @param {Number} [step=1]
 * @returns {Array.<Number>}
 */
export const range = function (maxOrMin, minOrMax = 0, step = 1) {
  const min = Math.min(maxOrMin, minOrMax);
  const max = Math.max(maxOrMin, minOrMax);
  const len = Math.ceil((max - min + 1) / step);
  let arr = new Array(len);
  for (let i = 0; i < len; i += 1) {
    arr[i] = step * i + min;
  }
  return arr;
};
/**
 * Turns 79998887766 to 7(999)888-77-66
 * @param {String} [phone]
 * @return {String}
 */
export const formatPhone = function (phone = '') {
  const placeholder = new Array(11).fill('*');
  const phoneArr = Object.assign(placeholder, String(phone).split(''));
  if (phoneArr[0] == 7) {
    return formatPhoneWithMask('+%s (%s%s%s) %s%s%s-%s%s-%s%s', ...phoneArr);
  }
  return formatPhoneWithMask('%s (%s%s%s) %s%s%s-%s%s-%s%s', ...phoneArr);
};

export const formatCurrency = function (n, digits = 2) {
  let hasMinus = false;
  if ((n && typeof n === 'string') || n instanceof String) {
    if (n.length > 0 && n[0] === '-') {
      hasMinus = true;
    }
  }
  if (n && typeof n === 'number') {
    if (n < 0) {
      hasMinus = true;
    }
  }
  let numberAsString = Number(formatNumber(n));
  numberAsString = numberAsString.toFixed(digits);
  const pattern = /(?=\B(?:\d{3})+\b)/g;
  const result = String(numberAsString).replace(pattern, ' ');
  return hasMinus ? `-${result}` : result;
};

export const formatDate = function (date, format = 'dd.MM.yyyy', timezone) {
  if (!date) {
    return;
  }
  if (typeof date === 'object' && date instanceof Date) {
    return formatdatetime(date, format);
  }
  if (typeof date === 'string') {
    let datetime;
    if (timezone) {
      datetime = new Date(date);
      datetime.toLocaleString('ru-RU', { timeZone: timezone });
    } else {
      datetime = parseISO(date);
    }
    return formatdatetime(datetime, format);
  }
};

/**
 * @param {String} str
 * @return {String}
 */
export const formatNumber = function (str) {
  const sanitized = String(str).replace(/[^.\d]+/g, '');
  const match = sanitized.match(/\d+(\.\d+)?/);
  return match ? match[0] : '';
};

export const formatName = function (str) {
  return String(str)
    .replace(/\s{2,}/g, ' ')
    .trim()
    .toLowerCase()
    .replace(/(?:^|[- ])(\S)/g, (match) => match.toUpperCase());
};

export const formatJson = function (obj) {
  return JSON.stringify(obj, null, '  ');
};

export const copyAsJson = function (plainObject) {
  return JSON.parse(JSON.stringify(plainObject));
};

export const downloadFile = function (filename, blob) {
  let link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', filename);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
};

export const parseFilename = function (response) {
  const header = response.headers.get('Content-Disposition');
  let str = String(header).match(/filename="([^"]+)"/);
  if (str && str.length > 0) {
    return str[1];
  }
  return '';
};

/**
 * Gets [1,2,3,4,5] and returns [[1,2], [3,4], [5]]
 * @param {Array|String} input
 * @param {Number} [chunk=2]
 * @returns {Array}
 */
export const group = function (input, chunk = 2) {
  const maxI = input.length;
  const maxJ = Math.ceil(maxI / chunk);
  const out = new Array(maxJ);
  for (let i = 0, j = 0; j < maxJ; i += chunk, j++) {
    out[j] = input.slice(i, Math.min(i + chunk, maxI));
  }
  return out;
};

/**
 * @param {String} str
 * @returns {String}
 */
export const capitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * @param {Object} obj
 * @param {String} key
 * @param {*} [stubValue=null]
 * @returns {*}
 */
export const getProp = function (obj, key, stubValue = null) {
  return isObject(obj) ? obj[key] : stubValue;
};

/**
 * @param {...Function} fns
 * @returns {Function}
 */
export const compose = function (...fns) {
  return (_) => fns.reduce((acc, fn) => fn(acc), _);
};

/**
 * @param {...Function} fns
 * @returns {Function}
 */
export const composePromise = function (...fns) {
  return (_) => new Promise(next.bind(null, _, fns));
  // ----------
  function next(arg, fns, resolve, reject) {
    const fn = fns.shift() || undefined;
    const promiseOrUndefined = fn(arg);
    const onFulfilled = next.bind(null, arg, fns, resolve, reject);
    return promiseOrUndefined
      ? promiseOrUndefined.then(onFulfilled, reject)
      : resolve(arg);
  }
};

/**
 * Filters an array or an object
 * @param {Array|Object} any
 * @param {Function} callback
 * @returns {Array|Object}
 */
export const filter = function (any, callback) {
  return Array.isArray(any)
    ? any.filter(callback)
    : Object.keys(any).reduce((acc, key) => {
        return callback(any[key], key)
          ? Object.assign(acc, { [key]: any[key] })
          : acc;
      }, {});
};

/**
 * Maps arrays or objects
 * @param {Array|Object} arrOrObj
 * @param {Function} [cb]
 * @return {Array}
 */
export const map = function (arrOrObj, cb) {
  return Array.isArray(arrOrObj)
    ? arrOrObj.map(cb)
    : Object.keys(arrOrObj).map((k) => cb(arrOrObj[k], k));
};

/**
 * @param {Array} arr
 * @param {Function} [hashFn=JSON.stringify]
 * @returns {Array} arr
 */
export const unique = function (arr, hashFn = JSON.stringify) {
  const set = new Set();
  return arr.filter((item) => {
    const hash = hashFn(item);
    if (set.has(hash)) {
      return false;
    } else {
      set.add(hash);
      return true;
    }
  });
};

/**
 * @param {*} arg
 * @returns {*}
 */
export const identity = function (arg) {
  return arg;
};

/**
 * Takes A and B and returns a list of tuples (A, B) i.e. a list of pairs
 * @see https://gitlab.mmdev.ru/modulemoney/module-money-backend-gateway/merge_requests/318/diffs#3705be42d294bc4dabd48e32cb44899d6479b180_357_425
 * @param {Array.<*>} listA
 * @param {Array.<*>} listB
 * @returns {Array.<Array>}
 */
export const zip = function (listA, listB) {
  const lenA = listA.length;
  const lenB = listB.length;
  assert(lenA === lenB, 'Lists must have the same length');
  const arr = new Array(listA.length);
  for (let i = 0; i < lenA; i++) {
    arr[i] = [listA[i], listB[i]];
  }
  return arr;
};

/**
 * Selects certain keys & values from an object to a new plain object
 * @see https://gitlab.mmdev.ru/modulemoney/module-money-backend-gateway/merge_requests/318/diffs#3705be42d294bc4dabd48e32cb44899d6479b180_357_405
 * @param {Object} obj
 * @param {Array.<String>} chosen An array of desired keys
 * @returns {Object}
 */
export const select = function (obj, chosen) {
  assert(isObject(obj), '"obj" must be an object');
  assert(Array.isArray(chosen), '"chosen" must be an array of strings');
  return chosen.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
};

/**
 * flatten an array of arrays
 * @see https://stackoverflow.com/a/15030117
 * @param {Array.<*>} arr
 * @returns {Array.<*>} arr
 */
export const flatten = (arr) =>
  arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten,
    );
  }, []);

/**
 * wraps the amount in nobr tag
 * @param {String} text
 * @returns {String}
 */
export const noBrCurrency = function (text) {
  assert(isString(text), '"text" must be a string');
  return text.replace(/(\d+)* \d+\.\d+/gi, (str) => {
    return `${str}`;
  });
};

/**
 * change \n on <br>
 * @see https://stackoverflow.com/a/7467863
 * @param {String} text
 * @returns {String}
 */
export const nl2br = function (text) {
  assert(isString(text), '"text" must be a string');
  return text
    .replace(/(<br\/>|<br \/>)/g, '')
    .replace(/\r\n|\n\r|\r|\n/g, '<br/>');
};

/**
 * WARNING: works only with {Boolean|Number|String|Array|Object|null|undefined} types
 * @see https://github.com/nervgh/yum.js/blob/master/src/yum.js#L143
 * @param any
 * @returns {*}
 */
export const copy = function (any) {
  // Number, String, Boolean, null, undefined
  if (isPrimitive(any)) {
    return any;
  }
  // We cannot copy FormData or File objects
  if (isFormData(any) || isFile(any)) {
    return any;
  }

  let root = Array.isArray(any) ? [] : {};
  for (let key in any) {
    // eslint-disable-next-line no-prototype-builtins
    if (any.hasOwnProperty(key)) {
      root[key] = copy(any[key]);
    }
  }
  return root;
};

/**
 * @param {*} any
 * @return {String}
 */
export const getType = function (any) {
  return Object.prototype.toString.call(any);
};

/**
 * @param {*} any
 * @returns {Boolean}
 */
export const isObject = function (any) {
  return any !== null && typeof any === 'object';
};

/**
 * @param {*} text
 * @returns {Boolean}
 */
export const isString = function (text) {
  return typeof text === 'string';
};

/**
 * @param {*} any
 * @return {Boolean}
 */
export const isFile = function (any) {
  return getType(any) === '[object File]';
};

/**
 * @param {*} any
 * @returns {Boolean}
 */
function isPrimitive(any) {
  if (any === null) {
    return true;
  }
  switch (typeof any) {
    case 'boolean':
    case 'number':
    case 'string':
    case 'null':
    case 'undefined':
      return true;
    default:
      return false;
  }
}

/**
 * @param {*} any
 * @return {Boolean}
 */
function isFormData(any) {
  return getType(any) === '[object FormData]';
}

export default {};
