<template>
  <PotokButton
    :disabled="!props.itemsActive"
    theme="linkIcon"
    text="акцепт всех активных"
  >
    <template #icon>
      <PotokImg :src="ImageConstants.acceptCircle" class="mr-1" />
    </template>
  </PotokButton>
</template>

<script setup>
import PotokImg from '@/components/_generic/PotokImg.vue';
import ImageConstants from '@/constants/imageConstants';
import { PotokButton } from 'potok-uikit';

const props = defineProps(['itemsActive']);
</script>

<style scoped lang="scss"></style>
