<template>
  <ModalFull
    :title="'зарегистрируйтесь в качестве партнера платформы, приводите новых заемщиков и зарабатывайте комиссию '"
    @close="close()"
  >
    <template #content>
      <div class="potok-text-body-2" style="color: #818e99">
        Для регистрации и подписании оферты перейдите по ссылке, после
        завершения регистрации и подписания оферты вам будет открыт доступ в
        личный кабинет партнера.
      </div>
      <div class="potok-text-body-2" style="margin-top: 20px; color: #818e99">
        Программа доступна только для индивидуальных предпринимателей и
        юридических лиц.
      </div>

      <PotokDivider marginY="20" />

      <div class="potok-text-body-1 potok-color-deepdive text_title">
        ваша ссылка
      </div>
      <div class="link_wrapper">
        <Loader v-if="loading" />
        <a
          v-if="!loading && magicLink"
          target="_blank"
          :href="magicLink"
          style="text-transform: none"
          >{{ magicLink }}</a
        >
        <div v-if="copied" class="copied">👍 скопировано</div>
      </div>

      <button
        :disabled="loading || !magicLink"
        type="button"
        class="button button-secondary"
        style="margin-top: 10px"
        @click="onClickCopy"
      >
        скопировать ссылку
      </button>
    </template>
  </ModalFull>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import server from '@/server';
import { Loader } from 'potok-uikit';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const emit = defineEmits(['close']);

const loading = ref(false);
const magicLink = ref(null);
const copied = ref(false);

const getMagicLink = () => {
  loading.value = true;
  return server.getMagicLink
    .send()
    .pipe(onFetchMagicLinkSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onFetchMagicLinkSuccess = (result) => {
  loading.value = false;
  if (result) {
    magicLink.value = result.data?.magicLink?.href;
  }
};

const close = () => {
  emit('close');
};

const onClickCopy = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(magicLink.value).then(function () {
      copied.value = true;
    });
  }
};

watch(copied, () => {
  setTimeout(() => {
    copied.value = false;
  }, 3000);
});
onMounted(() => {
  getMagicLink();
});
</script>
<style lang="scss" scoped>
.text_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #10273d;
}
</style>
