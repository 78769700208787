<template>
  <div class="login_input_phone">
    <div class="login__background"></div>
    <div class="login__content">
      <section class="page container_wrapper">
        <div class="login_title">
          <div>платформа для инвестирования в займы бизнесу</div>
        </div>
        <div class="login_subtitle">
          чтобы войти или зарегистрироваться введите номер телефона
        </div>
        <div>
          <div class="login_actions">
            <div class="login_input_wrapper">
              <input
                id="phone"
                v-model="localRawPhone"
                v-maska="localMasks.phone"
                type="tel"
                :readonly="loadingPostUsersCheckPhone"
                name="phone"
                placeholder=" (999) 999-99-99"
                class="form-control"
                @input="onKeyPhone"
                @keyup.enter="onClickEnterPhone()"
              />
              <label for="phone">+7</label>
              <div v-if="errorMessagePhone" class="potok-color-red">
                {{ errorMessagePhone }}
              </div>
            </div>
            <PotokButton
              :disabled="loadingPostUsersCheckPhone"
              size="large"
              text="начать"
              class="button-login"
              @click="onClickEnterPhone"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, nextTick, watch } from 'vue';
import { focusDomElementById, formatNumber } from '@/utils/commonUtils/utils';
import masks from '@/utils/masks';

import tracker from '@/tracker';
import { convertRawPhoneToLogin } from '@/utils/phone/formatPhone';
import server from '@/server';

import { PotokButton } from 'potok-uikit';

const props = defineProps(['rawPhone']);
const emit = defineEmits(['changeMode', 'changePhone']);

const localRawPhone = ref(null);

const errorMessagePhone = ref(null);
const loadingPostUsersCheckPhone = ref(false);

const localMasks = computed(() => {
  return {
    phone: masks.phone(),
  };
});

const isValidPhone = (phone) => {
  const formatPhone = formatNumber(phone);
  return (
    formatPhone.length === 10 &&
    formatPhone[0] === '9' &&
    !/^940+/gi.test(formatPhone)
  );
};

const enterPhone = ({ phone, callback }) => {
  if (loadingPostUsersCheckPhone.value) {
    return;
  }
  if (!phone) {
    errorMessagePhone.value = 'Введите номер телефона';
    return;
  }
  if (phone.length < 10 || !isValidPhone(phone)) {
    errorMessagePhone.value = 'Неверный номер телефона';
    return;
  }

  checkPhoneAsync({ phone, callback });
};

const checkPhoneAsync = ({ phone, callback }) => {
  const query = {
    phone: convertRawPhoneToLogin(phone),
  };
  loadingPostUsersCheckPhone.value = true;
  return server.postUsersCheckPhone
    .send(query)
    .pipe(onCheckPhoneAsyncSuccess.bind(this, { query, callback }), () => {
      loadingPostUsersCheckPhone.value = false;
    })
    .exec();
};
const onCheckPhoneAsyncSuccess = ({ callback }, { data }) => {
  loadingPostUsersCheckPhone.value = false;
  if (callback) {
    callback(data);
  }
};

const onKeyPhone = () => {
  emit('changePhone', localRawPhone.value);
};
const onClickEnterPhone = () => {
  emit('changePhone', localRawPhone.value);
  enterPhone({
    phone: localRawPhone.value,
    callback(result) {
      if (!result.isPhoneRegistered || !result.isActive) {
        tracker.queue(
          tracker.commands.SEND,
          'Adg_Investory_Shag2_Registracija_vvel_nomer',
        );
        return emit('changeMode', {
          component: 'inputRegister',
          step: 'inputPassword',
          isShowChangePasswordHint: result.showChangePasswordHint,
        });
      }

      let params = {};
      if (result.isSalesman) {
        params.isSalesman = true;
      }
      tracker.queue(
        tracker.commands.SEND,
        'Adg_Investory_Shag2_Avtorizacija_vvel_telefon',
      );
      return emit('changeMode', {
        component: 'inputPassword',
        isShowChangePasswordHint: result.showChangePasswordHint,
        params,
      });
    },
  });
};

const init = () => {
  nextTick(() => {
    focusDomElementById('phone');
  });
  localRawPhone.value = props.rawPhone;
};

watch(
  () => {
    return props.rawPhone;
  },
  () => {
    localRawPhone.value = props.rawPhone;
  },
);
onMounted(() => {
  init();
});
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.login_input_phone {
  background-color: $background;
  height: 100%;
}
.login__content {
  position: relative;
  z-index: 1;
}
.login__background {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-image: url('@img/login_background.png');
  background-repeat: no-repeat;
  min-height: 350px;
  opacity: 0.9;
  background-size: cover;
  z-index: 0;
  @media (max-width: $size_991) {
    min-height: 200px;
  }
}

.login_title {
  margin-top: 74px;
  font-weight: 500;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  color: $deep_dive;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    max-width: 688px;
  }
  @media (max-width: $size_991) {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;
    div {
      max-width: 208px;
    }
  }
}
.login_subtitle {
  margin-top: 52px !important;
}
.login_input_wrapper {
  position: relative;
  margin-right: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: $size_991) {
    margin-right: initial;
  }
  #phone {
    padding-left: 50px;
    color: $deep_dive;
    height: 60px;
    border: 1px solid #10273d;
    border-radius: 8px;
    @media (max-width: $size_991) {
      min-width: 288px;
      width: 100%;
    }
  }
  label {
    position: absolute;
    top: 30px;
    transform: translateY(-50%);
    left: 28px;
    color: $deep_dive;
    width: auto;
    height: auto;
  }
}
.login_actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  @media (max-width: $size_991) {
    flex-direction: column;
  }
}
.button-login {
  @media (max-width: $size_991) {
    margin-top: 20px;
  }
}
</style>
