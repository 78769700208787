export default class Countdown {
  /**
   * @param {Date} [begin=new Date()]
   * @param {Number} [duration=60] Offset in seconds from beginning
   * @param {Number} [step=1] Number seconds
   */
  constructor(begin = new Date(), duration = 180, step = 1) {
    this.begin = new Date(begin);
    this.end = new Date(begin);
    this.end.setSeconds(this.end.getSeconds() + duration);
    this.duration = duration;
    this.step = step;
    this.leftTime = duration;
    this.__onTick = this.__onTick.bind(this);
    this.__timerId = null;
  }

  reset() {
    this.begin = new Date();
    this.end = new Date();
    this.end.setSeconds(this.end.getSeconds() + this.duration);
    this.leftTime = this.duration;
    this.__timerId = null;
  }

  start() {
    this.__timerId = setInterval(this.__onTick, this.step * 1000);
  }

  stop() {
    clearInterval(this.__timerId);
  }

  __onTick() {
    const now = Date.now();
    const beginTime = this.begin.getTime();
    const diffMilliseconds = now - beginTime;
    const diffSeconds = Math.round(diffMilliseconds / 1000);
    this.leftTime = Math.max(this.duration - diffSeconds, 0);
    if (this.leftTime === 0) {
      this.stop();
    }
    this.onTick(this.leftTime);
  }

  onTick() {}

  setOnTick(callback) {
    if (callback) {
      this.onTick = callback;
    }
  }
}
