<template>
  <div>
    <div class="wrapper_dashboard_title">
      <div class="wrapper_title">
        <div class="content_subtitle">
          <div class="row_title_wrapper">
            <div class="row_title_right">
              <MenuSelect
                v-model="selectedGraphs"
                :items="typeGraphs"
                class="index_more_custom"
              ></MenuSelect>
            </div>
          </div>
        </div>

        <Switcher
          v-if="selectedGraphs === 'indexes'"
          v-model="selectedPeriodGraphs"
          :items="periodGraphs"
          @update:model-value="updatePeriod"
        ></Switcher>
      </div>
      <div class="content_chart_description">
        <button
          v-if="selectedGraphs == 'indexes'"
          type="button"
          class="button-transparent index_more potok-text-body-1 potok-color-deepdive"
          @click="onClickShowModal"
        >
          что это значит?
        </button>
      </div>
    </div>
    <div class="dashboard_block_content" style="min-height: 340px">
      <template v-if="loading">
        <Loader style="margin-top: 30px" />
      </template>
      <div v-if="!loading" class="content_chart">
        <Line
          v-if="inited"
          :data="data.graphData"
          :options="data.graphOptions"
          :plugins="plugins"
          style="height: 300px; width: 100%"
        />
      </div>
      <ModalFull
        v-if="selectedGraphs === 'indexes' && isShowModalIndexes"
        :title="'подробнее об индексах'"
        @close="isShowModalIndexes = false"
      >
        <template #content>
          <DashboardStatisticContentModal />
        </template>
      </ModalFull>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted, watch, nextTick } from 'vue';

import { Line } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { parse, getYear, getMonth } from 'date-fns';

import server from '@/server';
import { formatCurrency } from '@/utils/commonUtils/utils';
import detectDevice from '@/composables/detectDeviceComposable';
import { Loader } from 'potok-uikit';
import MenuSelect from '@/components/_generic/cselect/MenuSelect.vue';
import Switcher from '@/components/_generic/cselect/Switcher.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import DashboardStatisticContentModal from '@/components/Dashboard/DashboardStatistic/DashboardStatisticContent/DashboardStatisticContentModal.vue';

Chart.register(...registerables);

const backgroundColor = (context) => {
  const chart = context.chart;
  const { ctx, chartArea } = chart;
  if (!chartArea) {
    return null;
  }
  var gradient = ctx.createLinearGradient(0, 0, 0, 150);
  gradient.addColorStop(0, 'rgba(16, 186, 178, 0.3)');
  gradient.addColorStop(1, 'rgba(16, 186, 178, 0)');
  return gradient;
};

const $PRIMARY_COLOR = '#04CCD9';

const { isMobile } = detectDevice();

const inited = ref(false);
const loadingPulseIndexes = ref(false);
const loadingPulseStats = ref(false);
const selectedGraphs = ref('indexes');
const selectedPeriodGraphs = ref('');

const data = reactive({
  graphData: {
    labels: [],
    datasets: [
      {
        borderColor: $PRIMARY_COLOR,
        fill: false,
        data: [],
        backgroundColor: backgroundColor,
      },
      {
        borderColor: '#EE3D48',
        backgroundColor: '#EE3D48',
        fill: false,
        data: [],
      },
      {
        borderColor: '#FFC11E',
        backgroundColor: '#FFC11E',
        fill: false,
        data: [],
      },
      {
        borderColor: '#173B57',
        backgroundColor: '#173B57',
        fill: false,
        data: [],
      },
      {
        borderColor: '#818E99',
        backgroundColor: '#818E99',
        fill: false,
        data: [],
      },
    ],
  },
  graphOptions: {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 5,
          pointStyle: 'circle',
          usePointStyle: true,
          pointStyleWidth: 17,
          color: '#10273D',
        },
      },
      tooltip: {
        callbacks: {
          title: (value) => {
            if (!value[0]) {
              return '';
            }
            let title = value[0].label;
            if (title) {
              title = title.replace(',', ' ');
            }
            return title;
          },
          label: (value) => {
            if (selectedGraphs.value === 'amount') {
              const amount = value.raw / 1000000;
              return `${formatCurrency(amount, 0)} млн. ₽`;
            }
            if (selectedGraphs.value === 'indexes') {
              const label = value.dataset.label;
              let procent =
                data.rawIndexes[value.dataset.label][value.dataIndex].value;
              if (procent > 0) {
                procent = `+${formatCurrency(procent, 2)}`;
              } else {
                procent = `${formatCurrency(procent, 2)}`;
              }

              if (value.dataset.label === 'POTOK') {
                return ` ${formatCurrency(
                  data.rawIndexes[value.dataset.label][value.dataIndex]
                    .rawValue * 100,
                  2,
                )} ${label} (${procent}%)`;
              }
              return ` ${
                data.rawIndexes[value.dataset.label][value.dataIndex].rawValue
              } ${label} (${procent}%)`;
            }
            return value.raw;
          },
        },
      },
    },
    elements: {
      point: {
        pointStyle: 'circle',
        radius: 0,
        borderWidth: 2,
        backgroundColor: $PRIMARY_COLOR,
      },
      line: {
        borderWidth: 2,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#818E99',
          font: {
            size: 8,
          },
          callback: (_value) => {
            let value = _value;
            if (selectedGraphs.value === 'amount') {
              value = value / 1000000;
              return `${formatCurrency(value, 0)} млн. ₽`;
            }
            if (selectedGraphs.value === 'indexes') {
              return `${formatCurrency(value, 0)} %`;
            }
            return value;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          color: '#818E99',
          font: {
            size: 8,
          },
        },
      },
    },
  },
  rawStats: {},
  rawIndexes: {},
  projectStatIndexes: {},
});
const typeGraphs = ref([
  {
    title: 'индекс Потока',
    value: 'indexes',
  },
  {
    title: 'число инвесторов',
    value: 'investors',
  },
  {
    title: 'выдача займов',
    value: 'amount',
  },
]);
const periodGraphs = ref([
  {
    title: '12 месяцев',
    value: '12',
  },
  {
    title: '24 месяцев',
    value: '24',
  },
  {
    title: 'Весь период',
    value: '',
  },
]);
const isShowModalIndexes = ref(false);

const loading = computed(() => {
  return loadingPulseIndexes.value || loadingPulseStats.value;
});

const plugins = [
  {
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        if (!isMobile) {
          this.height += 20;
        } else {
          this.height += 40;
        }
      };
    },
  },
];
const getStatData = () => {
  if (selectedGraphs.value === 'investors') {
    let labels = [];
    let _data = [];
    for (const key in data.rawStats) {
      labels.push(getDateForIndexes(key));
      const value = data.rawStats[key].totalInvestorCount.value;
      _data.push(value);
    }
    return {
      data: _data,
      labels: labels,
    };
  }
  if (selectedGraphs.value === 'amount') {
    let labels = [];
    let _data = [];
    for (const key in data.rawStats) {
      labels.push(getDateForIndexes(key));
      const value = data.rawStats[key].totalLoanAmount.value;
      _data.push(value);
    }
    return {
      data: _data,
      labels: labels,
    };
  }
  if (
    selectedGraphs.value === 'indexes' &&
    Object.keys(data.projectStatIndexes).length > 0
  ) {
    const indexes = {};
    let labels = [];
    let RGBITR = [];
    let RUEYBCSTR = [];
    let potok = [];
    let IMOEX = [];
    let JL = [];
    let dataIndex = 0;
    data.rawIndexes = {
      RGBITR: [],
      RUEYBCSTR: [],
      POTOK: [],
      IMOEX: [],
      JL: [],
    };
    for (const key in data.projectStatIndexes) {
      labels.push(getDateForIndexes(key));
      if (data.projectStatIndexes[key].RGBITR) {
        const value = data.projectStatIndexes[key].RGBITR.value;
        RGBITR.push(value);
        data.rawIndexes.RGBITR[dataIndex] = {
          rawValue: data.projectStatIndexes[key].RGBITR.rawValue,
          value: value,
        };
      } else {
        RGBITR.push(NaN);
      }
      if (data.projectStatIndexes[key].RUEYBCSTR) {
        const value = data.projectStatIndexes[key].RUEYBCSTR.value;
        RUEYBCSTR.push(value);
        data.rawIndexes.RUEYBCSTR[dataIndex] = {
          rawValue: data.projectStatIndexes[key].RUEYBCSTR.rawValue,
          value: value,
        };
      } else {
        RUEYBCSTR.push(NaN);
      }
      if (data.projectStatIndexes[key].potok) {
        const value = data.projectStatIndexes[key].potok.value;
        potok.push(value);
        data.rawIndexes.POTOK[dataIndex] = {
          rawValue: data.projectStatIndexes[key].potok.rawValue,
          value: value,
        };
      } else {
        potok.push(NaN);
      }
      if (data.projectStatIndexes[key].IMOEX2) {
        const value = data.projectStatIndexes[key].IMOEX2.value;
        IMOEX.push(value);
        data.rawIndexes.IMOEX[dataIndex] = {
          rawValue: data.projectStatIndexes[key].IMOEX2.rawValue,
          value: value,
        };
      } else {
        IMOEX.push(NaN);
      }
      if (data.projectStatIndexes[key].JET) {
        const value = data.projectStatIndexes[key].JET.value;
        JL.push(value);
        data.rawIndexes.JL[dataIndex] = {
          rawValue: data.projectStatIndexes[key].JET.rawValue,
          value: value,
        };
      } else {
        JL.push(NaN);
      }
      dataIndex += 1;
    }
    indexes.labels = labels;
    indexes.RGBITR = RGBITR;
    indexes.RUEYBCSTR = RUEYBCSTR;
    indexes.POTOK = potok;
    indexes.IMOEX = IMOEX;
    indexes.JL = JL;
    return indexes;
  }
  return {
    data: [],
    labels: [],
  };
};
const takeDataGraphs = () => {
  inited.value = false;
  const _data = getStatData();
  data.graphData.labels = _data.labels;
  if (selectedGraphs.value === 'indexes') {
    data.graphData.datasets[0].data = _data.POTOK;
    data.graphData.datasets[0].fill = false;
    data.graphData.datasets[0].backgroundColor = $PRIMARY_COLOR;
    data.graphData.datasets[1].data = _data.IMOEX;
    data.graphData.datasets[2].data = _data.RGBITR;
    data.graphData.datasets[3].data = _data.RUEYBCSTR;
    data.graphData.datasets[4].data = _data.JL;
    data.graphData.datasets[0].label = 'POTOK';
    data.graphData.datasets[1].label = 'IMOEX';
    data.graphData.datasets[2].label = 'RGBITR';
    data.graphData.datasets[3].label = 'RUEYBCSTR';
    data.graphData.datasets[4].label = 'JL';
    data.graphOptions.plugins.legend.display = true;
  } else {
    data.graphOptions.plugins.legend.display = false;
    data.graphData.datasets[0].data = _data.data;
    data.graphData.datasets[1].data = [];
    data.graphData.datasets[2].data = [];
    data.graphData.datasets[3].data = [];
    data.graphData.datasets[4].data = [];
    data.graphData.datasets[0].label = null;
    data.graphData.datasets[1].label = null;
    data.graphData.datasets[2].label = null;
    data.graphData.datasets[3].label = null;
    data.graphData.datasets[4].label = null;
  }
  nextTick(() => {
    inited.value = true;
  });
};
const getDateForIndexes = (dateString) => {
  const date = parse(dateString, 'dd.MM.yyyy', new Date());
  let months;
  if (isMobile.value) {
    months = [...Array(12).keys()].map((k) => {
      return (k + 1).toString().padStart(2, '0');
    });
  } else {
    months = {
      0: 'ЯНВАРЬ',
      1: 'ФЕВРАЛЬ',
      2: 'МАРТ',
      3: 'АПРЕЛЬ',
      4: 'МАЙ',
      5: 'ИЮНЬ',
      6: 'ИЮЛЬ',
      7: 'АВГУСТ',
      8: 'СЕНТЯБРЬ',
      9: 'ОКТЯБРЬ',
      10: 'НОЯБРЬ',
      11: 'ДЕКАБРЬ',
    };
  }
  let currentYear = getYear(date);
  let currentMonth = getMonth(date);
  return [months[currentMonth], currentYear];
};
const updatePeriod = () => {
  getIndexes(selectedPeriodGraphs.value);
};
const getPlatformStatAsync = (callback) => {
  loadingPulseStats.value = true;
  return server.getPlatformStat
    .send(null, {})
    .pipe(onFetchPlatformStatSuccess, () => {
      loadingPulseStats.value = false;
    })
    .pipe(() => {
      if (callback) {
        callback();
      }
    })
    .exec();
};
const onFetchPlatformStatSuccess = (obj) => {
  loadingPulseStats.value = false;
  if (obj.data) {
    data.rawStats = obj.data;
  }
};
const getIndexes = (period) => {
  loadingPulseIndexes.value = true;
  const query = {};
  if (period) {
    query.period = period;
  }
  return server.getPlatformIndexes
    .send(query, {})
    .pipe(onFetchPlatformIndexesSuccess, () => {
      loadingPulseIndexes.value = false;
    })
    .exec();
};
const onFetchPlatformIndexesSuccess = (obj) => {
  loadingPulseIndexes.value = false;
  if (obj.data) {
    data.projectStatIndexes = obj.data;
    takeDataGraphs();
  }
};
const onClickShowModal = () => {
  isShowModalIndexes.value = true;
};

watch(selectedGraphs, () => {
  takeDataGraphs();
});
onMounted(() => {
  getPlatformStatAsync(() => {
    getIndexes();
  });
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.wrapper_dashboard_title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  @media (max-width: $size_767) {
    margin-bottom: 0px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: baseline;
  }
}
.dashboard_block_content {
  height: 100%;
  padding: 23px 20px 20px 20px;
  margin-top: 20px;
  @media (max-width: $size_767) {
    margin-top: 0px;
  }
}
.wrapper_title {
  flex: 2;
  padding: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: $size_767) {
    display: inline-block;
    flex-direction: column;
    align-items: baseline;
  }
  .content_subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: $size_767) {
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.item_row_vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  height: 100%;
}
.switcher {
  @media (max-width: $size_767) {
    margin-top: 10px;
  }
}
.content_chart {
  position: relative;
  @media (max-width: $size_767) {
    padding-top: 0px;
    margin-top: 10px;
  }
}
.content_chart_description {
  color: $primary;
  min-width: 140px;
}
.index_more {
  position: relative;
  color: $tiffany;
  @media (max-width: $size_767) {
    margin: 24px 0 14px 0;
  }
}
.row_title {
  margin-top: 0px !important;
  margin-right: 20px;
  @media (max-width: $size_767) {
    width: 100%;
    margin-right: initial;
  }
}
.row_title_right {
  @media (max-width: $size_767) {
    margin-top: 10px;
    width: 100%;
  }
}
.row_title_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.switcher {
  @media (max-width: $size_767) {
    width: 100%;
  }
  .items {
    @media (max-width: $size_767) {
      justify-content: space-between;
    }
  }
}
.index_more_custom {
  min-width: 240px;
  margin-right: 20px;
}
</style>
