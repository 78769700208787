export default `
<div style='text-transform:none' class='future-rate-modal'>
  <div class="future-rate-normal-text">
    <b>Методика расчета</b> — Ожидаемая доходность определяется на основе модели ожидаемых кредитных убытков (ECL), применяемой для оценки потерь от реализации кредитных рисков в соответствии с <a href="https://www.bis.org/bcbs/publ/d350.pdf" target="_blank">рекомендациями Базельского комитета</a> по банковскому надзору, а также основными подходами к расчету величины кредитного риска, установленными Банком России (Положение Банка России от 06.08.2015 № 483-П):
  </div>
  <div class='future-rate-margin-top'>Расчет <b class='future-rate-normal-text'>ожидаемой доходности (Y)</b>:</div>
  <span class='future-rate-formule-first'></span>
  <div class="future-rate-formule-desc">
    <b class='future-rate-normal-text'>Y<span class="future-rate-low-text">strategy</span></b> — ставка на остаток основного долга по всем инвестициям по соответствующей стратегии, объявленная Потоком.<br>
    <b class='future-rate-normal-text'>ECL</b> — ожидаемая величина кредитных убытков, уменьшающая доходность по стратегии, рассчитываемая как:
  </div>
  <span class='future-rate-formule-second'></span>
  <div class="future-rate-formule-desc">
    <b class='future-rate-normal-text'>PD </b>(Probability of Default, вероятность дефолта) — вероятность, с которой заемщик в течение некоторого срока может оказаться в состоянии дефолта.<br>
    <b class='future-rate-normal-text'>LGD </b>(Loss Given Default, потери при наступлении дефолта) — доля от суммы обязательств заемщика, которая может быть потеряна в случае дефолта.<br>
    <b class='future-rate-normal-text'>EAD </b> (Exposure at Default; сумма, подверженная риску дефолта) — общий объем обязательств заемщика на момент возникновения дефолта.
  </div>
  <div class='future-rate-margin-top'>Показатели PD, LGD, EAD рассчитываются на основе исторических данных о дефолтах в заданной категории заемщиков, средней сумме основного долга в момент дефолта и фактических сумм возврата основного долга после дефолта с учетом удерживаемой платформой комиссии.</div>
  <div class='future-rate-margin-top'>Исторические данные включают займы, выданные Платформой после 01.04.2020, по которым проводился скоринг. Включаются погашенные займы, займы по которым наступил дефолт, и непогашенные займы, выданные более 8 месяцев назад. Не включается продукт факторинг.</div>
</div>
`;
