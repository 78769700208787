<template>
  <button
    class="strategy-diversification-tag"
    @click.stop="onClickShowModalDiversification"
  >
    <div
      v-if="!props.isEnhancedDiversification"
      class="potok-text-body-3-bold potok-color-breakwater"
    >
      усилить диверсификацию
    </div>
    <div
      v-if="props.isEnhancedDiversification"
      class="potok-text-body-3 potok-color-grey"
    >
      отключить усиленную диверсификацию
    </div>
  </button>
</template>
<script setup>
const props = defineProps({
  isEnhancedDiversification: {
    type: Boolean,
    defualt: false,
  },
});

const emit = defineEmits(['click']);

const onClickShowModalDiversification = () => {
  emit('click');
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.strategy-diversification-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 2px 10px 2px 8px;
  border-radius: 6px;
  background-color: #f2f4f8;
  font-weight: 500;
  color: #173b57;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
  &:hover {
    opacity: 0.8;
  }
}
</style>
