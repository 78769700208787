import Countdown from '../../utils/classes/Countdown';
import { tagId } from '../../utils/commonUtils/utils';

export default class TwoFactorAuthentication {
  constructor() {
    this.leftTime = 0;
    this.countdown = new Countdown(new Date(), 60, 0);
    this.smsCode = '';
    this.identifiers = {
      smsCode: tagId('smsCode'),
    };
    this.setStep(TwoFactorAuthentication.steps.IDLE);
  }

  isStep(n) {
    return this.step === n;
  }

  setStep(n) {
    const { IDLE, ACTIVE, DONE } = TwoFactorAuthentication.steps;
    const min = IDLE;
    const max = DONE;
    const k = Math.min(Math.max(min, n), max);
    switch (k) {
      case IDLE:
        this.countdown.stop();
        this.countdown.reset();
        break;
      case ACTIVE:
        this.countdown.stop();
        this.countdown.reset();
        this.countdown.start();
        this.smsCode = '';
        break;
      case DONE:
        this.countdown.stop();
        break;
    }
    this.step = k;
  }
}

TwoFactorAuthentication.steps = {
  IDLE: 0,
  ACTIVE: 1,
  DONE: 2,
};
