<template>
  <div class="new-investments-new-request-step-1-table-mobile mt-6">
    <template v-if="isLoading">
      <Loader class="mt-6" />
    </template>

    <template v-if="!isLoading">
      <div
        class="new-investments-new-request-step-1-table-container potok-text-body-2 mt-5"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="new-investments-new-request-step-1-table-item mb-5"
        >
          <div class="new-investments-new-request-step-1-table-item-content">
            <!-- название и номер инвестиции -->
            <div
              class="new-investments-new-request-step-1-table-item-content-row"
            >
              <div class="new-request-step-1-table-investment">
                <div class="new-request-step-1-table-investment-name">
                  <router-link
                    :to="`/loans/${item.projectId}`"
                    class="step-1-table-link"
                  >
                    {{ item.borrowerShortName }}
                  </router-link>
                </div>
                <div class="new-request-step-1-table-investment-info mt-1">
                  инв.
                  <router-link
                    :to="`/investment/${item.id}`"
                    class="step-1-table-link"
                  >
                    {{ item.projectNumber }}
                  </router-link>
                  в заем
                  <router-link
                    :to="`/loans/${item.projectId}`"
                    class="step-1-table-link"
                  >
                    {{ item.number }}
                  </router-link>
                </div>
              </div>
            </div>
            <!-- контент инвестиции -->
            <div
              v-for="(contentItem, i) in contentList"
              :key="i"
              class="new-investments-new-request-step-1-table-item-content-row"
            >
              <span
                class="new-investments-new-request-step-1-table-item-content-title"
              >
                {{ contentItem.title }}
              </span>
              <span class="potok-text-body-1 mt-1">
                {{
                  item[contentItem.link]
                    ? contentItem.contentHandle(item[contentItem.link])
                    : 'нет данных'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

  <PaginationMobile
    v-if="!isLoading && props.items.length"
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :is-loading="props.isLoadingMore"
    :total-items-count="props.itemsCount"
    class="mt-2"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { Loader } from 'potok-uikit';
import PaginationMobile from '@/components/_generic/pagination/paginationMobile.vue';
import { formatDate, formatCurrency } from '@/utils/commonUtils/libUtils';

const props = defineProps(['items', 'isLoading', 'itemsCount']);
const emits = defineEmits(['toggleItem']);

const selectedPageRange = ref(5);
const currentPage = ref(1);

const formatDateHandle = (date) => {
  return formatDate(date, 'dd.MM.yyyy HH:mm', 'Europe/Moscow');
};
const formatCurrencyHandle = (currency) => {
  return `${formatCurrency(currency, 0)} ₽`;
};

const contentList = [
  {
    link: 'createdAt',
    title: 'окончание срока займа',
    contentHandle: formatDateHandle,
  },
  {
    link: 'loanFundedAt',
    title: 'окончание срока займа',
    contentHandle: formatDateHandle,
  },
  {
    link: 'amount',
    title: 'вложено',
    contentHandle: formatCurrencyHandle,
  },
  {
    link: 'mainDebtAmount',
    title: 'остаток основного долга',
    contentHandle: formatCurrencyHandle,
  },
];

const loadMoreList = () => {
  emits('loadMoreList', {
    selectedPageRange: selectedPageRange.value,
    currentPage: currentPage.value,
  });
};

watch(currentPage, loadMoreList);
</script>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.new-investments-new-request-step-1-table-item {
  width: 100%;
  background-color: $white;
  border-radius: 14px;
  padding: 20px 14px 6px 14px;
}
.new-investments-new-request-step-1-table-item-header {
  display: flex;
  justify-content: space-between;
}
.new-investments-new-request-step-1-table-item-header-left {
  display: flex;
  align-items: center;
}
.new-investments-new-request-step-1-table-item-btn {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: transparent;
  border: 1px solid $breakwater;
}
.new-investments-new-request-step-1-table-item-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.new-investments-new-request-step-1-table-item-content-row {
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  border-bottom: 1px solid $dividers;
  &:first-child {
    padding: 0 0 14px 0;
  }
  &:last-child {
    border-bottom: none;
  }
}
.new-investments-new-request-step-1-table-item-content-title {
  color: $grey;
}

.new-request-step-1-table-investment-name {
  text-transform: none;
}
.new-request-step-1-table-investment-info {
  color: $grey;
  span {
    color: $breakwater;
  }
}
.step-1-table-link {
  color: $deep_dive;
  text-decoration: none;
  &:hover {
    color: $tiffany;
  }
}
</style>
