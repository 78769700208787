<template>
  <button
    class="dashboard-statuses-bonus-btn"
    :class="{
      'bonus-basic': props.status === BONUS_TYPE.BASIC,
      'bonus-silver': props.status === BONUS_TYPE.SILVER,
      'bonus-gold': props.status === BONUS_TYPE.GOLD,
      'bonus-platinum': props.status === BONUS_TYPE.PLATINUM,
      'bonus-diamond': props.status === BONUS_TYPE.DIAMOND,
      'bonus-btn-non-active': !props.isActive,
    }"
  >
    <i class="dashboard-statuses-bonus-icon mr-1" />
    <div class="potok-text-uppercase dashboard-statuses-bonus-name">
      {{ props.status }}
    </div>
  </button>
</template>

<script setup>
import { BONUS_TYPE } from '@/constants/Dashboard/BonusProgramm/bonusProgrammConstants';

const props = defineProps({
  status: {
    type: String,
    required: false,
  },
  isActive: {
    type: Boolean,
    default: true,
  },
});
</script>

<style scoped lang="scss">
@use '../../../../scss/variables.scss' as *;

.bonus-btn-non-active {
  pointer-events: none;
}
.dashboard-statuses-bonus-btn {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid;
  background-color: transparent;
  padding: 5px 10px 5px 8px;
}

.dashboard-statuses-bonus-icon {
  display: block;
  height: 12px;
  width: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  &:hover {
    background: url('@img/icons/bonuses/platinum-level-hover.svg');
  }
}
.bonus-basic {
  color: $grey;
  .dashboard-statuses-bonus-icon {
    border-color: $grey;
    background-image: url('@img/icons/bonuses/basic-level.svg');
  }
  &:hover {
    color: $white;
    background-color: $grey;
    .dashboard-statuses-bonus-icon {
      background: url('@img/icons/bonuses/basic-level-hover.svg');
    }
  }
}
.bonus-silver {
  color: $breakwater;
  .dashboard-statuses-bonus-icon {
    border-color: $breakwater;
    background-image: url('@img/icons/bonuses/silver-level.svg');
  }
  &:hover {
    color: $white;
    background-color: $breakwater;
    .dashboard-statuses-bonus-icon {
      background: url('@img/icons/bonuses/silver-level-hover.svg');
    }
  }
}
.bonus-gold {
  color: $yellow;
  .dashboard-statuses-bonus-name {
    position: relative;
    bottom: 1px;
    top: 0;
  }
  .dashboard-statuses-bonus-icon {
    width: 15px;
    border-color: $yellow;
    background-image: url('@img/icons/bonuses/gold-level.svg');
  }
  &:hover {
    color: $white;
    background-color: $yellow;
    .dashboard-statuses-bonus-icon {
      background: url('@img/icons/bonuses/gold-level-hover.svg');
    }
  }
}
.bonus-platinum {
  color: $tiffany;
  .dashboard-statuses-bonus-icon {
    border-color: $tiffany;
    background-image: url('@img/icons/bonuses/platinum-level.svg');
  }
  &:hover {
    color: $white;
    background-color: $tiffany;
    .dashboard-statuses-bonus-icon {
      background: url('@img/icons/bonuses/platinum-level-hover.svg');
    }
  }
}
.bonus-diamond {
  color: $red;
  .dashboard-statuses-bonus-icon {
    border-color: $red;
    background-image: url('@img/icons/bonuses/diamond-level.svg');
  }
  &:hover {
    color: $white;
    background-color: $red;
    .dashboard-statuses-bonus-icon {
      background: url('@img/icons/bonuses/diamond-level-hover.svg');
    }
  }
}

.dashboard-statuses-bonus-name {
  line-height: 12px;
}
</style>
