<template>
  <template v-if="loading">
    <div class="icon_loading"></div>
  </template>
  <template v-if="!loading">
    <div class="step4">
      <div class="row">
        <div class="col-md-12">
          <div class="step_title">пополнение счета</div>
          <div class="switcher-wrapper">
            <Switcher
              v-if="typesTransfer.length > 1"
              v-model="selectedtTypesTransfer"
              :items="typesTransfer"
            />
          </div>

          <DashboardOnboardRefillBalanceAccount
            v-if="selectedtTypesTransfer === 2"
            :data="methodAccount"
          />
          <DashboardOnboardRefillBalanceSPB
            v-if="selectedtTypesTransfer === 1"
            :data="methodSpb"
          />
        </div>
      </div>

      <PotokDivider margin-y="30" />

      <div>
        <button
          class="button button-primary button-continue"
          type="button"
          :disabled="!isEnabledContinue"
          @click="onClickContinue"
        >
          завершить регистрацию
        </button>
      </div>
    </div>
  </template>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import Switcher from '@/components/_generic/cselect/Switcher.vue';
import detectDevice from '@/composables/detectDeviceComposable.js';
import server from '@/server';
import tracker from '@/tracker';
import { useStateStore } from '@/store/stateStore';

import DashboardOnboardRefillBalanceAccount from './DashboardOnboardRefill/DashboardOnboardRefillBalanceAccount.vue';
import DashboardOnboardRefillBalanceSPB from './DashboardOnboardRefill/DashboardOnboardRefillBalanceSPB.vue';

const store = useStateStore();

const emit = defineEmits(['updateStep']);
const { isMobile } = detectDevice();

const selectedtTypesTransfer = ref();
const loading = ref(false);

const paymentMethods = ref([]);

const isEnabledContinue = computed(() => {
  if (!loading.value) {
    return true;
  }
  return false;
});

const methodSpb = computed(() => {
  return paymentMethods.value.find((i) => i.method === 'sbp');
});
const methodAccount = computed(() => {
  return paymentMethods.value.find((i) => i.method === 'bankAccount');
});

const typesTransfer = computed(() => {
  let result = [];
  if (methodSpb.value) {
    let title = methodSpb.value.title;
    if (isMobile.value) {
      title = methodSpb.value.shortTitle;
    }
    result.push({
      title,
      icon: 'icon_spb',
      value: 1,
    });
  }
  if (methodAccount.value) {
    let title = methodAccount.value.title;
    if (isMobile.value) {
      title = methodAccount.value.shortTitle;
    }
    result.push({
      title,
      value: 2,
    });
  }
  return result;
});

const onClickContinue = () => {
  putOnboardBalance();
};
const putOnboardBalance = () => {
  const query = {};
  loading.value = true;
  return server.putUserOnboardBalance
    .send(query)
    .pipe(onFetchOnboardBalanceSuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onFetchOnboardBalanceSuccess = (obj) => {
  tracker.queue(
    tracker.commands.SEND,
    'Investor_LK_Onboard_Step_4_FinishOnboard',
  );
  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag9_Sbor_dannyh_uvidel_dannye_dlja_popolnenija_scheta',
  );

  loading.value = false;
  if (obj.data.isSuccessful) {
    emit('updateStep', {
      step: obj.data.onboardingStatus,
    });
  }
};

const getPaymentMethodsAsync = () => {
  loading.value = true;
  let query = {
    companyId: store.selectedCompanyId,
  };
  return server.getPaymentMethods
    .send(query)
    .pipe(
      (obj) => {
        loading.value = false;
        if (!obj) {
          return;
        }
        paymentMethods.value = obj.data || [];

        if (paymentMethods.value.length === 1) {
          selectedtTypesTransfer.value = typesTransfer.value[0].value;
        }
        if (paymentMethods.value.length > 1) {
          selectedtTypesTransfer.value = 1;
        }
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

onMounted(() => {
  getPaymentMethodsAsync();
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.step4 {
  text-transform: none;
}

.switcher-wrapper {
  margin-top: 20px;
  @media (max-width: $size_767) {
    margin-top: 17px;
  }
}
.block_right {
  text-align: center;
  @media (max-width: $size_991) {
    padding: 0;
  }
}
.code_payment {
  width: 200px;
  @media (max-width: 1199px) {
    width: 180px !important;
    height: 180px !important;
  }
}
.button-continue {
  @media (max-width: $size_767) {
    padding: 9px 15px 11px 15px;
    margin-bottom: 10px;
  }
}
</style>
