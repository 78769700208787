<template>
  <div>
    <InvestmentsFilterTableComponent
      v-if="!isMobile"
      :status="props.status"
      class="block_content"
      @update-filters="updateFilters"
    />
  </div>
</template>
<script setup>
import InvestmentsFilterTableComponent from './InvestmentsFilterTable/InvestmentsFilterTableComponent.vue';
import detectDevice from '@/composables/detectDeviceComposable';

const { isMobile } = detectDevice();

const emit = defineEmits(['updateFilters']);
const props = defineProps(['status']);

const updateFilters = (obj) => {
  emit('updateFilters', obj);
};
</script>
<style lang="scss" scoped></style>
