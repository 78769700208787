<template>
  <div>
    <div class="row" style="margin-top: 20px">
      <div class="col-md-8">
        <div class="block_content">
          <InvestorAddStep_1_1
            v-if="currentStepId === '1_1'"
            @next-step="nextStep"
          />
          <InvestorAddStep_2_1
            v-if="currentStepId === '2_1'"
            @next-step="nextStep"
          />
          <InvestorAddStep_2_2
            v-if="currentStepId === '2_2'"
            @next-step="nextStep"
          />
          <InvestorAddStep_3
            v-if="currentStepId === '3_1' || currentStepId === '3_2'"
            @next-step="nextStep"
          />
        </div>
      </div>
      <div class="col-md-4">
        <WizardDesktop
          :current-step-status="currentStepId"
          :steps="steps"
          @update-step="updateStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WizardDesktop from '@/components/_generic/stepWizard/WizardDesktop.vue';
import InvestorAddStep_1_1 from '../InvestorAddStep_1_1.vue';
import InvestorAddStep_2_1 from '../InvestorAddStep_2_1.vue';
import InvestorAddStep_2_2 from '../InvestorAddStep_2_2.vue';
import InvestorAddStep_3 from '../InvestorAddStep_3.vue';

export default {
  components: {
    WizardDesktop,
    InvestorAddStep_1_1,
    InvestorAddStep_2_1,
    InvestorAddStep_2_2,
    InvestorAddStep_3,
  },
  props: {
    currentStepId: {
      type: [Number, String],
      default: null,
    },
    steps: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  emits: ['nextStep'],
  methods: {
    nextStep(obj) {
      this.$emit('nextStep', obj);
    },
    updateStep(obj) {
      if (obj.prevStepId) {
        this.$emit('nextStep', { nextStepId: obj.prevStepId });
      }
    },
  },
};
</script>
<style></style>
