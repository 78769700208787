<template>
  <img
    v-if="!props.loadingIcon"
    :src="getImgUrl"
    :height="props.height"
    :width="props.width"
    :loading="props.lazy ? 'lazy' : 'eager'"
    alt="image"
  />

  <div
    v-else
    :style="{
      height: `${props.loadingHeight}px`,
      width: `${props.loadingWidth}px`,
      border: `${props.loadingBorder}px solid ${props.loadingTheme === 'light' ? 'white' : 'rgb(23, 59, 87)'}`,
    }"
    class="loading-icon"
  />
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  height: {
    type: [Number, String],
    default: 16,
  },
  width: {
    type: [Number, String],
    default: 16,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  loadingHeight: {
    type: [Number, String],
    default: 30,
  },
  loadingWidth: {
    type: [Number, String],
    default: 30,
  },
  loadingBorder: {
    type: [Number, String],
    default: 5,
  },
  loadingIcon: {
    type: Boolean,
    default: false,
  },
  loadingTheme: {
    type: String,
    default: 'light',
  },
});

const getImgUrl = computed(() => {
  return props.src ? `/static/${props.src}` : '';
});
</script>

<style scoped lang="scss">
@use '../../scss/variables.scss' as *;

.loading-icon {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  animation: loading-spin 0.75s infinite linear;
  border-top-color: transparent !important;

  &::before,
  &::after {
    left: -5px;
    top: -5px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }
  &::after {
    display: block;
    border-color: transparent !important;
    animation: loading-spin 1.25s infinite linear reverse;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
