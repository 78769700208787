<template>
  <div v-if="!loadingProject && item">
    <FormFieldTitle v-if="isShowDebtor" :title="'дебитор'" />

    <FormField
      v-if="item.projectPromoParams.debtorTitle"
      :divider="false"
      :name="'наименование'"
      :value="item.projectPromoParams.debtorTitle"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.debtorOgrn"
      :name="'ОГРН'"
      :value="item.projectPromoParams.debtorOgrn"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.debtorInn"
      :name="'ИНН'"
      :value="item.projectPromoParams.debtorInn"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.debtorDescription"
      :name="'описание деятельности'"
      :value="item.projectPromoParams.debtorDescription"
      :name-width="3"
      :value-width="9"
    />
    <FormField :name="'сайт'" :name-width="3" :value-width="9">
      <template #value>
        <template v-if="item.projectPromoParams.debtorSite.length !== 0">
          <a
            :href="getHttpString(item.projectPromoParams.debtorSite)"
            target="_blank"
            >{{ item.projectPromoParams.debtorSite }}</a
          >
        </template>
        <template v-else> Отсутствует </template>
      </template>
    </FormField>

    <FormFieldTitle v-if="isShowSupplier" :title="'поставщик'" />

    <FormField
      v-if="item.projectPromoParams.supplierTitle"
      :divider="false"
      :name="'наименование'"
      :value="item.projectPromoParams.supplierTitle"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplierOgrn"
      :name="'ОГРН'"
      :value="item.projectPromoParams.supplierOgrn"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplierInn"
      :name="'ИНН'"
      :value="item.projectPromoParams.supplierInn"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplierDescription"
      :name="'описание деятельности'"
      :value="item.projectPromoParams.supplierDescription"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      :name="'сайт'"
      :value="item.projectPromoParams.supplierDescription"
      :name-width="3"
      :value-width="9"
    >
      <template #value>
        <template v-if="item.projectPromoParams.supplierSite.length !== 0">
          <a
            :href="getHttpString(item.projectPromoParams.supplierSite)"
            target="_blank"
            >{{ item.projectPromoParams.supplierSite }}</a
          >
        </template>
        <template v-else> отсутствует </template>
      </template>
    </FormField>

    <FormFieldTitle
      v-if="isShowFactoringCompany"
      :title="'факторинговая компания'"
    />

    <FormField
      v-if="item.borrower.companyShortName"
      :divider="false"
      :name="'наименование'"
      :value="item.borrower.companyShortName"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.borrower.ogrn"
      :name="'ОГРН'"
      :value="item.borrower.ogrn"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.borrower.inn"
      :name="'ИНН'"
      :value="item.borrower.inn"
      :name-width="3"
      :value-width="9"
    />

    <FormFieldTitle
      v-if="isShowFactoringAgreement"
      :title="'договор факторинга между поставщиком и факторинговой компанией'"
    />

    <FormField
      v-if="item.projectPromoParams.contractTitle"
      :divider="false"
      :name="'наименование договора'"
      :value="item.projectPromoParams.contractTitle"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.contractNumber"
      :name="'номер договора факторинга'"
      :value="item.projectPromoParams.contractNumber"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.contractDateAt"
      :name="'дата договора факторинга'"
      :value="formatDate(item.projectPromoParams.contractDateAt)"
      :name-width="3"
      :value-width="9"
    />

    <FormField
      v-if="item.projectPromoParams.contractType"
      :name="'вид факторинга'"
      :name-width="3"
      :value-width="9"
    >
      <template #value>
        {{ item.projectPromoParams.contractType }}
        <QuestionPopover
          ><ul>
            <li>
              с правом регресса — поставщик несет солидарную ответственность по
              обязательствам Дебитора
            </li>
            <li>
              без права регресса — поставщик не отвечает по обязательствам
              Дебитора
            </li>
          </ul></QuestionPopover
        >
      </template>
    </FormField>

    <FormFieldTitle
      v-if="isShowContractSupply"
      :title="'контракт на поставку товаров / работ / услуг между поставщиком (клиентом факторинга) и Дебитором'"
    />

    <FormField
      v-if="item.projectPromoParams.supplyContractTitle"
      :divider="false"
      :name="'наименование контракта'"
      :value="item.projectPromoParams.supplyContractTitle"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplyContractNumber"
      :name="'номер контракта'"
      :value="item.projectPromoParams.supplyContractNumber"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplyContractDateAt"
      :name="'дата контракта'"
      :value="formatDate(item.projectPromoParams.supplyContractDateAt)"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplyContractTitle"
      :name="'вид поставляемых товаров (категорий товаров) / выполняемых работ / оказываемых услуг'"
      :value="item.projectPromoParams.supplyContractTitle"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplyContractIndulgence"
      :name="'отсрочка платежа по контракту между поставщиком и Дебитором (по видам товаров / работ / услуг)'"
      :value="item.projectPromoParams.supplyContractIndulgence"
      :name-width="3"
      :value-width="9"
    />

    <FormFieldTitle
      v-if="isShowRegisterOfClaim"
      :title="'реестр уступленных денежных требований, заявленных к финансированию по Договору факторинга (предмет залога), под залог которых привлекается займ'"
    />

    <FormField
      v-if="item.projectPromoParams.supplyRegisterNumber"
      :divider="false"
      :name="'номер реестра'"
      :value="item.projectPromoParams.supplyRegisterNumber"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplyRegisterDateAt"
      :name="'дата реестра'"
      :value="formatDate(item.projectPromoParams.supplyRegisterDateAt)"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplyRegisterAmount"
      :name="'сумма уступленных требований, передаваемых в обеспечение обязательств (залог) под займ, всего'"
      :value="formatCurrency(item.projectPromoParams.supplyRegisterAmount, 0)"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.supplyRegisterPaymentDateAt"
      :value="formatDate(item.projectPromoParams.supplyRegisterPaymentDateAt)"
      :name-width="3"
      :value-width="9"
    >
      <template #name>
        плановая крайняя дата погашения
        <QuestionPopover
          >по сроку отсрочки платежа по контракту между поставщиком и Дебитором
          уступленных денежных требований (без учета льготного периода Фактора
          по Договору факторинга)</QuestionPopover
        >
      </template>
    </FormField>

    <FormFieldTitle
      v-if="isShowVerificationClaim"
      :title="'верификация уступленных денежных требований, заявленных к финансированию по Договору факторинга (предмет залога), под залог которых привлекается займ'"
    />

    <FormField
      v-if="item.projectPromoParams.verificationStatus"
      :divider="false"
      :name="'статус верификации'"
      :value="item.projectPromoParams.verificationStatus"
      :name-width="3"
      :value-width="9"
    />
    <FormField
      v-if="item.projectPromoParams.verificationChannel"
      :name="'канал верификации'"
      :value="item.projectPromoParams.verificationChannel"
      :name-width="3"
      :value-width="9"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { formatDate, formatCurrency } from '@/utils/commonUtils/libUtils';

import server from '@/server';
import QuestionPopover from '@/components/_generic/QuestionPopover.vue';
import FormField from '@/components/_generic/forms/FormField.vue';
import FormFieldTitle from '@/components/_generic/forms/FormFieldTitle.vue';

const props = defineProps(['project']);
const route = useRoute();

const loadingProject = ref(false);
const item = ref(null);

onMounted(() => {
  fetchProjectOldAsync();
});

const isShowDebtor = computed(() => {
  if (!item.value || !item.value.projectPromoParams) {
    return false;
  }
  return !!(
    item.value.projectPromoParams.debtorTitle ||
    item.value.projectPromoParams.debtorOgrn ||
    item.value.projectPromoParams.debtorInn ||
    item.value.projectPromoParams.debtorDescription ||
    item.value.projectPromoParams.debtorSite
  );
});
const isShowSupplier = computed(() => {
  if (!item.value || !item.value.projectPromoParams) {
    return false;
  }
  return !!(
    item.value.projectPromoParams.supplierTitle ||
    item.value.projectPromoParams.supplierOgrn ||
    item.value.projectPromoParams.supplierInn ||
    item.value.projectPromoParams.supplierDescription ||
    item.value.projectPromoParams.supplierSite
  );
});
const isShowFactoringCompany = computed(() => {
  if (!item.value || !item.value.borrower) {
    return false;
  }
  return !!(
    item.value.borrower.companyShortName ||
    item.value.borrower.ogrn ||
    item.value.borrower.inn
  );
});
const isShowFactoringAgreement = computed(() => {
  if (!item.value || !item.value.projectPromoParams) {
    return false;
  }
  return !!(
    item.value.projectPromoParams.contractTitle ||
    item.value.projectPromoParams.contractNumber ||
    item.value.projectPromoParams.contractDateAt ||
    item.value.projectPromoParams.contractType
  );
});
const isShowContractSupply = computed(() => {
  if (!item.value || !item.value.projectPromoParams) {
    return false;
  }
  return !!(
    item.value.projectPromoParams.supplyContractTitle ||
    item.value.projectPromoParams.supplyContractNumber ||
    item.value.projectPromoParams.supplyContractDateAt ||
    item.value.projectPromoParams.supplyContractTitle ||
    item.value.projectPromoParams.supplyContractIndulgence
  );
});
const isShowRegisterOfClaim = computed(() => {
  if (!item.value || !item.value.projectPromoParams) {
    return false;
  }
  return !!(
    item.value.projectPromoParams.supplyRegisterNumber ||
    item.value.projectPromoParams.supplyRegisterDateAt ||
    item.value.projectPromoParams.supplyRegisterAmount ||
    item.value.projectPromoParams.supplyRegisterPaymentDateAt
  );
});
const isShowVerificationClaim = computed(() => {
  if (!item.value || !item.value.projectPromoParams) {
    return false;
  }
  return !!(
    item.value.projectPromoParams.verificationStatus ||
    item.value.projectPromoParams.verificationChannel
  );
});

const fetchProjectOldAsync = () => {
  if (!route.params.id || loadingProject.value) {
    return;
  }
  loadingProject.value = true;
  const query = {
    projectId: route.params.id,
  };
  const params = {
    borrowerId: props.project.borrower.id,
  };
  return server.getBorrowerFactoring
    .send(query, { params })
    .pipe(onFetchProjectOldAsyncSuccess, () => {
      loadingProject.value = false;
    })
    .exec();
};
const onFetchProjectOldAsyncSuccess = (obj) => {
  loadingProject.value = false;

  if (!obj || !obj.data) {
    return;
  }
  const data = obj.data;
  item.value = data;
};
const getHttpString = (str) => {
  return str.includes('http') ? str : 'http://' + str;
};
</script>
<style></style>
