<template>
  <section class="page" style="padding-top: 20px">
    <div class="back-link">
      <a href="" @click.prevent="$router.go(-1)">Назад</a>
    </div>

    <div class="investment">
      <div class="row row-center" style="margin-bottom: 40px">
        <div class="auth auth-black">
          <recover-password :raw-phone="formattedPhone"> </recover-password>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';

import RecoverPassword from '@/components/Settings/SettingsChangePassword/SettingsChangePasswordForm.vue';
import { formatLoginToPhoneNumber } from '@/utils/commonUtils/utils';

import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const formattedPhone = computed(() => {
  return formatLoginToPhoneNumber(store.state.session.login);
});
</script>
