<template>
  <div class="col-md-12 wrapper-step2">
    <div class="qr-wrapper">
      <div class="qr-code-panel">
        <img :src="'data:image/png;base64,' + props.qrCode" />
      </div>
      <div class="qr-code-wrapper">
        <div class="qr-amount">
          к оплате: {{ formatCurrency(props.amountValue, 0) }}&nbsp;₽
        </div>
        <button
          type="button"
          class="button button-text qr-back"
          @click="onClickBack"
        >
          <i class="icon_arrow_back"></i>изменить сумму
        </button>
      </div>
    </div>
    <div class="text-wrapper">
      <div class="text-wrapper-title">как пополнить счет через QR-код?</div>
      <div class="text-wrapper-steps">
        <div class="text-wrapper-step">
          <div class="text-wrapper-step-number">1.</div>
          <div class="text-wrapper-step-text">
            наведите камеру телефона на QR-код
          </div>
        </div>
        <div class="text-wrapper-step">
          <div class="text-wrapper-step-number">2.</div>
          <div class="text-wrapper-step-text">
            выберите банковское приложение из списка
          </div>
        </div>
        <div class="text-wrapper-step">
          <div class="text-wrapper-step-number">3.</div>
          <div class="text-wrapper-step-text">
            подтвердите платеж в мобильном приложении банка
          </div>
        </div>
      </div>
      <div class="text-wrapper-footer">
        при переводе по СБП обычно деньги зачисляются мгновенно, но иногда время
        ожидания составляет до 15 минут
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';

const emit = defineEmits(['prevStep']);
const props = defineProps({
  amountValue: {
    type: Number,
  },
  qrCode: {
    type: String,
    required: true,
  },
});

const onClickBack = () => {
  emit('prevStep');
};
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.qr-code-wrapper {
  margin-top: 20px;
  width: 100%;
}
.wrapper-step2 {
  display: flex;
  margin-bottom: 19px;
}
.step-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $breakwater;
}
.qr-wrapper {
  background: #f2f4f8;
  border-radius: 14px;
  width: 277px;
  padding: 24px;
}
.qr-code-panel {
  width: 230px;
  height: 230px;
  background: white;
  img {
    width: 230px;
    height: 230px;
  }
}
.qr-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
}
.qr-back {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $tiffany;
  cursor: pointer;
  margin-top: 8px;
  &:hover {
    opacity: 0.5;
  }
}
.icon_arrow_back {
  margin-right: 6px;
}
.text-wrapper {
  padding: 16px 20px 20px 20px;
}
.text-wrapper-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
}
.text-wrapper-steps {
  margin-top: 20px;
}
.text-wrapper-step {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  margin-bottom: 18px;
  max-width: 238px;
  display: flex;
}
.text-wrapper-step-number {
  margin-right: 5px;
}
.text-wrapper-footer {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey;
  margin-top: 55px;
  max-width: 335px;
}
</style>
