export default {
  getItem() {
    if (!localStorage) {
      return;
    }
    return localStorage.getItem('vuex');
  },
  setItem(value) {
    if (!localStorage) {
      return;
    }
    localStorage.setItem('vuex', JSON.stringify(value));
  },
  getProjects() {
    if (!localStorage) {
      return;
    }
    return localStorage.getItem('projects');
  },
  setProjects(value) {
    if (!localStorage) {
      return;
    }
    localStorage.setItem('projects', JSON.stringify(value));
  },
  getNamedItem(name) {
    if (!localStorage) {
      return;
    }
    return localStorage.getItem(name);
  },
  setNamedItem(name, value) {
    if (!localStorage) {
      return;
    }
    localStorage.setItem(name, value);
  },
};
