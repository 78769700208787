import NewInvestmentsPage from '@/pages/NewInvestmentsPage.vue';
import NewInvestmentsMyPortfolio from '@/pages/NewInvestments/NewInvestmentsMyPortfolio.vue';
import NewInvestmentsSellList from '@/pages/NewInvestments/NewInvestmentsSellList.vue';
import InvestmentsPage from '@/pages/InvestmentsPage.vue';
import NewInvestmentsSellItem from '@/pages/NewInvestments/NewInvestmentsSellItem.vue';
import NewInvestmentsNewRequest from '@/pages/NewInvestments/NewInvestmentsNewRequest.vue';

export default [
  {
    path: '/new-investments',
    name: 'newInvestments',
    components: {
      content: NewInvestmentsPage,
    },
    meta: {
      title: 'Инвестиции',
    },
    redirect: () => {
      return {
        name: 'newInvestmentsMyPortfolio',
      };
    },
    children: [
      {
        name: 'newInvestmentsMyPortfolio',
        path: '',
        component: NewInvestmentsMyPortfolio,
      },
      {
        name: 'newInvestmentsSellList',
        path: 'sell-list',
        component: NewInvestmentsSellList,
      },
    ],
  },
  {
    path: '/investments',
    name: 'investments',
    components: {
      content: InvestmentsPage,
    },
    meta: {
      title: 'Мои инвестиции',
    },
    redirect: () => {
      return {
        name: 'newInvestmentsMyPortfolio',
      };
    },
    children: [
      {
        name: 'newInvestmentsMyPortfolio',
        path: '',
        component: NewInvestmentsMyPortfolio,
      },
      {
        name: 'newInvestmentsSellList',
        path: 'sell-list',
        component: NewInvestmentsSellList,
      },
    ],
  },
  {
    path: '/new-investments/sell-list/:id',
    name: 'sellListItem',
    components: {
      content: NewInvestmentsSellItem,
    },
    meta: {
      title: 'Заявка на продажу',
    },
  },
  {
    path: '/new-investments/new-sell-request',
    name: 'newSellRequest',
    components: {
      content: NewInvestmentsNewRequest,
    },
    meta: {
      title: 'Создание заявки',
    },
  },
];
