<template>
  <PotokModal @close="emits('close')">
    <template #content>
      <div class="potok-text-h1">
        Сделай ставку на повышение!
      </div>

      <div class="mt-5">
        Пополни счет с 14 по 24 октября и получи гарантированный кэшбек до 2%,
        который УДВОИТСЯ в случае повышения ключевой ставки в октябре. Подробные условия читай <a href="https://files.potok.digital/Документы/раздел правила платформы/Акция_Двойной кешбэк.pdf" target="_blank">по ссылке</a>.
      </div>
    </template>
  </PotokModal>
</template>

<script setup>
import { PotokModal } from 'potok-uikit';

const emits = defineEmits(['close'])
</script>