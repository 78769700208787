/**
 * Throws an error when a value is falsely
 * @param {*} value
 * @param {String} [message]
 */
export const assert = function (value, message = '') {
  if (!value) {
    throw new Error(message);
  }
};
/**
 * Do nothing
 */
export const noOp = function () {
  // empty body
};
/**
 * Do nothing
 */
export const nextTick = function (fn) {
  setTimeout(fn, 1);
};

export const isString = function (any) {
  return typeof any === 'string';
};
