<template>
  <div class="loan-overdue">
    <div v-if="loading">
      <Loader class="mt-5" />
    </div>
    <div v-else class="overdue-table-wrapper1">
      <GeneralTable
        :items="items"
        :columns="columnsOverdue"
        class="overdue-table"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import server from '@/server';
import { Loader } from 'potok-uikit';
import GeneralTable from '@/components/_generic/table/GeneralTable.vue';
import constants from '@/constants';

import { useStateStore } from '@/store/stateStore';

import {
  hashArray,
  filter,
  map,
  flatten,
  merge,
  unique,
  uuidv4,
  copy,
  isNull,
  formatDate,
  noBrCurrency,
  autoLink,
} from '@/utils/commonUtils/utils';

const store = useStateStore();
const route = useRoute();

const loadingOverdueChecklistTypes = ref(false);
const loadingOverdueChecklistList$ProjectId = ref(false);
const allTypes = ref({});
const typesFuture = ref({});
const items = ref([]);

// start columns

const formatEventDate = (date) => {
  return isNull(date) ? 'Планируется' : formatDate(date);
};

const columnsOverdue = [
  {
    title: 'Дата',
    is_mobile_full: true,
    content_desktop(item) {
      return `
      ${formatEventDate(item.eventAt)}
      `;
    },
  },
  {
    title: 'Событие',
    is_mobile_full: true,
    content_desktop(item) {
      return `
      ${item.typeTitle}
      `;
    },
  },
  {
    title: 'Контрагент',
    is_mobile_full: true,
    content_desktop(item) {
      return `
      ${item.isGuarantor ? 'поручитель' : 'заемщик'}
      `;
    },
  },
  {
    title: 'Комментарий',
    is_mobile_full: true,
    td_desktop_style: {
      'white-space': 'break-spaces',
      'word-break': ' break-word',
    },
    content_desktop(item) {
      return `
      ${noBrCurrency(autoLink(item.comment))}
      `;
    },
  },
];

// end columns

const loading = computed(() => {
  return (
    loadingOverdueChecklistTypes.value ||
    loadingOverdueChecklistList$ProjectId.value
  );
});

onMounted(() => {
  initializeAsync();
});

const initializeAsync = () => {
  const promises = [
    getOverdueChecklistTypesAsync(),
    fetchOverdueMessagesAsync(),
  ];
  return Promise.all(promises).then(onInitializeAsyncSuccess);
};
const onInitializeAsyncSuccess = ([types, messages]) => {
  /*
          Итак, у нас есть 2 множества: Событий (Messages, M) и Видов событий (Types, T).
          Они связаны один ко многим (M) >- (T). Множество (T) упорядочено по ключу position по возрастанию.
          У него также есть подмножество Planned (Tp), выделенное по признаку isPlanned.

          Если взять все события проекта (M) и найти встретившиеся среди них виды (T'),
          то среди (T') нужно найти max(position).

          Затем из (Tp) выделить подмножество Tp Future (Tpf) по признаку position(Tp) > max(position(T')).

          Мы строим таблицу для гостя, где будут строки, выводимые по шаблону «Событие»:
          (M) — сортировка по date desc
          (M)
          (M)
          (Tpf) — сортировка по position asc
          (Tpf)
        */

  /*
          Схема с множествами — https://gitlab.mmdev.ru/modulemoney/module-money-server-app/issues/674#note_9792
          Итак, у нас есть 2 множества: Событий (Messages, M) и Видов событий (Types, T).
          1) Разделяем множество сообщение на выполненные и не выполненные
          2) Выделяем типы сообщений, которые уже выполнены
          3) Удаляем из множества типов, типы сообщений которые уже выполнены (пункт 2) (Tt)
          4) Сравниваем множество типов полученное в п.3 с множеством невыполненных сообщений из п.1 по типу
          5) Подставляем в множество типов (isPlanned = true) сообщения из пункта п.4
         */

  const messagesPerformed = filter(messages, (x) => x.isPerformed);
  const messagesNotPerformed = filter(messages, (x) => !x.isPerformed);
  const positions = hashArray(
    types,
    (x) => x.id,
    (x) => x.position,
  );

  // поиск position (черты разделения между сообщениями messagesPerformed и types)
  // после которого пойдут Типы в будущем времени
  const maxPresentedPosition = getMaxPresentedPosition(
    messagesPerformed,
    positions,
  );

  // убираем типы, которые уже используются в messagesPerformed
  const futureTypes = filter(types, (x) => x.position > maxPresentedPosition);

  // ищем совпадение типов с messagesNotPerformed
  const messagesNotPerformedHash = hashArrayExtended(messagesNotPerformed);
  const futureTypesHash = hashArray(
    futureTypes,
    (x) => x.typeId,
    (x) => x.position,
  );
  const messagesNotPerformedFuture = filter(
    messagesNotPerformedHash,
    (x) => futureTypesHash[x.typeId],
  );

  // подставляем найденные (messagesNotPerformedFuture) сообщения в будущие типы (futureTypesPlanned)
  const futureTypesPlanned = filter(futureTypes, (x) => x.isPlanned);
  const futureTypesWithMessage = map(futureTypesPlanned, (type) => {
    const selectMessage = messagesNotPerformedFuture[type.id]
      ? messagesNotPerformedFuture[type.id]
      : null;
    return selectMessage ? selectMessage : type;
  });

  // преобразование будущих типов и сообщений к общему интерфейсу
  const futureTypesWithMessageToInterface = map(
    flatten(futureTypesWithMessage),
    (item) => {
      return item.projectId === undefined
        ? buildItemFromType(item)
        : buildItemFromMessage(item);
    },
  );

  const itemsMerged = merge(
    map(messagesPerformed, buildItemFromMessage),
    futureTypesWithMessageToInterface,
  );

  // добавляем логику блокировки компании, т.е. если у пользователя есть поле
  // isCourtInProcess, то ему не отображаются события цессии
  items.value = store.state.user.isCourtInProcess
    ? itemsMerged.filter(
        (item) => item.typeId !== constants.overdueChecklistType.cession,
      )
    : itemsMerged;

  items.value.forEach((item) => {
    if (allTypes.value && allTypes.value[item.typeId]) {
      item.typeTitle = allTypes.value[item.typeId];
    }
  });
};
const getOverdueChecklistTypesAsync = () => {
  loadingOverdueChecklistTypes.value = true;
  return server.getOverdueChecklistTypes
    .send()
    .pipe(onGetOverdueChecklistTypesAsyncSuccess, () => {
      loadingOverdueChecklistTypes.value = false;
    })
    .exec();
};
const onGetOverdueChecklistTypesAsyncSuccess = ({ data }) => {
  loadingOverdueChecklistTypes.value = false;
  const getKeys = (item) => item.id;
  const getValues = (item) => item.title;
  const getValuesFuture = (item) => item.titleInFuture;
  allTypes.value = hashArray(data, getKeys, getValues);
  typesFuture.value = hashArray(data, getKeys, getValuesFuture);
  return data;
};
const fetchOverdueMessagesAsync = () => {
  const params = {
    projectId: route.params.id,
  };
  loadingOverdueChecklistList$ProjectId.value = true;
  return server.getOverdueChecklistList$ProjectId
    .send(null, { params })
    .pipe(onFetchOverdueMessagesAsyncSuccess, () => {
      loadingOverdueChecklistList$ProjectId.value = false;
    })
    .exec();
};
const onFetchOverdueMessagesAsyncSuccess = ({ data }) => {
  loadingOverdueChecklistList$ProjectId.value = false;
  return data;
};
const hashArrayExtended = (array) => {
  return array.reduce((acc, item, index, arr) => {
    const getKey = (item) => item.typeId;
    const getValue = (item) => filter(arr, (x) => x.typeId === item.typeId);
    return Object.assign(acc, {
      [getKey(item)]: getValue(item),
    });
  }, {});
};
const getMaxPresentedPosition = (messagesPerformed, positions) => {
  const presentedTypes = unique(messagesPerformed, (x) => x.typeId);
  return Math.max(...map(presentedTypes, (x) => positions[x.typeId]));
};
const buildItemFromType = (obj) => {
  return Object.assign(
    {
      id: uuidv4(),
      typeId: obj.id,
      title: '',
      comment: '',
      eventAt: null,
      isEnabled: false,
      isGuarantor: false,
      documents: [],
    },
    {
      isEvent: false,
    },
  );
};
const buildItemFromMessage = (obj) => {
  return Object.assign(copy(obj), {
    isEvent: true,
  });
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loan-overdue {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px #e5e9f2;
  border-radius: 6px;
  margin: 30px 0;
  padding: 20px 20px 20px 20px;

  @media (max-width: $size_767) {
    padding: 16px;
  }
}
.overdue-table {
  width: 100%;
}
.table > thead > tr > th {
  border-bottom: none;
}
</style>
