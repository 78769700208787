<template>
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-4">
      <AnalyticsHistoryTotalIncome
        :loading="props.loading"
        :income="props.total.income"
      />
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <AnalyticsHistoryTotalOutcome
        :loading="props.loading"
        :outcome="props.total.outcome"
      />
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <AnalyticsHistoryTotalEmail
        :loading="loadingEmail"
        @send-email="emit('sendEmail')"
      />
    </div>
  </div>
</template>
<script setup>
import AnalyticsHistoryTotalIncome from '../AnalyticsHistoryOperationTotalIncome.vue';
import AnalyticsHistoryTotalOutcome from '../AnalyticsHistoryOperationTotalOutcome.vue';
import AnalyticsHistoryTotalEmail from '../AnalyticsHistoryOperationTotalEmail.vue';

const emit = defineEmits(['sendEmail']);
const props = defineProps({
  loading: Boolean,
  loadingEmail: Boolean,
  total: Object,
});
</script>
<style></style>
