<template>
  <ModalFull title="" :close-click-outside="false" @close="emit('close')">
    <template #content>
      <div class="potok-text-h2 mb-3">подтвердить все активные заявки?</div>
    </template>
    <template #actions>
      <PotokButton
        text="подтвердить"
        :loading="loading"
        @click="onClickConfrim"
      />
      <PotokButton
        class="btn-cancel"
        text="отменить"
        :disabled="loading"
        theme="tertiary"
        @click="onClickCancel"
      />
    </template>
  </ModalFull>
</template>
<script setup>
import { ref } from 'vue';
import server from '@/server';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { useStateStore } from '@/store/stateStore';
import { PotokButton } from 'potok-uikit';

const emit = defineEmits(['close', 'confirm']);

const store = useStateStore();

const loading = ref(false);

const acceptActiveRequests = () => {
  loading.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };

  return server.postInvestmentsSellListApproveAll
    .send(query)
    .pipe(
      () => {
        loading.value = false;
        emit('close');
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

const onClickConfrim = () => {
  acceptActiveRequests();
};
const onClickCancel = () => {
  emit('close');
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

:deep(.modal_block_container) {
  max-width: 420px;
}
:deep(.modal_block_content) {
  text-align: center;
}
:deep(.modal_block_title) {
  justify-content: center;
}
:deep(.modal_block_actions) {
  margin: 20px auto 0;
  display: flex;
}
.btn-cancel {
  margin-left: 10px;
  @media (max-width: $size_767) {
    margin-left: initial;
    margin-top: 10px;
  }
}
</style>
