import { useStateStore } from '@/store/stateStore';

const authenticationGuard = (to, from, next) => {
  // Если юзер приходит с вебвью мобилки, минуя логин - пропускаем
  if (to.query.webview) {
    return next();
  }
  // Если юзер залогинен, пропускаем
  if (useStateStore().isAuthenticated) {
    return next();
  }

  // Если роут всегда доступен, пропускаем
  if (to.meta.alwaysAvailable) {
    return next();
  }

  // Если юзер не залогинен, и роут не доступен для неавторизованного юзера - редиректим на логин
  // const nextObj = {
  //   name: 'login',
  //   query: {
  //     ...to.query,
  //     url: `/${to.name}`,
  //   },
  // };
  // if (to.params.id) {
  //   nextObj.id = to.params.id;
  // }

  // return next(nextObj);
  next();
};

export default authenticationGuard;
