<template>
  <ModalFull title="загрузка паспорта" @close="close">
    <template #content>
      <div>
        <div class="rejected-passport-text potok-text-body-2">
          загрузите сканы или фотографии разворота первой страницы паспорта и
          страницу с пропиской — мы проверим и обновим данные в течение 2
          рабочих дней
        </div>

        <upload-passports @success="goToLoans" />
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { useRouter } from 'vue-router';
import UploadPassports from '@/components/_generic/account/moneyin/UploadPassports.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const router = useRouter();

const emit = defineEmits(['close']);

const goToLoans = () => {
  router.push({ name: 'loans' });
};

const close = () => {
  emit('close');
};
</script>

<style scoped lang="scss">
@use '../../scss/variables.scss' as *;

.rejected-passport-text {
  text-align: center;
  @media (max-width: $size_767) {
    text-align: left;
  }
}
:deep(.modal_block_container) {
  max-width: 520px;
  @media (max-width: $size_767) {
    max-width: inherit;
  }
}
:deep(.modal_block_title) {
  justify-content: center;
}
</style>
