<template>
  <div class="custom-radio-row">
    <div
      v-for="(item, index) in props.items"
      :key="index"
      class="row-item"
      @click.prevent="changeValue(item)"
    >
      <label :class="[{ disabled: props.disabled }]">
        <span
          class="check"
          :class="{
            active: item.value === props.modelValue,
          }"
        ></span>
        <span class="label-text">{{ item.title }}</span>
      </label>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  items: {
    type: Array,
  },
  modelValue: {},
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'makeDirty', 'change']);

const changeValue = (item) => {
  if (props.disabled) return;
  emit('update:modelValue', item.value);
};
</script>

<style lang="scss">
@use '../../scss/variables.scss' as *;

.custom-radio-row {
  color: #717d83;

  .custom-radio-hidden {
    display: none;
  }
  .active {
    border: 6px solid #173b57;
  }

  &:last-child {
    margin-bottom: 0;
  }
  label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  label.disabled {
    .check {
      background-color: #b5b5b5;
      border-color: #b5b5b5;
    }
  }
  .row-item {
    margin: 14px 0;
  }
  .check {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 2px;
    border-radius: 10px;
    background-color: #f2f4f8;
  }
  .label-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey;
  }
}
</style>
