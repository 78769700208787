<template>
  <div class="analytics-event content-block">
    <div class="events-row">
      <div class="events-col events-col-1">
        <div
          class="date property"
          v-html="
            formatDateResponsiveYear(props.item.date, 'dd MMMM yyyy') +
            ' в&nbsp;' +
            formatDate(props.item.date, 'HH:mm')
          "
        ></div>
        <div class="number">
          №<span>{{ props.item.projectNumber }}</span>
        </div>
      </div>
      <div class="events-col events-col-2 name property">
        {{ props.item.borrowerTitle }}
      </div>
      <div class="events-col events-col-3">
        <div class="sum-wrapper sum-wrapper-small">
          <div class="sum">
            <div class="property">погашено всего</div>
            <div class="value">
              <span>{{
                formatCurrency(props.item.refundedFactAmount, 0)
              }}</span>
            </div>
            <div class="property">из</div>
            <div class="value">
              <span>{{ formatCurrency(fullLoanAmountNow(), 0) }}</span> ₽
            </div>
          </div>
        </div>
        <div class="project-progress">
          <div
            :style="{
              width:
                formatLoanProgress(
                  props.item.refundedFactAmount - props.item.payoffAmount,
                  fullLoanAmountNow().toNumber(),
                ) + '%',
            }"
            class="bar"
          ></div>
        </div>
        <div class="property">
          <br />погашено сейчас
          <span>{{ formatCurrency(props.item.payoffAmount, 0) }}</span> ₽
        </div>
      </div>
      <div class="events-col events-col-4 rate">
        <div class="property">ставка</div>
        <div class="value">
          <span>{{ formatCurrency(props.item.loanRate * 100, 2) }}</span
          >%
        </div>
      </div>
      <div class="events-col events-col-5 term">
        <div class="property">срок</div>
        <div class="value">
          <span>{{ props.item.actualLoanTerm }}</span>
          <template
            v-if="props.item.factLoanTerm - props.item.actualLoanTerm > 0"
          >
            <span class="red"
              >+
              <span>{{
                props.item.factLoanTerm - props.item.actualLoanTerm
              }}</span></span
            >
            <span>{{
              pluralize(props.item.factLoanTerm - props.item.actualLoanTerm, [
                ' день',
                ' дня',
                ' дней',
              ])
            }}</span>
          </template>
          <template v-else>
            <span>{{
              pluralize(props.item.actualLoanTerm, [' день', ' дня', ' дней'])
            }}</span>
          </template>
        </div>
      </div>
      <div class="events-col events-col-6">
        <div class="project-tag project-partly-closed">
          вышел из мягкой просрочки
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  decimal,
  formatCurrency,
  formatDate,
  formatDateResponsiveYear,
  pluralize,
} from '@/utils/commonUtils/utils';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const formatLoanProgress = (part, full) => {
  return decimal(part).div(full).mul(100).toFixed(2);
};
const fullLoanAmountNow = () => {
  return decimal(props.item.refundedFactAmount)
    .plus(props.item.debtAmount)
    .minus(props.item.payoffAmount);
};
</script>
