/* eslint-disable */
function isObject(o) {
  return (
    typeof o === 'object' &&
    o !== null &&
    o.constructor &&
    Object.prototype.toString.call(o).slice(8, -1) === 'Object'
  );
}

function extend(target, src) {
  const noExtend = ['__proto__', 'constructor', 'prototype'];
  Object.keys(src)
    .filter((key) => noExtend.indexOf(key) < 0)
    .forEach((key) => {
      if (typeof target[key] === 'undefined') target[key] = src[key];
      else if (
        isObject(src[key]) &&
        isObject(target[key]) &&
        Object.keys(src[key]).length > 0
      ) {
        if (src[key].__swiper__) target[key] = src[key];
        else extend(target[key], src[key]);
      } else {
        target[key] = src[key];
      }
    });
}

function needsNavigation(props) {
  if (props === void 0) {
    props = {};
  }

  return (
    props.navigation &&
    typeof props.navigation.nextEl === 'undefined' &&
    typeof props.navigation.prevEl === 'undefined'
  );
}

function needsPagination(props) {
  if (props === void 0) {
    props = {};
  }

  return props.pagination && typeof props.pagination.el === 'undefined';
}

function needsScrollbar(props) {
  if (props === void 0) {
    props = {};
  }

  return props.scrollbar && typeof props.scrollbar.el === 'undefined';
}

function uniqueClasses(classNames) {
  if (classNames === void 0) {
    classNames = '';
  }

  const classes = classNames
    .split(' ')
    .map((c) => c.trim())
    .filter((c) => !!c);
  const unique = [];
  classes.forEach((c) => {
    if (unique.indexOf(c) < 0) unique.push(c);
  });
  return unique.join(' ');
}

export {
  isObject,
  extend,
  needsNavigation,
  needsPagination,
  needsScrollbar,
  uniqueClasses,
};
