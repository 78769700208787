import { defineStore } from 'pinia';

import { defaultStore } from './default.js';
import stateGetters from './stateGetters';
import stateActions from './stateActions.js';

export const useStateStore = defineStore('StateStore', {
  state: () => {
    return {
      state: JSON.parse(JSON.stringify(defaultStore)),
    };
  },
  getters: stateGetters,
  actions: stateActions,
});
