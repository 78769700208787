<template>
  <PotokPage class="subscription-wrapper">
    <div class="auth">
      <template v-if="isSubscription">
        <div class="title">Отписка от Email-рассылки</div>
        <div class="title">{{ getEmail() }}</div>
        <div class="button-center">
          <button
            :disabled="!(isValid(getEmail()) && isValid(getHash()))"
            type="button"
            class="button auth-button"
            @click="subscriptionAsync(getEmail(), getHash())"
          >
            Отписаться
          </button>
        </div>
      </template>
      <template v-else>
        <div class="title">
          {{ subscriptionSuccessText(getEmail()) }}
        </div>
      </template>
    </div>
  </PotokPage>
</template>

<script>
import mixins from '@/mixins';
import { PotokPage } from 'potok-uikit'

export default {
  components: {
    PotokPage,
  },
  mixins: [mixins.common],
  data() {
    return {
      isSubscription: true,
    };
  },
  methods: {
    getEmail() {
      return this.$route.query.email || '';
    },
    getHash() {
      return this.$route.query.hash || '';
    },
    isValid: Boolean,
    subscriptionAsync(email, hash) {
      if (!this.isValid(email) || !this.isValid(hash)) {
        return;
      }
      const query = {
        email,
        hash,
      };
      return this.server.postUsersSubscription
        .send(query)
        .pipe(this.onSubscriptionAsyncSuccess.bind(this, query))
        .exec();
    },
    onSubscriptionAsyncSuccess() {
      this.isSubscription = false;
    },
    subscriptionSuccessText(email) {
      return 'Подписку выключили для ' + email;
    },
  },
};
</script>
<style lang="scss" scoped>
.subscription-wrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 100px;
}
</style>
