export default {
  getNotification: {
    url: `/api/notifications`,
    method: 'GET',
  },
  putNotification: {
    url: `/api/notifications/:notificationId`,
    method: 'PUT',
  },
};
