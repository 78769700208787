import common from './common';
import settings from './settings';
import loans from './loans';
import analytics from './analytics';
import investments from './investments';

const routes = [...common, ...settings, ...loans, ...analytics, ...investments];

routes.push({
  path: '/:catchAll(.*)',
  redirect() {
    return { name: 'main' };
  },
});

export default routes;
