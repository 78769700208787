import { ref } from 'vue';

import { decimal } from '@/utils/commonUtils/utils';

const SortItems = (items, options) => {
  const sortColumn = options.sortColumn;
  const sortColumnDirection = options.sortColumnDirection;

  return items.sort((a, b) => {
    if (sortColumn === 'stavka') {
      const isBuybackA =
        a.purchaseDeals && a.purchaseDeals.length > 0 ? true : false;
      const loanRateA = isBuybackA
        ? a.purchaseDeals[0].purchasedInvestmentRate
        : a.loanRate * 100;

      const isBuybackB =
        b.purchaseDeals && b.purchaseDeals.length > 0 ? true : false;
      const loanRateB = isBuybackB
        ? b.purchaseDeals[0].purchasedInvestmentRate
        : b.loanRate * 100;

      if (sortColumnDirection === 'up') {
        return loanRateA - loanRateB;
      } else {
        return loanRateB - loanRateA;
      }
    }

    if (sortColumn === 'vozvrat') {
      if (sortColumnDirection === 'up') {
        return new Date(a.loanDue) - new Date(b.loanDue);
      } else {
        return new Date(b.loanDue) - new Date(a.loanDue);
      }
    }

    if (sortColumn === 'invest_date') {
      if (sortColumnDirection === 'up') {
        return new Date(a.loanFundedAt) - new Date(b.loanFundedAt);
      } else {
        return new Date(b.loanFundedAt) - new Date(a.loanFundedAt);
      }
    }

    if (sortColumn === 'refunded_date') {
      if (sortColumnDirection === 'up') {
        return new Date(a.loanRefundedAt) - new Date(b.loanRefundedAt);
      } else {
        return new Date(b.loanRefundedAt) - new Date(a.loanRefundedAt);
      }
    }

    if (sortColumn === 'cession_date') {
      if (sortColumnDirection === 'up') {
        return new Date(a.cessionStartAt) - new Date(b.cessionStartAt);
      } else {
        return new Date(b.cessionStartAt) - new Date(a.cessionStartAt);
      }
    }

    if (sortColumn === 'osd') {
      const value_a = decimal(a.myInvestmentDebt);
      const value_b = decimal(b.myInvestmentDebt);
      if (sortColumnDirection === 'up') {
        return value_a - value_b;
      } else {
        return value_b - value_a;
      }
    }
    if (sortColumn === 'overdue') {
      const value_a = a.daysOverdue;
      const value_b = b.daysOverdue;
      if (sortColumnDirection === 'up') {
        return value_a - value_b;
      } else {
        return value_b - value_a;
      }
    }
  });
};

export default function investmentsSortComposable() {
  const sortColumn = ref(null);
  const sortColumnDirection = ref('up');

  const sortItems = (_items) => {
    if (!sortColumn.value) {
      return _items;
    }
    return SortItems(_items, {
      sortColumn: sortColumn.value,
      sortColumnDirection: sortColumnDirection.value,
    });
  };

  const selectSortColumn = (_data) => {
    if (
      !sortColumn.value ||
      (sortColumn.value && sortColumn.value != _data.sort_slug)
    ) {
      sortColumn.value = _data.sort_slug;
      sortColumnDirection.value = 'up';
    } else if (
      sortColumn.value === _data.sort_slug &&
      sortColumnDirection.value === 'up'
    ) {
      sortColumnDirection.value = 'down';
    } else if (
      sortColumn.value === _data.sort_slug &&
      sortColumnDirection.value === 'down'
    ) {
      sortColumn.value = null;
      sortColumnDirection.value = 'up';
    }
  };

  const selectSortColumnMobile = ({ sortField }) => {
    switch (sortField) {
      case 'default':
        sortColumn.value = null;
        sortColumnDirection.value = 'up';
        break;
      case 'stavka_plus':
        sortColumn.value = 'stavka';
        sortColumnDirection.value = 'down';
        break;
      case 'stavka_minus':
        sortColumn.value = 'stavka';
        sortColumnDirection.value = 'up';
        break;
      case 'invest_date_plus':
        sortColumn.value = 'invest_date';
        sortColumnDirection.value = 'down';
        break;
      case 'invest_date_minus':
        sortColumn.value = 'invest_date';
        sortColumnDirection.value = 'up';
        break;
      case 'vozvrat_plus':
        sortColumn.value = 'vozvrat';
        sortColumnDirection.value = 'down';
        break;
      case 'vozvrat_minus':
        sortColumn.value = 'vozvrat';
        sortColumnDirection.value = 'up';
        break;
      case 'osd_plus':
        sortColumn.value = 'osd';
        sortColumnDirection.value = 'down';
        break;
      case 'osd_minus':
        sortColumn.value = 'osd';
        sortColumnDirection.value = 'up';
        break;
    }
  };
  return {
    sortColumn,
    sortColumnDirection,
    sortItems,
    selectSortColumn,
    selectSortColumnMobile,
  };
}
