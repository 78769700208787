<template>
  <div class="row">
    <RefillBalanceAccountDesktop
      v-if="!isMobile && inited"
      :fields="fields"
      :payment-values="paymentValues"
      :data="props.data"
    />
    <RefillBalanceAccountMobile
      v-if="isMobile && inited"
      :fields="fields"
      :payment-values="paymentValues"
      :data="props.data"
    />
  </div>
</template>
<script setup>
import { ref, computed, watch, nextTick } from 'vue';

import { useStateStore } from '@/store/stateStore';
import detectDevice from '@/composables/detectDeviceComposable.js';
import RefillBalanceAccountDesktop from './RefillBalanceAccountDesktop.vue';
import RefillBalanceAccountMobile from './RefillBalanceAccountMobile.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});
const { isMobile } = detectDevice();
const store = useStateStore();

const inited = ref(true);

const payeeName = 'ООО "Поток.Диджитал"';
const payeeInn = '9701046627';
const payeeKpp = '770101001';
const payeePersonalAcc = '40702810301600011889';
const payeeBankName = 'АО "Альфа-Банк"';
const payeeBankBic = '044525593';
const payeeBankCorrespAcc = '30101810200000000593';
const accountNumber = computed(() => {
  if (store.selectedCompany) {
    return store.selectedCompany.investorAccountNumber;
  }
  return null;
});
const purpose = computed(() => {
  return `Пополнение лицевого счета ${accountNumber.value}. Без НДС`;
});

const paymentValues = {
  payeeName,
  payeeInn,
  payeeKpp,
  payeePersonalAcc,
  payeeBankName,
  payeeBankBic,
  payeeBankCorrespAcc,
  purpose: purpose.value,
};
const fields = ref([
  {
    name: 'получатель',
    value: payeeName,
    copied: false,
  },
  {
    name: 'инн',
    value: payeeInn,
    copied: false,
  },
  {
    name: 'кпп',
    value: payeeKpp,
    copied: false,
  },
  {
    name: 'банк',
    value: payeeBankName,
    copied: false,
  },
  {
    name: 'бик',
    value: payeeBankBic,
    copied: false,
  },
  {
    name: 'корр счет',
    value: payeeBankCorrespAcc,
    copied: false,
  },
  {
    name: 'номер счета',
    value: '40702810301600011889',
    copied: false,
  },
  {
    name: 'назначение',
    value: purpose.value,
    copied: false,
  },
]);

watch(
  () => {
    return store.selectedCompany;
  },
  () => {
    const p = fields.value.find((i) => i.name === 'назначение');
    if (p) {
      p.value = purpose.value;
    }
    paymentValues.purpose = purpose.value;

    inited.value = false;
    nextTick(() => {
      inited.value = true;
    });
  },
);
</script>
<style lang="scss" scoped></style>
