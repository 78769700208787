export default class Layer {
  /**
   * @param {Boolean} [isShown=false]
   */
  constructor(isShown = false) {
    this.isShown = isShown;
  }
  toggle() {
    this.isShown = !this.isShown;
  }
  show() {
    this.isShown = true;
  }
  close() {
    this.isShown = false;
  }
}
