<template>
  <div class="new-investments-sell-btn-status-container">
    <button
      class="new-investments-sell-btn-status potok-text-uppercase"
      :class="{
        'confirmed-status-btn': props.status === 'confirmed',
        'approved-status-btn': props.status === 'approved',
        'canceled-or-rejected-status-btn':
          props.status === 'canceled' || props.status === 'rejected',
        'completed-status-btn': props.status === 'completed',
      }"
    >
      {{ text }}
    </button>

    <VDropdown placement="bottom-end">
      <i
        v-if="props.comment"
        class="icon-attention new-investments-sell-btn-attention ml-3"
        @click.stop
      />
      <template #popper>
        <div class="popup">
          <div v-close-popper class="popup-close"></div>
          <div class="popup-text">
            {{ props.comment }}
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps(['status', 'comment']);

const text = computed(() => {
  switch (props.status) {
    case 'confirmed':
      return 'активна';
    case 'approved':
      return 'обрабатывается';
    case 'canceled':
      return 'отменена';
    case 'rejected':
      return 'отклонена';
    case 'created':
      return 'создана';
    case 'completed':
      return 'реализовано';
    default:
      return 'не известный';
  }
});
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.new-investments-sell-btn-status-container {
  display: flex;
  align-items: center;
}
.new-investments-sell-btn-status {
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  border: none;
  cursor: initial;
}
.new-investments-sell-btn-attention {
  height: 16px;
  width: 16px;
}
.confirmed-status-btn {
  background-color: rgba(4, 204, 217, 0.1);
  color: $tiffany;
}
.approved-status-btn {
  background-color: rgba(47, 128, 237, 0.1);
  color: $blue;
}
.canceled-or-rejected-status-btn {
  background-color: rgba(238, 61, 72, 0.1);
  color: $red;
}
.completed-status-btn {
  background-color: rgba(255, 107, 0, 0.1);
  color: rgba(255, 107, 0, 1);
}
</style>
