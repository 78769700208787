<template>
  <section>
    <div class="row" style="margin-bottom: 13px">
      <div class="col-md-3 col-sm-3 block_item">
        <div class="block_content">
          <InvestmentsBalanceInvested
            :stat-amount="props.statAmount"
            :stat-count="props.statCount"
            :loading-company-stat="props.loadingCompanyStat"
          />
        </div>
      </div>
      <div class="col-md-3 col-sm-3 block_item">
        <div class="block_content">
          <InvestmentsBalanceIncome
            :stat-income="props.statIncome"
            :promo-income="props.promoIncome"
            :loading-company-promo-income="props.loadingCompanyPromoIncome"
          />
        </div>
      </div>
      <div
        v-if="!store.isPotokFinanceBorrower"
        class="col-md-3 col-sm-3 block_item"
      >
        <div class="block_content">
          <InvestmentsBalanceExpectedIncome />
        </div>
      </div>
      <div class="col-md-3 col-sm-3 block_item">
        <div class="block_content">
          <InvestmentsBalanceDefault />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import InvestmentsBalanceInvested from '../InvestmentsBalanceInvested.vue';
import InvestmentsBalanceIncome from '../InvestmentsBalanceIncome.vue';
import InvestmentsBalanceExpectedIncome from '../InvestmentsBalanceExpectedIncome.vue';
import InvestmentsBalanceDefault from '../InvestmentsBalanceDefault.vue';
import { useStateStore } from "@/store/stateStore";
const props = defineProps([
  'statAmount',
  'statCount',
  'statIncome',
  'promoIncome',
  'loadingCompanyStat',
  'loadingCompanyPromoIncome',
]);

const store = useStateStore()
</script>
<style lang="scss" scoped></style>
