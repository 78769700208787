<template>
  <div class="loan-params">
    <FormFieldTitle :divider="false" :title="'информация'" />

    <div class="loan-params-block">
      <FormField
        :divider="false"
        :name="'статус'"
        :name-width="6"
        :value-width="6"
      >
        <template #value>
          <div :class="props.project.statusClassName" class="project__tag">
            <span class="project__tag-icon">{{
              props.project.statusTitle
            }}</span>
          </div>
        </template>
      </FormField>
      <FormField
        v-if="isCession"
        :name="'дата цессии'"
        :value="formatDate(props.project.cessionStartAt)"
        :name-width="6"
        :value-width="6"
      />
      <FormField
        v-if="isCession && props.project.restructuringComment"
        :name="'комментарий по реструктизации'"
        :value="props.project.restructuringComment"
        :name-width="6"
        :value-width="6"
      />
    </div>

    <PotokDivider margin-y="30" mobile-margin-y="17" />

    <div
      v-if="isShowDetailTitle"
      class="project__title"
      style="cursor: pointer"
      @click="isShowDetail = !isShowDetail"
    >
      подробнее
      <i
        class="fa-angle-up fa"
        :class="{
          'fa-angle-up': isShowDetail,
          'fa-angle-down': !isShowDetail,
        }"
      ></i>
    </div>
    <div v-if="isShowDetail">
      <div class="details">
        <div class="details-block">
          <FormField
            :divider="false"
            :name="'тип займа'"
            :name-width="6"
            :value-width="6"
          >
            <template #value>
              <label-loan-type :loan-type="props.project.type" />
            </template>
          </FormField>
          <FormField
            :name="'начисление %'"
            :value="scheduleTypes[props.project.scheduleType]"
            :name-width="6"
            :value-width="6"
          />
          <FormField :name="'срок'" :name-width="6" :value-width="6">
            <template #value>
              {{
                props.project.term +
                ' ' +
                pluralize(props.project.term, ['день', 'дня', 'дней'])
              }}
            </template>
          </FormField>
          <FormField
            v-if="!isRisingFunds"
            :name="'дата выдачи'"
            :value="formatDate(props.project.fundedAt)"
            :name-width="6"
            :value-width="6"
          />
          <FormField
            v-if="isShowDateFundedAndRefunded"
            :name="'плановая дата возврата'"
            :value="formatDate(props.project.scheduledRefundDate)"
            :name-width="6"
            :value-width="6"
          />
        </div>
        <div class="details-block">
          <FormField :name="'ставка'" :name-width="6" :value-width="6">
            <template #value>
              <div style="display: flex">
                <span>{{ formatCurrency(props.project.rate * 100, 2) }}</span>
                %
                <div
                  v-if="getScheduleType() && props.project.initialIrr"
                  @click.prevent
                >
                  <VDropdown>
                    <button
                      class="info-icon-hint project__tag"
                      style="
                        padding: 0;
                        display: inline-block;
                        margin-left: 5px;
                      "
                    >
                      <span :class="getScheduleType().class"></span>
                    </button>
                    <template #popper>
                      <div class="popup">
                        <div v-close-popper class="popup-close"></div>
                        <div class="popup-text">
                          <div v-if="props.project.initialIrr">
                            эффективная ставка: {{ props.project.initialIrr }}%.
                          </div>
                        </div>
                      </div>
                    </template>
                  </VDropdown>
                </div>
              </div>
            </template>
          </FormField>
          <FormField
            v-if="props.project.paymentTypeDescription"
            :name="'частота выплат'"
            :value="props.project.paymentTypeDescription"
            :name-width="6"
            :value-width="6"
          />
          <FormField
            :name="'прочее обеспечение'"
            :name-width="6"
            :value-width="6"
          >
            <template #value>
              <span v-if="props.project.isMarketplaceCollateral">да</span>
              <span v-if="!props.project.isMarketplaceCollateral">нет</span>
            </template>
          </FormField>
          <FormField
            :name="'текущая просрочка'"
            :name-width="6"
            :value-width="6"
          >
            <template #value>
              <span v-if="props.project.overdueDays" style="color: red">{{
                props.project.overdueDays +
                ' ' +
                pluralize(props.project.overdueDays, ['день', 'дня', 'дней'])
              }}</span>
              <span v-else>отсутствует</span>
            </template>
          </FormField>
          <FormField
            v-if="props.project.paymentTypeDescription"
            :name="'ставка при просрочке'"
            :value="`${formatCurrency(props.project.overdueRate * 100, 2)} %`"
            :name-width="6"
            :value-width="6"
          />
        </div>
      </div>
    </div>

    <template
      v-if="props.project.guarantors && props.project.guarantors.length > 0"
    >
      <FormFieldTitle :title="'поручители'" />
      <div v-for="(guarantor, index) in props.project.guarantors" :key="index">
        <div class="item_name" style="color: #313132">
          {{ guarantor.fio }}
        </div>
        <div class="item_value" style="color: #7d7d7e">
          {{ guarantor.age }}
          <span v-if="guarantor.age">{{ pluralAge(guarantor.age) }}</span>
        </div>
      </div>
    </template>

    <FormFieldTitle :title="'документы'" />

    <div class="documents">
      <div
        v-for="(doc, index) in props.project.documents"
        :key="index"
        class="document"
      >
        <a
          href="#"
          class="doc-name document_link"
          :data-id="doc.id"
          @click.prevent="downloadSignedDocumentsAsync(doc)"
        >
          {{ formatDocumentTitle(doc) }}
          <i class="icon_document_pdf" />
        </a>
      </div>
      <div v-if="localIsRestructed" class="document">
        <a
          href="https://v3.potok.digital/legal/pravila_restrukturizacii_potok.pdf"
          class="doc-name document_link"
          target="_blank"
        >
          правила реструктуризации
          <i class="icon_document_pdf" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

import documentUtils from '@/composables/documentsComposable';
import constants from '@/constants';
import LabelLoanType from '@/components/_generic/tags/LabelLoanType.vue';
import FormFieldTitle from '@/components/_generic/forms/FormFieldTitle.vue';
import FormField from '@/components/_generic/forms/FormField.vue';
import {
  pluralize,
  formatCurrency,
  formatDate,
} from '@/utils/commonUtils/utils';

const props = defineProps(['project', 'status']);
const { formatDocumentTitle, downloadSignedDocumentsAsync } = documentUtils();

const scheduleTypes = constants.scheduleTypes;
const isShowDetail = ref(false);
const isShowDetailTitle = ref(false);

const localIsRestructed = computed(() => {
  return isRestructed(props.project);
});
const localIsDefault = computed(() => {
  return isDefault(props.project);
});
const isCession = computed(() => {
  if (localIsRestructed.value) {
    return true;
  }
  if (localIsDefault.value) {
    return true;
  }
  return false;
});
const isRisingFunds = computed(() => {
  if (
    props.project &&
    props.project.statusId === constants.PROJECT__STATUS__RISING_FUNDS
  ) {
    return true;
  }
  return false;
});
const isShowDateFundedAndRefunded = computed(() => {
  if (props.status === 'funded' || props.status === 'fundedFull') {
    return true;
  }
  return false;
});

onMounted(() => {
  /**
   * "Подробнее — показывать данные ниже в свернутом виде
   * для займов в статусе “дефолт”, “реструктуризация”,
   * для остальных – сразу в развернутом"
   */
  if (isDefault(props.project)) {
    isShowDetailTitle.value = true;
    isShowDetail.value = false;
  } else {
    isShowDetailTitle.value = false;
    isShowDetail.value = true;
  }
});
// methods: {
const isDefault = (item) => {
  if (
    item.statusId === constants.projectStatus.cession1st ||
    item.statusId === constants.projectStatus.cession2nd ||
    item.statusId === constants.projectStatus.cessionFixed
  ) {
    return true;
  }
};
const isRestructed = (item) => {
  if (
    item.statusId === constants.projectStatus.cession1st ||
    item.statusId === constants.projectStatus.cession2nd ||
    item.statusId === constants.projectStatus.cessionFixed
  ) {
    if (item.isRestructured) {
      return true;
    }
  }
};
const getScheduleType = () => {
  if (props.project.loanType === 'factoring') {
    return;
  }
  if (['ordinary', 'start_without_od'].includes(props.project.scheduleType)) {
    return {
      class: 'equal-icon',
      text: 'Начисление от первоначальной суммы',
    };
  } else if (
    ['annuitet', 'annuitet_delayed_od', 'annuitet_last_period_od'].includes(
      props.project.scheduleType,
    )
  ) {
    return {
      class: 'step-icon',
      text: 'Начисление от фактического остатка',
    };
  }
};
const pluralAge = (number) => {
  const titles = ['год', 'года', 'лет'];
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loan-params {
  width: 100%;
  background: $white;
  box-shadow: 0px 1px 4px #e5e9f2;
  border-radius: 6px;
  margin: 30px 0;
  padding: 20px 20px 20px 20px;

  @media (max-width: $size_767) {
    padding: 16px;
  }
}

.loan-params-block {
  @media (min-width: $size_991) {
    width: 50%;
  }
}

.details {
  display: flex;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.details-block {
  width: 50%;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.project__tag {
  padding: 0;
  @media (max-width: $size_767) {
    margin: 0;
  }
  .project__tag-icon {
    @media (max-width: $size_767) {
      margin: 0 !important;
    }
  }
}
.documents {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
}
.document {
  padding: 0;
  @media (max-width: $size_767) {
    width: 100%;
  }
  .document_link {
    padding: 20px 20px 48px 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    background: #f2f4f8;
    position: relative;
    border-radius: 8px;

    color: $deep_dive;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-transform: lowercase;
    min-width: 353px;
    @media (max-width: $size_767) {
      min-width: inherit;
      width: 100%;
    }
  }
  .icon_document_pdf {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>
