const filterItemsBuybackCurrent = (items, filters) => {
  let filteredItems = JSON.parse(JSON.stringify(items));
  for (let field in filters) {
    if (filters[field]) {
      if (field === 'search') {
        const term = filters[field].toLowerCase();
        filteredItems = filteredItems.filter((i) => {
          return (
            i.borrowerShortName.toLowerCase().includes(term) ||
            i.projectNumber.toLowerCase().includes(term)
          );
        });
      }
      if (field === 'daysOverdue') {
        filteredItems = filteredItems.filter((i) => {
          return parseInt(i.daysOverdue) == parseInt(filters[field]);
        });
      }
      if (field === 'soldBy') {
        filteredItems = filteredItems.filter((i) => {
          return parseInt(i.soldBy) == parseInt(filters[field]);
        });
      }
      if (field === 'myInvestmentDebt') {
        filteredItems = filteredItems.filter((i) => {
          return parseInt(i.myInvestmentDebt) == parseInt(filters[field]);
        });
      }
      if (field === 'myInvestmentIncome') {
        filteredItems = filteredItems.filter((i) => {
          return parseInt(i.myInvestmentIncome) == parseInt(filters[field]);
        });
      }
      if (field === 'myInvestmentExpectingIncome') {
        filteredItems = filteredItems.filter((i) => {
          return (
            parseInt(i.myInvestmentExpectingIncome) == parseInt(filters[field])
          );
        });
      }
    }
  }
  return filteredItems;
};

export default filterItemsBuybackCurrent;
