import { useStateStore } from '@/store/stateStore';

const onBoardingGuard = (to, from, next) => {
  const { isOnboardingComplete } = useStateStore().state.user;

  // Если юзер приходит с вебвью мобилки, минуя логин - пропускаем без проверки онбординга
  if (to.query.webview) {
    return next();
  }
  // Если юзер прошел онбординг, или роут доступен для онбординга, пропускаем
  if (isOnboardingComplete || to.meta?.canWhenOnBoarding) {
    return next();
  }
  // Если роут доступен всегда, пропускаем
  if (to.meta?.alwaysAvailable) {
    return next();
  }

  // Если роут недоступен для онбординга, редиректим на главную
  return next({
    name: 'main',
  });
};

export default onBoardingGuard;
