<template>
  <div class="wrapper_content">
    <div>
      <div class="wrapper_title potok-text-body-1 potok-color-deepdive">
        <div class="circle_tag" style="background: #04ccd9"></div>
        <div>POTOK</div>
      </div>
      <p class="content_text potok-text-body-2 color-deepdive">
        индекс Потока, рассчитывается помесячно по формуле: &lt;Индекс
        предыдущего месяца> * (1 + &lt;Доходность текущего месяца>). Доходность
        текущего месяца: (&lt;Процентный доход за месяц> + &lt;Возвраты из
        дефолтов> - &lt;Дефолты>) / &lt;Ср. портфель инвестиций>.
      </p>
    </div>
    <div>
      <div class="wrapper_title potok-text-body-1 potok-color-deepdive">
        <div class="circle_tag" style="background: #ee3d48"></div>
        <div>MOEX</div>
      </div>
      <p class="content_text potok-text-body-2 potok-color-deepdive">
        индекс Московской биржи, представляет собой ценовой, взвешенный по
        рыночной капитализации фондовый индекс, включающий 50 наиболее ликвидных
        акций крупнейших российских компаний.
      </p>
    </div>
    <div style="margin: 0">
      <div class="wrapper_title potok-text-body-1 potok-color-deepdive">
        <div class="circle_tag" style="background: #ffc11e"></div>
        <div>RGBITR</div>
      </div>
      <p class="content_text potok-text-body-2 potok-color-deepdive">
        индекс государственных облигаций Московской биржи, включает наиболее
        ликвидные облигации федерального займа.
      </p>
    </div>
    <div>
      <div class="wrapper_title potok-text-body-1 potok-color-deepdive">
        <div class="circle_tag" style="background: #173b57"></div>
        <div>RUEYBCSTR</div>
      </div>
      <p class="content_text potok-text-body-2 potok-color-deepdive">
        индекс облигаций повышенной доходности Московской биржи, включает в себя
        облигации из сектора повышенного инвестиционного риска.
      </p>
    </div>
    <div>
      <div class="wrapper_title potok-text-body-1 potok-color-deepdive">
        <div class="circle_tag" style="background: #818e99"></div>
        <div>JL</div>
      </div>
      <p class="content_text potok-text-body-2 potok-color-deepdive">
        индекс доходности инвесторов ближайшего конкурента Потока, значения
        взяты с официального сайта
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.wrapper_content {
  text-transform: none;
}
.wrapper_title {
  display: flex;
  align-items: center;
  text-transform: none;
  color: #111d3d;
  margin-left: -20px;
  margin-top: 25px;
  @media (max-width: $size_767) {
    margin-left: 0px;
  }
}
.circle_tag {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}
.content_text {
  color: #818e99;
  text-transform: none;
  @media (max-width: $size_767) {
    margin-left: 18px;
  }
}
</style>
