import SettingsPage from '@/pages/SettingsPage.vue';
import SettingsPerson from '@/pages/Settings/SettingsPerson.vue';
import SettingsInvestors from '@/pages/Settings/SettingsInvestors.vue';
import SettingsNotification from '@/pages/Settings/SettingsNotification.vue';
import SettingsSecurity from '@/pages/Settings/SettingsSecurity.vue';
import SettingsChangePassword from '@/pages/Settings/SettingsChangePassword.vue';

export default [
  {
    path: '/account/change-password',
    name: 'settingsChangePassword',
    components: {
      content: SettingsChangePassword,
    },
    meta: {
      canWhenOnBoarding: true,
      title: 'Изменение пароля',
    },
  },
  {
    path: '/account/settings',
    name: 'settings',
    redirect: () => {
      return {
        name: 'SettingsPerson',
      };
    },
    meta: {
      canWhenOnBoarding: true,
      title: 'Настройки',
    },
    components: {
      content: SettingsPage,
    },
    children: [
      {
        name: 'SettingsPerson',
        path: '',
        component: SettingsPerson,
      },
      {
        name: 'SettingsInvestors',
        path: 'investors',
        component: SettingsInvestors,
      },
      {
        name: 'SettingsNotification',
        path: 'notification',
        component: SettingsNotification,
      },
      {
        name: 'SettingsSecurity',
        path: 'security',
        component: SettingsSecurity,
      },
    ],
  },
];
