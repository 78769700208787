<template>
  <template
    v-if="store.isAdmin || store.selectedCompany.isPortfolioSaleAvailable"
  >
    <NewInvestmentsPage />
  </template>
  <template v-else>
    <PotokPage>
      <template v-if="store.state.user.name && store.state.user.surname">
        <template
          v-if="
            store.state.user.passportProcessingStatus ===
            constants.passportStatus.PENDING
          "
        >
          <section class="balances">
            <div class="row">
              <div class="col-md-8 col-xs-12">
                <h2 class="block-title">Проверка паспортных данных</h2>
              </div>
            </div>
            <div class="content-block balances-row" style="background: #ffecec">
              <div class="row">
                <div class="balances-item col-md-8 col-xs-8">
                  <div class="property" style="color: black">
                    В настоящий момент происходит проверка ваших паспортных
                    данных. Этот процесс занимает не более 1-2 часов (в рабочее
                    время), после чего средства начнут автоматически
                    распределяться в займы.”
                  </div>
                </div>
              </div>
            </div>
          </section>
        </template>
      </template>

      <InvestmentsContent />
    </PotokPage>
  </template>
</template>

<script setup>
import { onMounted } from 'vue';

import { PotokPage } from 'potok-uikit';
import InvestmentsContent from '../components/Investments/InvestmentsContent.vue';
import tracker from '@/tracker';
import constants from '@/constants';
import { useStateStore } from '@/store/stateStore';
import NewInvestmentsPage from '@/pages/NewInvestmentsPage';

const store = useStateStore();

onMounted(() => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_My_Investments_View');
  store.merge({
    preferences: {
      clientType: constants.clientTypes.investor,
    },
  });
});
</script>
<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.page {
  margin-bottom: 100px !important;
  @media (max-width: $size_767) {
    margin-bottom: initial;
  }
}
.investor {
  @media (max-width: $size_767) {
    padding-top: 0;
  }
}
</style>
