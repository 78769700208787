<template>
  <component
    :is="filterComponentName"
    :filters="props.filters"
    class="block_filter"
    @update-filters="updateFilters"
  ></component>
</template>
<script setup>
import { computed } from 'vue';

import InvestmentsFilterTableFunded from './InvestmentsFilterTableComponent/InvestmentsFilterTableFunded.vue';
import InvestmentsFilterTableRestructured from './InvestmentsFilterTableComponent/InvestmentsFilterTableRestructured.vue';
import InvestmentsFilterTableRefunded from './InvestmentsFilterTableComponent/InvestmentsFilterTableRefunded.vue';
import InvestmentsFilterTableOverdue from './InvestmentsFilterTableComponent/InvestmentsFilterTableOverdue.vue';
import InvestmentsFilterTableDefault from './InvestmentsFilterTableComponent/InvestmentsFilterTableDefault.vue';
import InvestmentsFilterTableSold from './InvestmentsFilterTableComponent/InvestmentsFilterTableSold.vue';
import InvestmentsFilterTableBuybackCurrent from './InvestmentsFilterTableComponent/InvestmentsFilterTableBuybackCurrent.vue';

const emit = defineEmits(['updateFilters']);
const props = defineProps(['status', 'filters']);

const filterComponentName = computed(() => {
  if (props.status === 'funded') {
    return InvestmentsFilterTableFunded;
  }
  if (props.status === 'restructured') {
    return InvestmentsFilterTableRestructured;
  }
  if (props.status === 'refunded') {
    return InvestmentsFilterTableRefunded;
  }
  if (props.status === 'softOverdue') {
    return InvestmentsFilterTableOverdue;
  }
  if (props.status === 'default') {
    return InvestmentsFilterTableDefault;
  }
  if (props.status === 'sold') {
    return InvestmentsFilterTableSold;
  }
  if (props.status === 'buyback_current') {
    return InvestmentsFilterTableBuybackCurrent;
  }
  return null;
});

const updateFilters = (obj) => {
  emit('updateFilters', obj);
};
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.block_filter {
  margin-top: 20px;
}

.block_content {
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: $size_767) {
    padding: 0;
  }
}
:deep(.clear) {
  position: absolute;
  top: 7px;
  right: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background-color: #eeeeee;
  font-size: 24px;
  line-height: 1;
  color: #717d83;
}
:deep(.filter_item) {
  @media (max-width: $size_767) {
    margin-bottom: 16px;
  }
}
</style>
