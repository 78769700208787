import { formatCurrency, pluralize } from '@/utils/commonUtils/utils';
import {
  showPlus,
  contentInvestmentDesktop,
  contentInvestmentMobile,
} from './investmentsColumnsUtils.js';

export const ColumnsTotalBuybackCurrent = (items) => {
  let myInvestmentDebt = 0;
  let soldBy = 0;
  let myInvestmentIncome = 0;
  let myInvestmentExpectingIncome = 0;
  items.forEach((i) => {
    myInvestmentDebt += i.myInvestmentDebt;
    soldBy += i.soldBy;
    myInvestmentIncome += i.myInvestmentIncome;
    myInvestmentExpectingIncome += i.myInvestmentExpectingIncome;
  });
  return {
    mobileTitle: 'Всего по Buyback — текущие',
    myInvestmentDebt: formatCurrency(myInvestmentDebt, 0) + ' ₽',
    soldBy: formatCurrency(soldBy, 0) + ' ₽',
    myInvestmentIncome: formatCurrency(myInvestmentIncome, 0) + ' ₽',
    myInvestmentExpectingIncome:
      formatCurrency(myInvestmentExpectingIncome, 0) + ' ₽',
  };
};

export const ColumnsBuybackCurrent = [
  {
    title: 'Инвестиция',
    title_mobile: ' ',
    is_mobile_full: true,
    content_desktop(item) {
      return contentInvestmentDesktop(item);
    },
    content_mobile(item) {
      return contentInvestmentMobile(item);
    },
  },
  {
    title: 'Просрочка',
    content_desktop(item) {
      return `
      ${
        item.daysOverdue +
        ' ' +
        pluralize(item.daysOverdue, ['день', 'дня', 'дней'])
      }
      `;
    },
  },
  {
    title: 'ОД на момент продажи',
    slug: 'soldBy',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-end',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.soldBy, 0)} ₽
      `;
    },
  },
  {
    title: 'Остаток ОД',
    slug: 'balanceOfMainDebt',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.myInvestmentDebt, 0)} ₽
      `;
    },
  },
  {
    title: 'Факт. доход',
    slug: 'factIncome',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.myInvestmentIncome, 0)} ₽
      `;
    },
  },
  {
    title: 'Прогноз дохода',
    slug: 'expectingIncome',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      <div ${
        showPlus(item.myInvestmentExpectingIncome)
          ? ' style="color: #04CCD9;"'
          : ''
      }">
      ${showPlus(item.myInvestmentExpectingIncome)}
      ${formatCurrency(item.myInvestmentExpectingIncome, 0)}
      ₽
      </div>
      `;
    },
  },
];
