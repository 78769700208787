<template>
  <div class="steps-wrapper">
    <div class="step step-done potok-text-uppercase">
      1. список инвестиции для продажи
    </div>
    <div
      class="step potok-text-uppercase"
      :class="isColoredSecondStep ? 'step-done' : ''"
    >
      2. подтверждение операции
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  currentStepId: {
    type: Number,
    default: 1,
  },
});

const isColoredSecondStep = computed(() => {
  return props.currentStepId >= 2;
});
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.steps-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  .step {
    width: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid $input;
    color: $grey;
    &-done {
      color: $deep_dive;
      border-bottom: 1px solid $deep_dive;
    }
  }
}
</style>
