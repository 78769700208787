<template>
  <div>
    <template v-if="props.accountList.length">
      <div class="header_scroll_menu">
        <div
          v-for="(item, index) in props.accountList"
          :key="item.id"
          class="header__menulist__item_wrapper"
          @click="onClickChooseCompany(item)"
          @touchstart.stop
          @touchmove.stop
          @touchcancel.stop
        >
          <div class="header__menulist__item hover_header_item">
            <div
              :class="{
                account_list_item_selected: selectedCompany.id === item.id,
              }"
            >
              <div class="account_list_item_lk">
                ЛС {{ item.investorAccountNumber }}
              </div>
              <div class="account_list_item_name">
                {{ getShortAccountName(item) }}
              </div>
              <div class="account_list_item_balance">
                {{ formatCurrency(item.totalBalance, 0) }} ₽
              </div>
            </div>
          </div>

          <PotokDivider v-if="index < props.accountList.length - 1" />
        </div>
      </div>
    </template>

    <PotokDivider v-if="isUserRegistrated" />

    <div
      v-if="isUserRegistrated"
      class="header__menulist__item hover_header_item"
      style="padding: 10px; margin-top: 10px"
    >
      <a
        href="#"
        class="link_btn"
        style="color: #10273d"
        @click.prevent="onClickAddAccount()"
        @touchstart="onClickAddAccount()"
      >
        <i class="icon_circle_plus" style="margin-right: 9px"></i>
        добавить лицевой счет
      </a>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';

import { formatCurrency } from '@/utils/commonUtils/utils';
import headerComposable from '@/composables/headerComposable';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});

const { getShortAccountName } = headerComposable();

const props = defineProps(['accountList']);
const emit = defineEmits(['chooseCompanyById', 'addAccount']);

const onClickChooseCompany = (item) => {
  emit('chooseCompanyById', item);
};
const onClickAddAccount = () => {
  emit('addAccount', {
    callback: () => {},
  });
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.account_list_item_selected {
  .account_list_item_lk {
    color: #04ccd9 !important;
  }
  .account_list_item_name {
    color: #04ccd9 !important;
  }
  .account_list_item_balance {
    color: #04ccd9 !important;
  }
}

.account_list_item_lk {
  padding: 0;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
}
.account_list_item_name {
  padding: 4px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
}
.account_list_item_balance {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  margin-top: 7px;
}
</style>
