<template>
  <div class="new-request-step-2-hints block-container">
    <div class="new-request-step-2-hints-chip potok-text-uppercase">
      ПОДСКАЗКИ
    </div>

    <NewInvestmentsNewRequestStep2HintsContent :hints="props.hints" />
  </div>
</template>

<script setup>
import NewInvestmentsNewRequestStep2HintsContent from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep2/NewInvestmentsNewRequestStep2HintsContent.vue';

const props = defineProps({
  hints: {
    type: [Object],
    required: false,
  },
});
</script>

<style scoped lang="scss">
@use '../../../../scss/variables.scss' as *;
@use '../../../../scss/typography.scss' as *;

.new-request-step-2-hints {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  background: $dividers;

  @media (max-width: $size_991) {
    padding: 0;
    background: none;
  }
}

.new-request-step-2-hints-chip {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 8px 3px 8px;
  border-radius: 6px;
  background: $background;
}
</style>
