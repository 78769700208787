<template>
  <ModalFull
    title="бонус за первое пополнение"
    class="mt-2"
    @close="emits('close')"
  >
    <template #content>
      <div class="bonus-modal-container">
        <Loader v-if="isLoading" />
        <div v-else class="bonus-modal-content">
          <div class="bonus-modal-description potok-text-body-1 mb-7">
            {{ bonusProgramText.conditions.descriptionPart1 }}
          </div>
          <div class="bonus-modal-list-container mt-7">
            <div class="bonus-modal-list-header potok-text-uppercase">
              <div class="bonus-modal-list-size">размер пополнения</div>
              <div class="bonus-modal-list-profit">ставка начисления</div>
            </div>
            <div class="bonus-modal-list potok-text-body-1">
              <div
                v-for="item in bonusProgramList"
                :key="'profit' + item.profit"
                class="bonus-modal-list-item py-4"
              >
                <div class="bonus-modal-list-size">
                  {{ itemSizeText(item.size) }}
                </div>
                <div class="bonus-modal-list-profit">
                  {{ `${item.profit}% годовых` }}
                </div>
              </div>
            </div>
          </div>
          <div class="bonus-modal-description-bottom potok-text-body-1 mt-5">
            {{ bonusProgramText.conditions.descriptionPart2 }}
          </div>
          <div class="bonus-modal-more-info potok-text-body-1">
            <div
              class="bonus-modal-more-info-btn mt-7"
              @click="isShowDetail = !isShowDetail"
            >
              подробные условия
              <i
                class="fa-angle-up fa"
                :class="{
                  'fa-angle-up': isShowDetail,
                  'fa-angle-down': !isShowDetail,
                }"
              />
            </div>
            <div
              v-if="isShowDetail"
              class="bonus-modal-more-info-content mt-5"
              v-html="bonusProgramText.extendedConditions.description"
            ></div>
          </div>
        </div>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { ref } from 'vue';
import server from '@/server';
import { Loader } from 'potok-uikit';

import { formatCurrency } from '@/utils/commonUtils/libUtils';

const emits = defineEmits(['close']);

const bonusProgramList = ref([
  {
    size: 20000,
    profit: 6,
  },
  {
    size: 100000,
    profit: 8,
  },
  {
    size: 300000,
    profit: 10,
  },
  {
    size: 1000000,
    profit: 12,
  },
  {
    size: 5000000,
    profit: 14,
  },
]);

const bonusProgramText = ref({
  conditions: {
    descriptionPart1: '',
    descriptionPart2: '',
  },
  extendedConditions: {
    description: '',
  },
});
const isLoading = ref(true);
const isShowDetail = ref(false);

const itemSizeText = (size) => {
  return `от ${formatCurrency(size, 0)} ₽`;
};

const getBonusProgramAsync = () => {
  isLoading.value = true;
  return server.getFeaturesStatusBonus
    .send()
    .pipe(onGetBonusProgramAsyncSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};

const onGetBonusProgramAsyncSuccess = ({ data }) => {
  bonusProgramText.value = data.text;
  isLoading.value = false;
};

getBonusProgramAsync();
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;
@use '../../../scss/typography.scss' as *;

:deep(.modal_block_container) {
  max-width: 736px;
}

.bonus-modal-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}

.bonus-modal-content {
  width: 100%;
}

.bonus-modal-description {
  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}
.bonus-modal-current {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  background-color: $dividers;
  padding: 20px;
  border-radius: 8px;
}

.bonus-modal-list-size {
  min-width: 120px;
  @media (max-width: $size_767) {
    margin-left: 16px;
  }
}

.bonus-modal-list-profit {
  min-width: 100px;
  margin-left: 195px;
  @media (max-width: $size_767) {
    margin-left: 50px;
  }
}

.bonus-modal-current-left-title {
  color: $tiffany;
  @media (max-width: $size_767) {
    @include potok-text-h2;
  }
}

.bonus-modal-list-header {
  display: flex;
  color: $grey;
}
.bonus-modal-list-item {
  display: flex;
  border-bottom: 1px solid $dividers;
  &:last-child {
    border-bottom: 0;
  }
  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}
.bonus-modal-description-bottom {
  color: $grey;
  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}
.bonus-modal-more-info {
  color: $grey;
  @media (max-width: $size_767) {
    @include potok-text-body-2;
  }
}
:deep(.bonus-modal-more-info-content) {
  ol {
    padding-left: 20px;
    margin: 0;
  }
  ul {
    padding-left: 30px;
    list-style: disc;
  }
}
.bonus-modal-more-info-btn {
  color: $tiffany;
  cursor: pointer;
}

.color-basic {
  color: $grey;
}
.color-silver {
  color: $breakwater;
}
.color-gold {
  color: $yellow;
}
.color-platinum {
  color: $tiffany;
}
.color-diamond {
  color: $red;
}
</style>
