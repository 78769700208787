<template>
  <div class="settings-desktop">
    <h1 class="settings-title potok-text-h1">
      {{ title }}
    </h1>
    <div class="settings-container">
      <div class="settings-content">
        <router-view />
      </div>
      <div class="settings-right-container ml-7">
        <div class="settings-menu-container">
          <SettingsMenu :items="items" @change="changeTitle" />
        </div>
        <SettingsTelegramChannels
          v-if="route.name === 'SettingsNotification'"
          class="mt-7"
        />
        <SettingsInvestorTip
          v-if="route.name === 'SettingsInvestors'"
          class="mt-7"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import SettingsMenu from '@/components/Settings/SettingsMenu.vue';
import { ref } from 'vue';
import { PAGE_TITLES } from '@/constants/Settings/settingsConstants';
import { useRoute } from 'vue-router';
import SettingsTelegramChannels from '@/components/Settings/SettingsNotification/SettingsTelegramChanels.vue';
import SettingsInvestorTip from '@/components/Settings/SettingsInvestors/SettingsInvestorTip.vue';

const route = useRoute();

const title = ref(PAGE_TITLES.PERSONAL);

const items = ref([
  {
    title: PAGE_TITLES.PERSONAL,
    routeName: 'SettingsPerson',
    icon: 'personal',
  },
  {
    title: PAGE_TITLES.INVESTORS,
    routeName: 'SettingsInvestors',
    icon: 'investors',
  },
  {
    title: PAGE_TITLES.NOTIFICATIONS,
    routeName: 'SettingsNotification',
    icon: 'notification',
  },
  {
    title: PAGE_TITLES.SECURITY,
    routeName: 'SettingsSecurity',
    icon: 'security',
  },
]);

const changeTitle = (item) => {
  title.value = item.title;
};

const init = () => {
  const currentPage = items.value.find((el) => el.routeName === route.name);
  title.value = currentPage.title;
};
init();
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.settings-container {
  display: flex;
}
.settings-content {
  width: 70%;
}
.settings-menu-container {
  width: 100%;
}
.settings-right-container {
  width: 30%;
}
.settings-title {
  color: $deep_dive;
  margin: 0 0 30px 0;
}
:deep(.settings_row) {
  display: flex;
  align-items: center;
}
</style>
