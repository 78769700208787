export default {
  getDocument: {
    url: `/api/documents/:id`,
    method: 'GET',
  },
  getDocumentBase64: {
    url: `/api/documents/:id/base64`,
    method: 'GET',
  },
  getDocumentSignSms: {
    url: `/api/documents/:id/sign/sms`,
    method: 'GET',
  },
  getDocumentSignEds: {
    url: `/api/documents/:id/sign/eds`,
    method: 'GET',
  },
  putDocumentEds: {
    url: `/api/documents/:id/eds`,
    method: 'PUT',
    body: {
      companyId: null,
      eds: null,
    },
  },
};
