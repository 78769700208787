<template>
  <PotokPage>
    <div class="loan-page">
      <div v-if="loadingProjectId" class="block-container">
        <Loader class="loader" />
      </div>

      <div v-if="!loadingProjectId && !data.project" class="block-container">
        <p>не удалось получить данные по займу</p>
      </div>

      <div v-if="!loadingProjectId && data.project">
        <div class="header">
          <div class="title potok-text-h1">
            заем
            <span class="subtitle">{{ data.project.number }}</span>
          </div>

          <LoanTags :project="data.project" />
        </div>

        <LoanSummary
          :project="data.project"
          :status="getStatus"
          @deposit-money="
            depositMoney({
              depositOperationId: constants.depositOperations.investment,
              projectId: data.project.id,
              trackerType: data.trackerTypeDependsOnTab[tabComponentName],
            })
          "
        />

        <LoanBanner2kk v-if="isPotokHoldingProject" class="mb-7" />

        <LoanYourInvestments
          v-if="data.project.investments && data.project.investments.length > 0"
          :project="data.project"
        />

        <PotokTabs
          v-if="!isMobile && data.project"
          :items="filteredTabItems"
          @change-tab="changeTab"
        />

        <LoanTabsMobile
          v-if="isMobile && data.project"
          :current-component="tabComponentName"
          :status-id="data.project.statusId"
          @change-tab="changeTab($event, true)"
        />

        <div>
          <component
            :is="selectedTabComponent"
            :project="data.project"
            :status="getStatus"
          >
          </component>
        </div>
      </div>
    </div>
  </PotokPage>
</template>
<script setup>
import { ref, reactive, watch, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import tracker from '@/tracker';
import server from '@/server';
import storage from '@/utils/localStorage/storage';
import { useStateStore } from '@/store/stateStore';
import constants from '@/constants';

import detectDevice from '@/composables/detectDeviceComposable';
import { getProjectStatus } from '@/utils/commonUtils/utils';

import { Loader, PotokTabs, PotokPage } from 'potok-uikit';

import LoanTags from '../components/Loan/LoanTags.vue';
import LoanSummary from '@/components/Loan/LoanSummary.vue';
import LoanParams from './Loan/LoanParams.vue';
import LoanAboutBorrower from './Loan/LoanAboutBorrower.vue';
import LoanHistory from './Loan/LoanHistory.vue';
import LoanOverdue from './Loan/LoanOverdue.vue';
import LoanTabsMobile from '../components/Loan/LoanTabs/LoanTabsMobile.vue';
import LoanBanner2kk from '@/components/Loan/banners/LoanBanner2kk.vue';
import LoanYourInvestments from '@/components/Loan/LoanYourInvestments.vue';
import getLoanStatus from '@/composables/loansStatuses';

const { isMobile } = detectDevice();
const store = useStateStore();
const router = useRouter();
const route = useRoute();

const loadingProjectId = ref(false);

const tabComponentName = ref('LoanParams');
const tabItems = ref([
  {
    tabComponentName: 'LoanParams',
    text: 'параметры займа',
    isActive: true,
  },
  {
    tabComponentName: 'LoanOverdue',
    text: 'события цессии',
    isActive: false,
  },
  {
    tabComponentName: 'LoanAboutBorrower',
    text: 'о заемщике',
    isActive: false,
  },
  {
    tabComponentName: 'LoanHistory',
    text: 'история в Потоке',
    isActive: false,
  },
]);

const filteredTabItems = computed(() => {
  return tabItems.value.filter((el) => {
    if (el.tabComponentName !== 'LoanOverdue') {
      return true;
    } else {
      if (hasStatus.value) {
        return true;
      }
    }

    return false;
  });
});

const hasStatus = computed(() => {
  const ids = [
    constants.projectStatus.cession1st,
    constants.projectStatus.cession2nd,
  ];
  return ids.indexOf(data.project.statusId) !== -1;
});

const changeTab = (item, isTabsMobile = false) => {
  if (isTabsMobile) {
    sendBorrowerTrackerInfo(item);
    tabComponentName.value = item;
    return;
  }

  const itemIndex = tabItems.value.findIndex(
    (el) => el.tabComponentName === item.tabComponentName,
  );
  tabItems.value.forEach((el) => {
    el.isActive = false;
  });

  sendBorrowerTrackerInfo(item.tabComponentName);

  tabItems.value[itemIndex].isActive = true;
  tabComponentName.value = item.tabComponentName;
};

const sendBorrowerTrackerInfo = (item) => {
  if (item === 'BorrowerData') {
    tracker.queue(
      tracker.commands.SEND,
      'Investor_LK_My_Account_Borrower_Info',
    );
  }
};

const data = reactive({
  trackerTypeDependsOnTab: {
    LoanParams: 'investFromLoanDescription',
    AboutBorrower: 'investFromAboutBorrower',
    Overdue: 'investFromOverdue',
    History: 'investFromHistory',
  },
  project: {},
});

const tabComponents = {
  LoanParams,
  LoanAboutBorrower,
  LoanHistory,
  LoanOverdue,
};

const selectedTabComponent = computed(() => {
  return tabComponents[tabComponentName.value];
});

const getStatus = computed(() => {
  const params = {
    statusId: data.project.statusId,
    amount: data.project.amount,
    collected: data.project.collected,
    refunded: data.project.refunded,
  };
  return getLoanStatus(params);
});

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

const isPotokHoldingProject = computed(() => {
  return data.project.isPotokHoldingBorrower;
});

watch(
  () => {
    return route.params.id;
  },
  () => {
    fetchProjectAsync();
  },
);

watch(selectedCompanyId, () => {
  fetchProjectAsync();
});

onMounted(() => {
  fetchProjectAsync();
});

const makeProjectViewed = () => {
  /**
   * Сохраняем в хранилеще состояние просмотренности займа
   */
  if (!data.project || !data.project.id) {
    return;
  }
  let items = window.localStorage.getItem('projects');
  if (items) {
    items = JSON.parse(items);
  } else {
    items = {};
  }
  items[data.project.id] = true;
  storage.setProjects(items);
};

const fetchProjectAsync = () => {
  if (route.name !== 'loan') {
    return;
  }
  if (!route.params.id || !selectedCompanyId.value || loadingProjectId.value) {
    return;
  }
  loadingProjectId.value = true;
  const query = {
    companyId: selectedCompanyId.value,
  };
  const params = {
    id: route.params.id,
  };
  return server.getProjectIdNew
    .send(query, { params })
    .pipe(onFetchProjectAsyncSuccess, () => {
      loadingProjectId.value = false;
    })
    .exec();
};

const onFetchProjectAsyncSuccess = (obj) => {
  loadingProjectId.value = false;

  if (!obj || !obj.data) {
    return;
  }
  const result = obj.data;
  data.project = {
    ...result,
    ...getProjectStatus(result),
  };
  data.project.investments.forEach((i) => {
    i.isRestructured = data.project.isRestructured;
  });
  store.merge({
    buffer: {
      project: {
        borrower: {
          id: result.borrower.id,
        },
      },
    },
  });
  makeProjectViewed();
};

const depositMoney = ({ depositOperationId, projectId, trackerType }) => {
  tracker.queue(tracker.commands.SEND, trackerType);
  store.merge({
    buffer: {
      depositOperationId,
      investorCompanyId: selectedCompanyId.value,
      project: {
        id: projectId,
      },
    },
  });
  router.push({
    name: 'loanInvestment',
    params: {
      id: projectId,
    },
  });
};
</script>

<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.loan-page {
  position: relative;
  margin-bottom: 40px;
  padding: 0px;
  text-transform: none;
  @media (max-width: $size_767) {
    padding: 0;
    margin: 14px 0 20px 0;
  }

  .loader {
    margin-bottom: 20px;
  }

  .title {
    @media (max-width: $size_767) {
      font-size: 20px;
    }
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .subtitle {
    color: $grey-second;
    margin-right: 10px;
  }

  .banner {
    margin-bottom: 28px;
  }
}
</style>
