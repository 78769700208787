import { ref, computed } from 'vue';

export default function detectDevice() {
  const windowWidth = ref(null);
  const windowHeight = ref(null);

  const isMobile = computed(() => {
    return !!(windowWidth.value && windowWidth.value <= 767);
  });

  const isTablet = computed(() => {
    return !!(windowWidth.value && windowWidth.value <= 991);
  });

  const changeDimension = () => {
    windowWidth.value = document.documentElement.clientWidth;
    windowHeight.value = document.documentElement.clientHeight;
  };
  window.addEventListener('resize', changeDimension);
  changeDimension();

  return { isMobile, isTablet };
}
