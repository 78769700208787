<template>
  <div
    :style="{
      top: props.positions.top ? props.positions.top + 'px' : false,
      right: props.positions.right ? props.positions.right + 'px' : false,
      bottom: props.positions.bottom ? props.positions.bottom + 'px' : false,
      left: props.positions.left ? props.positions.left + 'px' : false,
      width: props.width ? props.width + 'px' : false,
    }"
    class="block-container potok-dialog"
  >
    <div class="potok-dialog-title-container" @click.stop="clickAway">
      <PotokButton
        v-if="isMobile"
        text="назад"
        theme="chip"
        size="chip"
        class="mb-7"
        @click.stop="close"
      >
        <template #icon>
          <PotokImg
            :src="imageConstants.arrowBack"
            width="15"
            height="15"
            class="mr-1"
          />
        </template>
      </PotokButton>

      <h2 v-if="props.title" class="potok-text-h2 potok-dialog-title-text">
        {{ props.title }}
      </h2>
    </div>

    <slot name="content" />

    <div v-if="$slots.actions" class="potok-dialog-actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup>
import detectDevice from '@/composables/detectDeviceComposable';
import imageConstants from '@/constants/imageConstants';

const { isMobile } = detectDevice();

const emits = defineEmits(['close', 'clickAway']);
const props = defineProps({
  positions: {
    type: Object,
    default: () => {
      return {
        top: 0,
        right: 50,
        bottom: 50,
        left: 0,
      };
    },
  },
  width: {
    type: [String, Number, Boolean],
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
});

const clickAway = () => {
  if (isMobile) {
    emits('clickAway');
  }
};

const close = () => {
  emits('close');
};
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.potok-dialog {
  position: fixed;
  padding: 30px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: none;
  box-shadow: 0 16px 64px 0 #b7cbe480;

  @media (max-width: $size_767) {
    z-index: 20;
    padding: 16px;
    height: 100%;
    width: 100% !important;
    min-width: 200px;
    max-width: initial;
    max-height: initial;
    top: 0 !important;
    right: 0 !important;
    border-radius: initial;
  }
}

.potok-dialog-title-text {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
