import constants from '@/constants';

const projectIsRising = [
  'checking',
  'needPassport',
  'declined',
  'approved',
  'canceledBeforeFundsStart',
  'canceled',
  'risingFunds',
  'risingTimeout',
];

const projectIsRefunded = [
  'funded',
  'overdue',
  'refunded',
  'cession1st',
  'cession2nd',
  'cessionFixed',
  'approvedWaitOption',
  'issuing',
];

export default function getLoanStatus({
  statusId,
  amount,
  collected,
  refunded,
}) {
  if (projectIsRising.includes(constants.projectStatusSlug[statusId])) {
    if (amount !== collected) {
      return 'risingFunds';
    }
    if (amount === collected) {
      return 'risingFundsFull';
    }
  }
  if (projectIsRefunded.includes(constants.projectStatusSlug[statusId])) {
    if (amount !== refunded) {
      return 'funded';
    }
    if (amount === refunded) {
      return 'fundedFull';
    }
  }
  return 'funded';
}
