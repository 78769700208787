<template>
  <div>
    <div
      :class="{ header__menulist__show: props.isShowCompanyList }"
      class="header__menulist_companies_button"
      @click.stop.prevent="onClickToggle()"
    >
      <span class="header__menulist_text header__menulist_second_text">{{
        companySelectedShortName
      }}</span>
    </div>
    <div v-if="props.isShowCompanyList" class="companies_list">
      <CompaniesList
        :companies-root="companiesRoot"
        @choose-company-by-id="onClickChooseCompany"
        @add-investor="onClickAddInvestor"
      />

      <div class="header__menulist__item hover_header_item" style="padding: 0">
        <PotokDivider style="padding: 0" />
      </div>
      <div
        class="header__menulist__item hover_header_item"
        style="padding: 10px 0; margin-top: 0; margin-top: 5px"
      >
        <div
          class="link_btn"
          style="
            width: 100%;
            padding: 0 10px;
            display: flex;
            align-items: center;
          "
          @click.prevent="onClickSettings()"
          @touchstart="onClickSettings()"
        >
          <i class="icon_settings_outline" style="margin-right: 9px"></i>
          настройки
        </div>
      </div>
      <div class="header__menulist__item hover_header_item">
        <div
          class="link_btn"
          style="
            color: #ff5754;
            width: 100%;
            display: flex;
            align-items: center;
          "
          @click.prevent="onClickLogout()"
          @touchstart="onClickLogout()"
        >
          <i class="icon_logout_red" style="margin-right: 12px"></i>
          выйти
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import headerComposable from '@/composables/headerComposable';
import { useStateStore } from '@/store/stateStore';
import CompaniesList from './CompaniesList.vue';

const { companySelectedShortName } = headerComposable();

const store = useStateStore();

const props = defineProps(['isShowCompanyList']);
const emit = defineEmits([
  'changeShowCompanyList',
  'chooseCompanyById',
  'addInvestor',
  'clickSettings',
  'clickLogout',
]);

const companiesRoot = computed(() => {
  return store.companiesRoot;
});

const onClickChooseCompany = (data) => {
  emit('chooseCompanyById', data);
};

const onClickToggle = () => {
  emit('changeShowCompanyList', !props.isShowCompanyList);
};

const onClickAddInvestor = () => {
  emit('addInvestor');
};

const onClickSettings = () => {
  emit('clickSettings');
};

const onClickLogout = () => {
  emit('clickLogout');
};
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.header__menulist_companies_button {
  position: relative;
  color: $deep_dive;
  text-decoration: none;
  padding: 6px 12px 9px 12px;
  background: $background;
  border-radius: 28px !important;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: rgba(242, 244, 248, 0.6);
  }
  .desktop-icon {
    margin-left: 5px;
  }
}
</style>
