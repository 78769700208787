import { assert, noOp, nextTick, isString } from '../utils';

const RUN = Symbol('RUN');

class AbstractTracker {
  /**
   * @see https://yandex.ru/support/metrika/code/counter-initialize.html
   * @param {Object} [options]
   * @param {Boolean} [isProduction]
   */
  constructor(options = {}, isProduction = false) {
    this.commands = [];
    this.options = options;

    // We should prevent executing commands until tracker will be ready
    this[RUN] = this.run;
    this.run = noOp;

    if (isProduction) {
      this.initialize(this.options);
    }
  }

  /**
   * @param {Object} options
   * @private
   */
  initialize() {
    throw new Error('You have to implement this one');
  }

  /**
   * Queues a command
   * @param {String} command
   * @param {...*} [opts]
   */
  queue(command, ...opts) {
    assert(isString(command), 'The command must be a string');
    this.commands.push([command, ...opts]);
    nextTick(() => this.run());
  }

  /**
   * Executes a command
   * @param {String} command
   * @param {...*} [opts]
   */
  execCommand() {
    throw new Error('You have to implement this one');
  }

  /**
   * Runs the command queue
   */
  run() {
    const queue = this.commands;

    for (let command = queue.shift(); command; command = queue.shift()) {
      try {
        this.execCommand(command);
      } catch (err) {
        console.error(err);
      }
    }
  }
}

AbstractTracker.RUN = RUN;

export default AbstractTracker;
