const DOTS = '...';

const range = (start, end) => {
  let length = end - start + 1;
  /*
    Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

let CreatePageRange = (options) => {
  const SIBLING_COUNT = options.SIBLING_COUNT || 2;
  const totalPageCount = options.totalPageCount;
  const currentPage = options.currentPage;

  if (totalPageCount == 1) {
    return [];
  }
  const totalPageNumbers = SIBLING_COUNT + 5;
  /*
  Case 1:
    If the number of pages is less than the page numbers we want to show in our
    paginationComponent, we return the range [1..totalPageCount]
  */

  if (totalPageNumbers >= totalPageCount) {
    let x = range(1, totalPageCount);
    let buttons = [];
    for (let i in x) {
      buttons.push({
        title: x[i],
        action: x[i],
      });
    }
    return buttons;
  }

  /*
    Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
  */
  const leftSiblingIndex = Math.max(currentPage - SIBLING_COUNT, 1);
  const rightSiblingIndex = Math.min(
    currentPage + SIBLING_COUNT,
    totalPageCount,
  );
  /*
  We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
  */
  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;
  /*
    Case 2: No left dots to show, but rights dots to be shown
  */
  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 3 + 2 * SIBLING_COUNT;
    if (totalPageCount - leftItemCount === 1) {
      leftItemCount -= 1;
    }
    let leftRange = [];
    let x = range(1, leftItemCount);
    for (let i in x) {
      leftRange.push({
        title: x[i],
        action: x[i],
      });
    }

    return [
      ...leftRange,
      {
        title: DOTS,
        action: 'nextPageBlock',
      },
      {
        title: totalPageCount,
        action: totalPageCount,
      },
    ];
  }
  /*
    Case 3: No right dots to show, but left dots to be shown
  */
  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 3 + 2 * SIBLING_COUNT;
    if (totalPageCount - rightItemCount === 1) {
      rightItemCount -= 1;
    }
    let x = range(totalPageCount - rightItemCount + 1, totalPageCount);
    let rightRange = [];
    for (let i in x) {
      rightRange.push({
        title: x[i],
        action: x[i],
      });
    }

    return [
      {
        title: firstPageIndex,
        action: firstPageIndex,
      },
      {
        title: DOTS,
        action: 'prevPageBlock',
      },
      ...rightRange,
    ];
  }
  /*
    Case 4: Both left and right dots to be shown
  */
  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = [];
    const x = range(leftSiblingIndex, rightSiblingIndex);
    for (let i in x) {
      middleRange.push({
        title: x[i],
        action: x[i],
      });
    }
    return [
      {
        title: firstPageIndex,
        action: firstPageIndex,
      },
      {
        title: DOTS,
        action: 'prevPageBlock',
      },
      ...middleRange,
      {
        title: DOTS,
        action: 'nextPageBlock',
      },
      {
        title: lastPageIndex,
        action: lastPageIndex,
      },
    ];
  }
  if (!shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 3 + 2 * SIBLING_COUNT;
    let firstValue = totalPageCount - rightItemCount + 1;
    let lastValue = totalPageCount;
    if (totalPageCount - rightItemCount === 1) {
      lastValue = totalPageCount - 1;
    }
    let x = range(firstValue, lastValue);
    let rightRange = [];
    for (let i in x) {
      rightRange.push({
        title: x[i],
        action: x[i],
      });
    }

    return [
      {
        title: firstPageIndex,
        action: firstPageIndex,
      },
      ...rightRange,
      {
        title: lastPageIndex,
        action: lastPageIndex,
      },
    ];
  }
  return [];
};

export default CreatePageRange;
