<template>
  <div class="input_element">
    <div class="input_element__wrapper">
      <div v-if="inputedValue" class="input_element__title">
        {{ props.title }}
      </div>
      <input
        v-model="inputedValue"
        v-maska="props.vmaska"
        class="form-control"
        :class="[
          'input_element__input',
          { error: showError },
          { disabled: props.disabled },
        ]"
        :disabled="props.disabled"
        :placeholder="props.title"
        :style="props.style"
        type="number"
        @input="changeValue"
      />
    </div>
    <div v-if="showError" class="input_element__error">
      {{ inputErrorMessage }}
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  vmaska: [Object, String],
  validation: {
    type: Object,
    default: () => {},
  },
  placeholder: {
    type: String,
  },
  type: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
  },
  style: {
    type: Object,
  },
});
const emit = defineEmits(['update:modelValue']);

const errorsNames = reactive({
  minValue: 'Должно быть больше',
  minValue400: 'Должно быть больше 400₽',
  minValue1000: 'Должно быть больше 1000₽',
  between: 'Не входит в диапазон',
  currency: 'Неверное количество символов после запятой',
  maxValue: 'Должно быть меньше',
  exactLength: 'Неверное количество символов',
  dividedBy: 'Число должно быть кратно 1000₽',
  required: 'Обязательное поле',
});
const inputedValue = ref(null);

const inputErrorMessage = computed(() => {
  let errorMessage = null;
  Object.keys(errorsNames).forEach((key) => {
    if (props.validation[key] && props.validation[key].$invalid) {
      errorMessage = errorsNames[key];
    }
  });

  return errorMessage;
});

const showError = computed(() => {
  return props.validation && props.validation.$invalid && !props.disabled;
});

onMounted(() => {
  inputedValue.value = props.modelValue;
});
const changeValue = (evt) => {
  evt.preventDefault();
  if (props.disabled) return;
  emit('update:modelValue', inputedValue.value);
};
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.input_element__wrapper {
  position: relative;
  width: 100%;
  max-width: 300px;
}
.input_element__title {
  position: absolute;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
  z-index: 3;
  top: -6px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
}
.input_element__input {
  width: 100%;
  box-sizing: border-box;
  flex: auto;
  position: relative;
  z-index: 2;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  &::placeholder {
    color: #999999;
    font-size: 14px;
    line-height: 24px;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
.input_element__error {
  width: 300px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: #ff5754;
}
.error {
  border: 1px solid #ff5754;
}
</style>
