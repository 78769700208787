import { isNull, parseAsJson } from './utils';

export default class IncomingError {
  /**
   * @param {Object} options
   */
  constructor(options) {
    this.message = '';
    this.status = 600;
    this.url = '';
    this.response = null;
    Object.assign(this, options);
  }
  /**
   * @returns {Error}
   */
  toError() {
    let error = new Error(this.message);
    Object.assign(error, this);
    error.data = this;
    return error;
  }
  /**
   * @param {Error|Response} errorOrResponse
   * @returns {Promise.<IncomingError>}
   */
  static factoryAsync(errorOrResponse) {
    if (errorOrResponse instanceof Error) {
      return IncomingError.fromErrorAsync(errorOrResponse);
    }
    if (window.Response && errorOrResponse instanceof window.Response) {
      return IncomingError.fromFetchResponseAsync(errorOrResponse);
    }
    throw new TypeError('Unsupported type.');
  }
  /**
   * @param {Error} err
   * @returns {Promise.<IncomingError>}
   */
  static fromErrorAsync(err) {
    const options = Object.assign({ message: err.message }, err);
    return Promise.resolve(new IncomingError(options));
  }
  /**
   * @param {Response} response
   * @returns {Promise.<IncomingError>}
   */
  static fromFetchResponseAsync(response) {
    const promise = Promise.resolve(response.text());
    return promise.then((text) => {
      const json = parseAsJson(text);
      const errorAsJson = isNull(json)
        ? { message: text }
        : json['error'] || json;
      const options = Object.assign(errorAsJson, {
        status: response.status,
        url: response.url,
        response,
      });
      return new IncomingError(options);
    });
  }
}
