<template>
  <div class="row investor_pulse">
    <div>
      <div class="col-md-4 col-lg-4 dashboard_block" style="height: 257px">
        <DashboardPulseBlock1 :project-stats="props.projectStats" />
      </div>
      <div class="col-md-4 col-lg-4 dashboard_block" style="height: 257px">
        <DashboardPulseBlock2 :project-stats="props.projectStats" />
      </div>
      <div class="col-md-4 col-lg-4 dashboard_block" style="height: 257px">
        <DashboardPulseBlock3 :future-stats="props.futureStats" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { Chart, registerables } from 'chart.js';

import DashboardPulseBlock1 from '../DashboardPulseBlock1.vue';
import DashboardPulseBlock2 from '../DashboardPulseBlock2.vue';
import DashboardPulseBlock3 from '../DashboardPulseBlock3.vue';

Chart.register(...registerables);

const props = defineProps(['projectStats', 'futureStats']);
</script>
<style lang="scss" scoped></style>
