export default {
  getPages: {
    url: `/teleport/pages`,
    method: 'GET',
  },
  getServerStatuses: {
    url: `/teleport/statuses`,
    method: 'GET',
  },
  postSelectStatus: {
    url: `/teleport/status`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
};
