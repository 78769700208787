<template>
  <div
    class="profitable-wrapper block-container1"
    :class="{
      'block-container': !isMobile,
    }"
  >
    <div class="profitable-head mb-5">
      <h2 class="potok-text-body-1-bold my-0">расчет доходности</h2>
      <VDropdown
        :placement="'bottom'"
        :triggers="['click']"
        class="help-dropdown"
      >
        <i class="icon-attention" />
        <template #popper>
          <div class="popup">
            <div v-close-popper class="popup-close" />
            <div class="popup-text">
              С помощью данного виджета, вы можете оценить доходность своего
              портфеля инвестиций и всех активов на платформе. Активы – это
              портфель инвестиций плюс сумма денежных средств. Для оценки
              доходности мы берем сумму чистых доходов (убытков) за период и
              делим на среднедневной размер портфеля (активов). Для приведения к
              годовой ставке мы возводим (1+y) в степень (365/n), где n – число
              дней в периоде; y – доходность за период. Показатели рассчитаны на
              конец {{ getMonthYear }} года.
            </div>
          </div>
        </template>
      </VDropdown>
    </div>
    <div>
      <CustomExtendedSelect
        v-model="selectedDatePeriods"
        :items="periods"
        calendar-mode
        :selected-icon="'icon-calendar'"
        :disabled="loading"
        @open-calendar="isShowCalendar = true"
      />
      <AnalyticsAssetsProfitableCalendar
        v-if="isShowCalendar"
        :is-show="isShowCalendar"
        :selected-range="selectedDatePeriods"
        @change="selectDateRange"
        @close="isShowCalendar = false"
      />
    </div>
    <template v-if="loading">
      <div class="mt-7">
        <Loader />
      </div>
    </template>
    <div v-if="!loading && errorMessage" class="mt-4">
      {{ errorMessage }}
    </div>
    <div v-if="!loading && !errorMessage" class="profitable-wrapper-content">
      <AnalyticsAssetsProfitableDesktop
        v-if="!isMobile"
        :data="profitabilityFiltered"
      />
      <AnalyticsAssetsProfitableMobile
        v-if="isMobile"
        :data="profitabilityFiltered"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { Loader } from 'potok-uikit';

import CustomExtendedSelect from '@/components/_generic/cselect/CustomExtendedSelect.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import server from '@/server/index';
import { useStateStore } from '@/store/stateStore';
import { parseISO, getYear, getMonth, sub } from 'date-fns';

import { getDateSince, getDateTo } from '../../../utils/date/getDateToSince.js';
import AnalyticsAssetsProfitableCalendar from '@/components/Analytics/AnalyticsAssets/AnalyticsAssetsProfitable/AnalyticsAssetsProfitableCalendar.vue';
import AnalyticsAssetsProfitableDesktop from './AnalyticsAssetsProfitable/AnalyticsAssetsProfitableDesktop.vue';
import AnalyticsAssetsProfitableMobile from './AnalyticsAssetsProfitable/AnalyticsAssetsProfitableMobile.vue';

const { isMobile } = detectDevice();
const store = useStateStore();

const periods = ref([
  {
    title: '3 месяца',
    range: [sub(new Date(), { months: 3 }), sub(new Date(), { days: 1 })],
    periodLength: 3,
  },
  {
    title: '6 месяцев',
    range: [sub(new Date(), { months: 6 }), sub(new Date(), { days: 1 })],
    periodLength: 6,
  },
  {
    title: '12 месяцев',
    range: [sub(new Date(), { months: 12 }), sub(new Date(), { days: 1 })],
    periodLength: 12,
  },
  {
    title: 'все время',
    range: [
      store.state.user.createdAt
        ? new Date(store.state.user.createdAt)
        : sub(new Date(), { years: 3 }),
      sub(new Date(), { days: 1 }),
    ],
    periodLength: 0,
  },
]);

const selectedDatePeriods = ref(periods.value[3]);
const loading = ref(false);
const profitability = ref([]);
const errorMessage = ref(null);
const resultDate = ref(null);

const isShowCalendar = ref(false);

const profitabilityFiltered = computed(() => {
  if (!profitability.value || profitability.value.length === 0) {
    return {};
  }
  return profitability.value.find(
    (i) => i.periodLength === selectedDatePeriods.value.periodLength,
  );
});

const getMonthYear = computed(() => {
  if (!resultDate.value) {
    return null;
  }
  const date = parseISO(resultDate.value);
  const months = {
    0: 'января',
    1: 'февраля',
    2: 'марта',
    3: 'апреля',
    4: 'мая',
    5: 'июня',
    6: 'июль',
    7: 'августа',
    8: 'сентября',
    9: 'октября',
    10: 'ноября',
    11: 'декабря',
  };
  const currentYear = getYear(date);
  const currentMonth = getMonth(date);
  return `${months[currentMonth]} ${currentYear}`;
});

const selectDateRange = (data) => {
  if (data.periodItem) {
    selectedDatePeriods.value = data.periodItem;
  } else {
    selectedDatePeriods.value = {
      range: [data.dateSince, data.dateTo],
      periodLength: 'custom',
    };
    getProfitability();
  }
};
const getProfitability = () => {
  errorMessage.value = null;
  loading.value = true;
  const companyId = store.selectedCompanyId;
  const query = {};
  if (selectedDatePeriods.value.periodLength === 'custom') {
    query.fromDate = getDateSince(selectedDatePeriods.value?.range[0]);
    query.toDate = getDateTo(selectedDatePeriods.value?.range[1]);
  }

  server.getProfitabilitiesV2
    .send(query, {
      params: { id: companyId },
    })
    .pipe(onGetProfitabilitySuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onGetProfitabilitySuccess = ({ data }) => {
  loading.value = false;

  if (data.error) {
    errorMessage.value = data.message;
    return;
  }

  profitability.value = data.profitability;
  resultDate.value = data.date;
};

watch(
  () => {
    return store.selectedCompanyId;
  },
  () => {
    getProfitability();
  },
);

onMounted(() => {
  getProfitability();
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.profitable-wrapper {
  width: 100%;
}
.profitable-head {
  display: flex;
  justify-content: space-between;
}
.profitable-wrapper-content {
  padding-top: 10px;
}

:deep(.item-header) {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
}
:deep(.item-row) {
  display: flex;
  align-items: center;
  padding: 25px 0;
}
:deep(.item-row-border) {
  border-bottom: 1px solid $dividers;
}
:deep(.tag-icon) {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.tag-icon-green) {
  background-color: #edfbfc;
}
:deep(.tag-icon-red) {
  background-color: #ee3d481a;
}
:deep(.tag-icon-yellow) {
  background-color: #ffc11e1f;
}
:deep(.tag-icon-grey) {
  background-color: $background;
}
:deep(.item-name) {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: $grey;
}
:deep(.item-value) {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: $deep_dive;
  @media (max-width: $size_767) {
    margin-top: 6px;
  }
}
:deep(.icon-bag) {
  background-image: url('@img/icons/analytics/assets/bag.svg');
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
:deep(.icon-cart) {
  background-image: url('@img/icons/analytics/assets/cart.svg');
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
</style>
