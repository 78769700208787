<template>
  <swiper :slides-per-view="'auto'" style="margin-right: -15px">
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <DashboardBalancesActive />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px" data-tg-id="3">
        <div class="dashboard_block_content block_content">
          <template v-if="props.loadingBalance">
            <div class="balances-item col-md-6">
              <div class="property" style="margin-top: 15px">
                <Loader />
              </div>
            </div>
          </template>
          <template v-if="!props.loadingBalance">
            <DashboardBalancesMoney
              @show-cashbox-setting="emit('showCashboxSetting')"
              @deposit-money="emit('depositMoney')"
            />
          </template>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="strategy-slide">
      <div class="dashboard_block" style="margin-right: 16px" data-tg-id="2">
        <div class="dashboard_block_content block_content">
          <DashboardBalancesStrategy />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <DashboardBalancesResult :loading="loadingResult" />
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script setup>
import { Swiper } from '@/libs/swiper/swiper';
import { SwiperSlide } from '@/libs/swiper/swiper-slide';
import '@/libs/swiper/swiper.min.css';
import { Loader } from 'potok-uikit';

import DashboardBalancesMoney from '../DashboardBalancesMoney.vue';
import DashboardBalancesActive from '../DashboardBalancesActive.vue';
import DashboardBalancesResult from '../DashboardBalancesResult.vue';
import DashboardBalancesStrategy from '../DashboardBalancesStrategy.vue';

const emit = defineEmits(['depositMoney', 'showCashboxSetting']);
const props = defineProps(['loadingBalance', 'loadingResult']);
</script>
<style lang="scss" scoped>
.dashboard_block_content {
  height: 396px;
}
</style>
