<template>
  <input
    :id="id"
    ref="input_suggestion"
    v-model="model"
    type="text"
    autocomplete="off"
    :placeholder="props.placeholder"
    :disabled="props.disabled"
    @change="onChange"
  />
</template>

<script setup>
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeMount,
  onUnmounted,
} from 'vue';

import $ from 'jquery';
import 'suggestions-jquery';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  /** Поле для хранения полной информации о результате */
  fullInfo: {},
  /** Параметры для Дадата */
  options: {},
  /** API-ключ */
  token: {
    type: String,
    default: '',
  },
  /** Тип подсказок */
  type: {
    type: String,
    default: '',
  },
  fieldValue: {
    type: String,
    default: 'value',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: '',
  },
});

const emit = defineEmits([
  'update:fullInfo',
  'update:fullInfo',
  'update:modelValue',
  'change',
]);

const input_suggestion = ref();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const currentOptions = ref({
  /** API-ключ */
  token: '',
  /** Тип подсказок */
  type: '',
  /** ИДЕНТИФИКАТОР_РАЗРАБОТЧИКА */
  partner: '',
});
const defaultOptions = ref({
  /** Прокручивать текстовое поле к верхней границе экрана при фокусе */
  scrollOnFocus: false,
  /** Автоматически подставлять подходящую подсказку из списка, когда текстовое поле теряет фокус.*/
  triggerSelectOnBlur: false,
  /** Автоматически подставлять подходящую подсказку из списка при нажатии на Enter. */
  triggerSelectOnEnter: true,
  /** Что показывать в правом углу текстового поля подсказок */
  addon: 'spinner',
});

watch(
  () => props.modelValue,
  () => {
    model.value = props.modelValue;
  },
);
onBeforeMount(() => {
  parseOptions();
  if (!currentOptions.value.token) {
    console.warn('Dadata: Необходимо указать API-токен');
  }
  if (!currentOptions.value.type) {
    console.warn('Dadata: Необходимо указать тип подсказок');
  }
});
onMounted(() => {
  model.value = props.modelValue;
  initSuggestion();
  $(input_suggestion.value).attr('autocomplete', 'off');
});
const parseOptions = () => {
  currentOptions.value = Object.assign(
    currentOptions.value,
    defaultOptions.value,
  );

  currentOptions.value = Object.assign(currentOptions.value, props.options);
  if (props.token) currentOptions.value.token = props.token;
  if (props.type) currentOptions.value.type = props.type;
  currentOptions.value = Object.assign(currentOptions.value, {
    onSearchError: (q, w) => {
      if (w.status === 403) {
        console.warn('Ошибка доступа! \n ' + w.responseJSON.message);
      }
    },
  });
};
const initSuggestion = () => {
  $(input_suggestion.value).suggestions(currentOptions.value);
};
const destroySuggestion = () => {
  const plugin = $(input_suggestion.value).suggestions();
  if (plugin) {
    plugin.dispose();
  }
};
const onChange = () => {
  if (model.value === null || model.value === '') {
    emit('update:fullInfo', null);
  }
  if (typeof model.value === 'string') {
    emit('update:fullInfo', null);
  }
  emit('update:modelValue', model.value);
  emit('change', model.value);
};
onUnmounted(() => {
  destroySuggestion();
});
</script>

<style lang="scss" scoped>
@import '../../../node_modules/suggestions-jquery/dist/css/suggestions.min.css';
</style>
