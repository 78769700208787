<template>
  <swiper :slides-per-view="'auto'" style="margin-right: -15px">
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <AnalyticsAssetsProfitableResult :data="data" />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <AnalyticsAssetsProfitableProfit :data="data" />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="dashboard_block" style="margin-right: 16px">
        <div class="dashboard_block_content block_content">
          <AnalyticsAssetsProfitableCleanProfit :data="data" />
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script setup>
import { toRefs } from 'vue';
import { Swiper } from '@/libs/swiper/swiper';
import { SwiperSlide } from '@/libs/swiper/swiper-slide';
import '@/libs/swiper/swiper.min.css';

import AnalyticsAssetsProfitableResult from './AnalyticsAssetsProfitableResult.vue';
import AnalyticsAssetsProfitableProfit from './AnalyticsAssetsProfitableProfit.vue';
import AnalyticsAssetsProfitableCleanProfit from './AnalyticsAssetsProfitableCleanProfit.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});
const { data } = toRefs(props);
</script>
