export default class Pagination {
  /**
   * @param {Number} [chunk=20]
   * @param {Number} [offset=0]
   */
  constructor(chunk = 20, offset = 0) {
    this.__chunk = chunk;
    this.__offset = offset;
    this.__isLast = false;
  }

  /**
   * @returns {Number}
   */
  get chunk() {
    return this.__chunk;
  }

  /**
   * @returns {Boolean}
   */
  get isLast() {
    return this.__isLast;
  }

  /**
   * @param {Boolean} v
   */
  set isLast(v) {
    this.__isLast = v;
  }

  /**
   * @returns {Number}
   */
  get offset() {
    return this.__offset;
  }

  /**
   * @param {Number} n
   */
  set offset(n) {
    const diff = n - this.__offset;
    this.__isLast = diff < this.__chunk;
    this.__offset = n;
  }

  /**
   * Rests the state
   */
  reset() {
    this.offset = 0;
    this.isLast = false;
  }
}
