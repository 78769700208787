<template>
  <div>
    <h2 class="page_title">{{ props.title }}</h2>
  </div>
</template>
<script setup>
const props = defineProps({
  title: {
    type: String,
  },
});
</script>
<style lang="scss" scoped></style>
