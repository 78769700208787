<template>
  <section class="investor_pulse">
    <div class="row">
      <div class="col-xs-12">
        <h2 class="block_title" style="text-transform: none">пульс Потока</h2>
      </div>
    </div>
    <template v-if="store.state.loaders.isPulseProjectStatsLoading">
      <div class="balances-item col-md-6">
        <div class="property" style="margin-top: 15px">
          <Loader />
        </div>
      </div>
    </template>
    <template
      v-if="
        !store.state.loaders.isPulseProjectStatsLoading &&
        !store.state.loaders.isPlatformStatFutureLoading
      "
    >
      <DashboardPulseDesktop
        v-if="!isMobile"
        :project-stats="data.projectStats"
        :future-stats="futureStats"
      />
      <DashboardPulseMobile
        v-if="isMobile"
        :project-stats="data.projectStats"
        :future-stats="futureStats"
      />
    </template>
  </section>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue';
import { Loader } from 'potok-uikit';
import server from '@/server';

import DashboardPulseDesktop from './DashboardPulse/DashboardPulseContainers/DashboardPulseDesktop.vue';
import DashboardPulseMobile from './DashboardPulse/DashboardPulseContainers/DashboardPulseMobile.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();

const data = reactive({
  projectStats: {},
});

const { isMobile } = detectDevice();

const futureStats = ref(null);

const fetchPlatformStatFutureAsync = () => {
  return server.getPlatformStatFuture
    .send(null, {})
    .pipe(onFetchPlatformStatFutureSuccess, () => {
      store.setPlatformStatFutureLoading(false);
    })
    .exec();
};
const onFetchPlatformStatFutureSuccess = (obj) => {
  store.setPlatformStatFutureLoading(false);
  if (!obj.data) {
    return;
  }
  futureStats.value = obj.data;
};

fetchPlatformStatFutureAsync();

const fetchProjectsRaisingStatsAsync = () => {
  const query = {};

  if (store.selectedCompanyId) {
    query.companyId = store.selectedCompanyId;
  }

  return server.getProjectsRaisingStats
    .send(query, {})
    .pipe(onFetchProjectsRaisingStatsSuccess, () => {
      store.setPulseProjectStatsLoading(false);
    })
    .exec();
};
const onFetchProjectsRaisingStatsSuccess = (obj) => {
  store.setPulseProjectStatsLoading(false);
  if (!obj.data) {
    return;
  }
  data.projectStats = obj.data;
};

onMounted(() => {
  fetchProjectsRaisingStatsAsync();
});
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.investor_pulse {
  .block_title {
    margin-top: 70px;
    margin-bottom: 30px;
    @media (max-width: $size_767) {
      margin-top: 40px;
    }
  }
  :deep(.row_title) {
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.02em;
    margin-top: 9px;
    color: $deep_dive;
    @media (max-width: $size_767) {
      font-weight: 500;
    }
  }

  :deep(.dashboard_block) {
    .dashboard_block_content {
      @media (max-width: $size_767) {
        padding: 20px;
      }
      .content_title {
        padding: 10px 0;
        @media (max-width: $size_767) {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
