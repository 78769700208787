import { format, parseISO, parse } from 'date-fns';
import ru from 'date-fns/locale/ru';
import Decimal from 'decimal.js-light';
import Autolinker from 'autolinker';
import * as jsUtils from '@/utils/commonUtils/libUtils';
import constants from '@/constants';

export const formatLoginToPhoneNumber = jsUtils.formatPhone;

export * from '@/utils/commonUtils/libUtils';

if (!Promise.allSettled) {
  Promise.allSettled = function (promises) {
    return Promise.all(
      promises.map((p) =>
        Promise.resolve(p).then(
          (value) => ({
            status: 'fulfilled',
            value: value,
          }),
          (error) => ({
            status: 'rejected',
            reason: error,
          }),
        ),
      ),
    );
  };
}

/**
 * @param {String} id
 * @param {String} [nodeEnv=process.env.VUE_APP_NODE_ENV]
 * @return {String}
 */
export function tagId(id, nodeEnv = process.env.VUE_APP_NODE_ENV) {
  return nodeEnv === 'production' ? jsUtils.uuidv4() : id;
}

/**
 * Turns 12.12.2012 to 2012-12-11T21:00:00.000Z (ISO string)
 * @param {String} dateRuAsString
 * @return {String}
 */
export function convertDateFromRuToISOString(dateRuAsString) {
  return parse(dateRuAsString, 'dd.MM.yyyy', new Date()).toISOString();
}

/**
 * Returns formatted date without current year
 * @param {Date|String|Number} date
 * @param {String} [format]
 * @return {String}
 */

export function formatDateResponsiveYear(date, localFormat = 'dd.MM.yyyy') {
  let responsiveFormat = localFormat;
  if (format(parseISO(date), 'yyyy') == format(new Date(), 'yyyy')) {
    responsiveFormat = localFormat.replace(
      /(\s\.|\/|-|)y{2,4}(\s\.|\/|-|)/,
      '',
    );
  }
  return format(parseISO(date), responsiveFormat, { locale: ru });
}

/**
 * @returns {void}
 */
export function debug(...args) {
  if (process.env.VUE_APP_NODE_ENV === 'development' || window.isDebugMode) {
    console.log(...args);
  }
}

/**
 * @param {String} url
 * @returns {void}
 */
export function redirectTo(url) {
  return window.location.assign(url);
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 * @param {Number} min
 * @param {Number} max
 * @return {Number}
 */
export function getRandomIntInclusive(_min, _max) {
  let min = Math.ceil(_min);
  let max = Math.floor(_max);
  // The maximum is inclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
/**
 * @param {String|Number} any
 * @return {Decimal}
 */
export function decimal(_value) {
  const value = _value || 0;
  return new Decimal(value);
}

export function merge(arr1, arr2) {
  const types = [jsUtils.getType(arr1), jsUtils.getType(arr2)];
  jsUtils.assert(
    types[0] === types[1],
    'You cannot merge arguments with different types.',
  );
  switch (types[0]) {
    case '[object Array]':
      return arr1.concat(arr2);
  }
}

/**
 * @param {Array} array
 * @param {Function} [getKey=getId]
 * @param {Function} [getValue=identity]
 * @returns {Object}
 */
export function hashArray(
  array,
  getKey = (x) => x.id,
  getValue = jsUtils.identity,
) {
  return array.reduce((acc, item) => {
    return Object.assign(acc, { [getKey(item)]: getValue(item) });
  }, {});
}
/**
 * @param {String} id
 */
export function focusDomElementById(id) {
  const el = document.getElementById(id);
  el && el.focus();
}

/**
 * @param {*} any
 * @returns {Boolean}
 */
export function isNotNull(any) {
  return !isNull(any);
}
/**
 * @param {*} any
 * @returns {Boolean}
 */
export function isNull(any) {
  return any === null;
}
/**
 * @see https://github.com/gregjacobs/Autolinker.js#usage
 * @param {String} text
 * @param {Object} [opts]
 * @returns {String}
 */
export function autoLink(text, opts = {}) {
  return Autolinker.link(text, opts);
}

/**
 * @param {Array} any
 * @return {Object}
 */
export function reduceToObject(any) {
  const type = jsUtils.getType(any);
  switch (type) {
    case '[object Array]':
      return any.reduce((acc, item) => Object.assign(acc, item), {});
    default:
      throw new Error('Unsupported type');
  }
}
/**
 * @param {Blob} blob
 */
export function createObjectURL(blob) {
  return window.URL.createObjectURL(blob);
}

/**
 * @param {Object} dst
 * @param {Object} src
 * @returns {Object|Array}
 */
export function extend(dst, ...src) {
  for (let source of src) {
    mergeObjects(dst, source);
  }
  return dst;
}

/**
 * Recursively merges objects
 * @param {Object} dst
 * @param {Object} src
 * @returns {Object}
 */
function mergeObjects(dst, src) {
  for (let key in src) {
    if (Object.prototype.hasOwnProperty.call(src, key)) {
      if (
        jsUtils.isObject(dst[key]) &&
        jsUtils.getType(dst[key]) === jsUtils.getType(src[key])
      ) {
        mergeObjects(dst[key], src[key]);
      } else {
        dst[key] = jsUtils.copy(src[key]);
      }
    }
  }
  return dst;
}

export function getProjectStatus(project) {
  const { statusId, isRestructured, overdueDays } = project;
  const { projectStatusTitle, projectStatusClass, projectStatus } = constants;
  const status = {
    statusTitle: projectStatusTitle[statusId],
    statusClassName: projectStatusClass[statusId],
  };

  if (
    statusId === projectStatus.cession1st ||
    statusId === projectStatus.cession2nd ||
    statusId === projectStatus.cessionFixed
  ) {
    if (isRestructured) {
      status.statusTitle = 'Реструктурирован';
      status.statusClassName = 'project-restructured';
    } else {
      status.statusTitle = 'Дефолт';
    }
  } else if (overdueDays && statusId !== projectStatus.refunded) {
    status.statusTitle = projectStatusTitle[projectStatus.overdue];
    status.statusClassName = 'project-overdue';
  }

  return status;
}

export function localParseDate(date) {
  let result = parse(date, 'dd.MM.yyyy', new Date());
  if (!isNaN(result.getTime())) {
    return result;
  }
  result = parse(date, 'yyyy-MM-dd', new Date());
  if (!isNaN(result.getTime())) {
    return result;
  }
}
