<template>
  <div class="password_wrapper">
    <input
      :id="props.id"
      v-model="value"
      v-maska="props.vmaska"
      class="form-control"
      :type="!isShowPassword ? 'password' : 'text'"
      :readonly="props.loading"
      :placeholder="props.placeholder"
    />
    <label
      :for="props.id"
      title="показать пароль"
      @click="isShowPassword = !isShowPassword"
    >
      <i v-if="!isShowPassword" class="icon_password_eye" />
      <i v-if="isShowPassword" class="icon_password_eye_close" />
    </label>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';

const props = defineProps([
  'modelValue',
  'vmaska',
  'loading',
  'placeholder',
  'id',
]);
const emit = defineEmits(['update:modelValue']);
const isShowPassword = ref(false);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.password_wrapper {
  position: relative;
  background: #f2f4f8;
  height: 60px;
  border-radius: 8px;
  padding-right: 38px;
}
input {
  padding-left: 20px;
  color: $deep_dive;
  height: 60px;
  border: 0px;
  background: transparent;
  margin: 0 auto;
  &:focus {
    border: 0;
    box-shadow: none;
  }
}
label {
  position: absolute;
  top: 24px;
  right: 20px;
  cursor: pointer;
}
</style>
