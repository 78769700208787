<template>
  <PotokPage class="login-register">
    <component
      :is="currentComponent"
      :raw-phone="props.rawPhone"
      :raw-password="rawPassword"
      :raw-email="rawEmail"
      :sms-id="smsId"
      @change-password="changePassword"
      @change-email="changeEmail"
      @change-step="changeStep"
      @change-sms-id="changeSmsId"
    />
  </PotokPage>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { PotokPage } from 'potok-uikit';

import LoginInputRegisterStep1 from '../../components/Login/LoginRegister/LoginRegisterStep1.vue';
import LoginInputRegisterStep2 from '../../components/Login/LoginRegister/LoginRegisterStep2.vue';
import LoginInputRegisterStep3 from '../../components/Login/LoginRegister/LoginRegisterStep3.vue';
const props = defineProps(['rawPhone', 'componentStep']);
const emit = defineEmits(['changeMode', 'changePassword']);

const rawPassword = ref(null);
const rawEmail = ref(null);
const errorMessageRegister = ref(null);

const currentStep = ref('inputPassword');
const smsId = ref(null);

const currentComponent = computed(() => {
  if (currentStep.value === 'inputPassword') {
    return LoginInputRegisterStep1;
  }
  if (currentStep.value === 'inputEmail') {
    return LoginInputRegisterStep2;
  }
  if (currentStep.value === 'inputSMSCode') {
    return LoginInputRegisterStep3;
  }
  return null;
});

const changeStep = (step) => {
  if (step === 'inputPhone') {
    return emit('changeMode', {
      component: 'inputPhone',
    });
  }
  currentStep.value = step;
};
const changePassword = (value) => {
  errorMessageRegister.value = null;
  rawPassword.value = value;
};
const changeEmail = (value) => {
  errorMessageRegister.value = null;
  rawEmail.value = value;
};
const changeSmsId = (value) => {
  smsId.value = value;
};

watch(
  () => {
    return props.componentStep;
  },
  () => {
    currentStep.value = props.componentStep;
  },
);
onMounted(() => {
  if (props.componentStep) {
    currentStep.value = props.componentStep;
  }
});
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.login_wrapper .login-register {
  padding-top: 72px;
}
:deep(.container_wrapper) {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $size_991) {
    width: 100%;
  }
}
:deep(.login_title) {
  margin-top: 74px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $deep_dive;
  width: 100%;
  display: flex;
  justify-content: center;
  letter-spacing: -0.01em;
  div {
    max-width: 688px;
  }
  @media (max-width: $size_991) {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;
    div {
      max-width: 208px;
    }
  }
}

:deep(.login_subtitle_restricted) {
  max-width: 379px;
  margin: 0 auto;
}

:deep(.login_input_wrapper) {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;

  @media (max-width: $size_991) {
    margin-right: initial;
  }
  input {
    width: 340px;
    padding-left: 20px;
    color: $deep_dive;
    height: 60px;
    border: 0px;
    background: #f2f4f8;
    border-radius: 8px;
    @media (max-width: $size_991) {
      min-width: 288px;
      width: 100%;
    }
  }
  #passwordConfirm {
    margin-top: 20px;
  }
}

:deep(.login_actions) {
  width: 340px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.button-continue) {
  margin-top: 24px;
  width: 340px;
  height: 60px;
}
:deep(.button-back) {
  color: $grey;
  margin-top: 20px;
}
:deep(.text_tips) {
  color: $grey;
  margin-top: 10px;
  margin-bottom: 0;
}
:deep(.footer_message) {
  color: $grey;
  max-width: 536px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  a {
    color: $deep_dive;
  }
}
:deep(.error_message) {
  margin-top: 10px;
}
</style>
