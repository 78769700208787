<template>
  <div class="money-value">
    <slot name="before" />
    <span class="money-value-base" :style="{ color: props.color }">
      {{ baseValue }}
    </span>
    <span
      v-if="!props.rounding"
      class="money-value-comma"
      :style="{ color: props.commaColor }"
    >
      {{ valueAfterComma }}
    </span>
    <slot name="after" />
  </div>
</template>

<script setup>
import { formatCurrency } from '@/utils/commonUtils/libUtils';
import { computed } from 'vue';

const props = defineProps({
  value: {
    type: [Number, String],
    required: false,
  },
  rounding: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: '#10273D',
  },
  commaColor: {
    type: String,
    default: '#818E99',
  },
});

const valueAfterComma = computed(() => {
  if (!props.value) {
    return '';
  }

  const stringedValue = props.value.toString();
  let value = stringedValue.split('.')[1];

  if (!value) {
    return '';
  }

  if (value.length === 1) {
    value += '0';
  }

  if (value.length > 2) {
    value = value.substring(0, 2);
  }

  return `,${value} ₽`;
});

const baseValue = computed(() => {
  let value = formatCurrency(props.value, 1);
  let newValue = value.toString().split('');
  newValue.splice(value.length - 2, 2);
  let finalValue = newValue.join('');

  if (props.rounding || !valueAfterComma.value) {
    finalValue += ' ₽';
  }

  return finalValue;
});
</script>
