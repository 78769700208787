<template>
  <div class="rules-alfa potok-text-body-3 mt-4">
    <p class="rules-alfa-dropdown-text my-0">
      Нажимая кнопку «Продолжить», вы присоединяетесь к
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
        target="_blank"
        class="potok-color-deepdive"
      >
        Правилам платформы</a
      >, подтверждаете, что ознакомились и
      <a
        href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D0%BB%D0%BA%D0%B8/%D0%94%D0%B5%D0%BA%D0%BB%D0%B0%D1%80%D0%B0%D1%86%D0%B8%D1%8F%20%D0%BE%20%D1%80%D0%B8%D1%81%D0%BA%D0%B0%D1%85.docx.pdf"
        target="_blank"
        class="potok-color-deepdive"
      >
        принимаете риски</a
      >, связанные с инвестированием; подтверждаете, что являетесь налоговым
      резидентом РФ и не являетесь
    </p>

    <div class="rules-alfa-dropdown-text">
      <VDropdown placement="bottom-start">
        <span class="potok-color-deepdive"
          >российским или иностранным должностным лицом</span
        >
        <template #popper>
          <div class="popup">
            <div v-close-popper class="popup-close" />
            <div class="popup-text">
              ДЛПМО - должностные лица публичных международных организаций?<br />
              ИПДЛ - иностранные публичные должностные лица;<br />
              РПДЛ - лица, замещающие (занимающие) государственные должности РФ
            </div>
          </div>
        </template>
      </VDropdown>
      <span class="my-0"> и/или родственником такого лица, </span>
    </div>

    <div class="rules-alfa-dropdown-text">
      <span class="my-0">действуете к собственной выгоде, </span>
      <VDropdown placement="bottom-start">
        <span class="potok-color-deepdive">выгодоприобретатели</span>
        <template #popper>
          <div class="popup">
            <div v-close-popper class="popup-close" />
            <div class="popup-text">
              лица, к выгоде которых действует инвестор, в том числе на
              основании агентского договора, договоров поручения, комиссии и
              доверительного управления, при проведении операций с денежными
              средствами и иным имуществом
            </div>
          </div>
        </template>
      </VDropdown>
      <span class="my-0"> отсутствуют.</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.dropdown-trigger {
  cursor: pointer;
  color: $tiffany;
}
.rules-alfa {
  text-transform: none;
  color: $grey;
}
.rules-alfa-dropdown-text {
  display: inline;
}
</style>
