import AnalyticsPage from '@/pages/AnalyticsPage.vue';
import AnalyticsMyPortfolio from '@/pages/Analytics/AnalyticsMyPortfolio.vue';
import AnalyticsHistoryOperation from '@/pages/Analytics/AnalyticsHistoryOperation.vue';
import AnalyticsHistoryEvent from '@/pages/Analytics/AnalyticsHistoryEvent.vue';
import AnalyticsAssets from '@/pages/Analytics/AnalyticsAssets.vue';

export default [
  {
    path: '/analytics',
    name: 'analytics',
    components: {
      content: AnalyticsPage,
    },
    meta: {
      title: 'Аналитика',
    },
    redirect: () => {
      return {
        name: 'analyticsMyPortfolio',
      };
    },
    children: [
      {
        name: 'analyticsMyPortfolio',
        path: '',
        component: AnalyticsMyPortfolio,
      },
      {
        name: 'analyticsHistoryOperation',
        path: 'history-operation',
        component: AnalyticsHistoryOperation,
      },
      {
        name: 'analyticsHistoryEvent',
        path: 'history-event',
        component: AnalyticsHistoryEvent,
      },
      {
        name: 'analyticsAssets',
        path: 'assets',
        component: AnalyticsAssets,
      },
    ],
  },
];
