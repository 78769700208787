<template>
  <swiper :slides-per-view="'auto'" style="margin-right: -15px">
    <swiper-slide>
      <div style="margin-right: 16px">
        <AnalyticsHistoryTotalIncome
          :loading="props.loading"
          :income="props.total.income"
        />
      </div>
    </swiper-slide>
    <swiper-slide>
      <div style="margin-right: 16px">
        <AnalyticsHistoryTotalOutcome
          :loading="props.loading"
          :outcome="props.total.outcome"
        />
      </div>
    </swiper-slide>
    <swiper-slide>
      <div style="margin-right: 16px">
        <AnalyticsHistoryTotalEmail
          :loading="loadingEmail"
          @send-email="emit('sendEmail')"
        />
      </div>
    </swiper-slide>
  </swiper>
</template>
<script setup>
import { Swiper } from '@/libs/swiper/swiper';
import { SwiperSlide } from '@/libs/swiper/swiper-slide';
import '@/libs/swiper/swiper.min.css';

import AnalyticsHistoryTotalIncome from '../AnalyticsHistoryOperationTotalIncome.vue';
import AnalyticsHistoryTotalOutcome from '../AnalyticsHistoryOperationTotalOutcome.vue';
import AnalyticsHistoryTotalEmail from '../AnalyticsHistoryOperationTotalEmail.vue';

const emit = defineEmits(['sendEmail']);
const props = defineProps({
  loading: Boolean,
  loadingEmail: Boolean,
  total: Object,
});
</script>
<style></style>
