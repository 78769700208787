<template>
  <div>
    <div
      :class="{ header__menulist__show: props.isShowAccountList }"
      class="header__menulist_button"
      @click.stop.prevent="onClickToggle()"
    >
      <span class="header__menulist_text">
        {{ getAccountName(selectedCompany) }}
      </span>
      <i
        :class="{
          'fa-angle-up': props.isShowAccountList,
          'fa-angle-down': !props.isShowAccountList,
        }"
        class="desktop-icon fa"
      ></i>
    </div>
    <div v-if="props.isShowAccountList" class="account_list">
      <AccountsList
        :account-list="accountList"
        @choose-company-by-id="onClickChooseCompany"
        @add-account="onClickAddAccount"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStateStore } from '@/store/stateStore';
import headerComposable from '@/composables/headerComposable';
import AccountsList from './AccountsList.vue';

const { getAccountName } = headerComposable();

const store = useStateStore();

const props = defineProps(['isShowAccountList']);
const emit = defineEmits([
  'changeShowAccountList',
  'chooseCompanyById',
  'addAccount',
]);

const accountList = computed(() => {
  return store.getAccountList;
});

const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const onClickChooseCompany = (data) => {
  emit('chooseCompanyById', data);
};

const onClickToggle = () => {
  emit('changeShowAccountList', !props.isShowAccountList);
};

const onClickAddAccount = () => {
  emit('addAccount');
};
</script>
