export const investmentTypes = [
  {
    value: 'all',
    title: 'все инвестиции',
  },
  {
    value: 'auto',
    title: 'автоинвест',
  },
  {
    value: 'manual',
    title: 'ручное',
  },
];
