<template>
  <div class="total_wrapper">
    <div class="icon_receive_square"></div>
    <div class="content_title">отчет</div>
    <button
      class="button button-text button_email"
      type="button"
      @click="onClickSendEmail"
    >
      выслать на почту
      <span class="icon_arrow_long_right"></span>
    </button>
  </div>
</template>
<script setup>
const emit = defineEmits(['sendEmail']);
const onClickSendEmail = () => {
  emit('sendEmail');
};
</script>
<style lang="scss" scoped>
.button_email {
  margin-top: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #10273d;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
