<template>
  <ModalFull :title="'Подписание документа'" @close="close">
    <template #content>
      <div class="subtitle" style="margin-bottom: 40px">
        подтвердите инвестиционное предложение по займу
        {{ investment.number }}:<br />
        сумма <span>{{ formatCurrency(props.investment.amount) }}</span> ₽, срок
        <span>{{ props.investment.term }}</span> дней
      </div>

      <two-factor-auth
        :phone="login"
        :authentication="authentication"
        :error-message="errorMessage"
        :loading="loadingPostProjectInvestmentConfirm"
        @change="onChangeInput"
        @on-enter="onEnter"
        @close="close"
      >
        <button
          :disabled="
            v$.authentication.smsCode.$invalid || isPendingConfirmation
          "
          type="button"
          class="button button-primary"
          style="width: 100%"
          @click="postProjectInvestmentConfirmAsync"
        >
          Подписать
        </button>
      </two-factor-auth>
    </template>
  </ModalFull>
</template>

<script setup>
import { ref, computed, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import useVuelidate from '@vuelidate/core';
import validators from '@/validators';

import tracker from '@/tracker';
import server from '@/server/index';
import TwoFactorAuth from '@/components/_generic/TwoFactorAuth.vue';
import TwoFactorAuthentication from '@/components/_generic/TwoFactorAuthentication';
import { focusDomElementById, formatCurrency } from '@/utils/commonUtils/utils';
import { useStateStore } from '@/store/stateStore';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const emit = defineEmits(['close']);
const props = defineProps(['investment']);
const router = useRouter();
const store = useStateStore();

const rulesValidations = {
  authentication: {
    smsCode: {
      required: validators.required,
      exactLength: validators.exactLength(4),
      numeric: validators.numeric,
    },
  },
};

const authentication = reactive(new TwoFactorAuthentication());
const v$ = useVuelidate(rulesValidations, { authentication: authentication });
const loadingPostProjectInvestmentConfirm = ref(false);
const errorMessage = ref(null);

const steps = computed(() => {
  return TwoFactorAuthentication.steps;
});

const isPendingConfirmation = computed(() => {
  return loadingPostProjectInvestmentConfirm.value;
});
const login = computed(() => {
  return store.state.session.login;
});
const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

onMounted(() => {
  authentication.setStep(steps.value.ACTIVE);
  focusDomElementById('smsCode');
});

const close = () => {
  emit('close');
};
const postProjectInvestmentConfirmAsync = () => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Manual_SMS');

  const query = {
    id: props.investment.id,
    code: authentication.smsCode,
  };
  loadingPostProjectInvestmentConfirm.value = true;
  return server.postProjectInvestmentConfirm
    .send(query)
    .pipe(
      onPostProjectInvestmentConfirmAsyncSuccess.bind(this),
      onPostProjectInvestmentConfirmAsyncError.bind(this),
    )
    .exec();
};
const onPostProjectInvestmentConfirmAsyncSuccess = () => {
  loadingPostProjectInvestmentConfirm.value = false;

  store.fetchBalance({
    companyId: selectedCompanyId.value,
  });
  close();
  store.pushTip({
    message: 'Инвестирование в заём подписано',
  });
  router.push({ name: 'loans' });
};
const onPostProjectInvestmentConfirmAsyncError = (error) => {
  loadingPostProjectInvestmentConfirm.value = false;
  const { data } = error;
  if (data.message) {
    errorMessage.value = data.message;
  }
};
const onEnter = () => {
  if (v$.value.authentication.smsCode.$invalid || isPendingConfirmation.value) {
    return;
  }
  postProjectInvestmentConfirmAsync();
};
const onChangeInput = () => {
  errorMessage.value = null;
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

:deep(.modal_block_container) {
  max-width: 440px;
}
:deep(.modal_block_title) {
  justify-content: center;
}
:deep(.modal_mobile_block_title) {
  text-align: center;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #7d7d7e;
  margin-bottom: 0px;
  text-align: center;
}
.button {
  width: auto;
  height: auto;
  line-height: initial;
}
</style>
