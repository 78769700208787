import {
  formatCurrency,
  formatDate,
  pluralize,
} from '@/utils/commonUtils/utils';
import {
  paymentFreqMobile,
  contentInvestmentDesktop,
  contentInvestmentMobile,
} from './investmentsColumnsUtils.js';

export const ColumnsTotalDefault = (items) => {
  let myInvestmentAmount = 0;
  let myInvestmentDebt = 0;
  items.forEach((i) => {
    myInvestmentAmount += i.myInvestmentAmount;
    myInvestmentDebt += i.myInvestmentDebt;
  });
  return {
    mobileTitle: 'Всего по дефолтным инвестициям',
    myInvestmentAmount: formatCurrency(myInvestmentAmount, 0) + ' ₽',
    myInvestmentDebt: formatCurrency(myInvestmentDebt, 0) + ' ₽',
  };
};

export const ColumnsDefault = [
  {
    title: 'Инвестиция',
    title_mobile: ' ',
    is_mobile_full: true,
    th_desktop_style: {
      'padding-left': '20px',
    },
    content_desktop(item) {
      return contentInvestmentDesktop(item);
    },
    content_mobile(item) {
      return contentInvestmentMobile(item);
    },
  },
  {
    title: 'Ставка',
    sort: true,
    sort_slug: 'stavka',
    content_desktop(item) {
      const isBuyback =
        item.purchaseDeals && item.purchaseDeals.length > 0 ? true : false;
      const loanRate = isBuyback
        ? item.purchaseDeals[0].purchasedInvestmentRate
        : item.loanRate * 100;
      return `
        ${formatCurrency(loanRate, 2)} %
      `;
    },
  },
  {
    title: 'Дата инвест.',
    title_mobile: 'Дата инвестирования',
    sort: true,
    sort_slug: 'invest_date',
    content_desktop(item) {
      return `
      ${formatDate(item.loanFundedAt, 'dd.MM.yyyy')}
      `;
    },
  },
  {
    title: 'Дата цессии',
    sort: true,
    sort_slug: 'cession_date',
    content_desktop(item) {
      let date = item.cessionStartAt;
      if (date) {
        date = formatDate(item.cessionStartAt, 'dd.MM.yyyy');
      }
      return date;
    },
  },
  {
    title: 'Просрочка',
    sort: true,
    sort_slug: 'overdue',
    content_desktop(item) {
      return `
      ${
        item.daysOverdue +
        ' ' +
        pluralize(item.daysOverdue, ['день', 'дня', 'дней'])
      }
      `;
    },
    content_mobile(item) {
      return `
      ${formatDate(item.loanDue, 'dd.MM.yyyy')}
      ${paymentFreqMobile(item.loanPaymentFreq)}
      `;
    },
  },
  {
    slug: 'myInvestmentAmount',
    title: 'Вложено',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.myInvestmentAmount, 0) + ' ₽'}
      `;
    },
  },
  {
    slug: 'myInvestmentDebt',
    title: 'Остаток осн. долга',
    sort: true,
    sort_slug: 'osd',
    th_desktop_style: {
      'padding-right': '15px',
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'padding-right': '15px',
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.myInvestmentDebt, 0) + ' ₽'}
      `;
    },
  },
];
