<template>
  <div class="investor_pulse">
    <swiper :slides-per-view="'auto'" style="margin-right: -15px">
      <swiper-slide>
        <div class="dashboard_block" style="height: 257px; margin-right: 16px">
          <DashboardPulseBlock1 :project-stats="props.projectStats" />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="dashboard_block" style="height: 257px; margin-right: 16px">
          <DashboardPulseBlock2 :project-stats="props.projectStats" />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="dashboard_block" style="height: 257px; margin-right: 16px">
          <DashboardPulseBlock3 :future-stats="props.futureStats" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
import { Chart, registerables } from 'chart.js';

import { Swiper } from '@/libs/swiper/swiper';
import { SwiperSlide } from '@/libs/swiper/swiper-slide';
import '@/libs/swiper/swiper.min.css';

import DashboardPulseBlock1 from '../DashboardPulseBlock1.vue';
import DashboardPulseBlock2 from '../DashboardPulseBlock2.vue';
import DashboardPulseBlock3 from '../DashboardPulseBlock3.vue';

Chart.register(...registerables);

const props = defineProps(['projectStats', 'futureStats']);
</script>
<style lang="scss" scoped>
.content_title_statistic {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
</style>
