<template>
  <div>
    <transition name="fade">
      <ModalFull v-if="alert.message" :title="alert.title" @close="close">
        <template #content>
          <div
            :class="{
              modal_text_tiny: alert.title,
              modal_text: !alert.title,
            }"
            v-html="alert.message"
          ></div>
        </template>
        <template #actions>
          <div class="button-center">
            <button
              type="button"
              class="button button-secondary button_ok"
              @click.stop="onClickOk"
            >
              {{ alert.action_text ? alert.action_text : 'понятно' }}
            </button>
          </div>
        </template>
      </ModalFull>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { useStateStore } from '@/store/stateStore';

const loading = ref(false);
const store = useStateStore();
const alert = computed(() => {
  return store.state.alert;
});
const close = () => {
  store.removeAlert();
};
const onClickOk = () => {
  if (store.state.alert.callback) {
    loading.value = true;
    store.state.alert.callback(() => {
      loading.value = false;
      store.removeAlert();
    });
  } else {
    store.removeAlert();
  }
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
:deep(.modal_title) {
  text-align: center;
  margin: auto;
}
:deep(.modal_block_container) {
  max-width: 400px;
}
.modal_text {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.01em;
  color: $deep_dive;
  text-align: center;
}
.modal_text_tiny {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  text-align: center;
}
.button_ok {
  min-width: 240px;
}
</style>
