<template>
  <td class="potok-table-cell">
    <slot />
  </td>
</template>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.potok-table-cell {
  background: transparent;
  padding: 24px 10px 16px 10px;
  border-top: 1px solid $dividers;
  white-space: nowrap;
  &:first-child {
    padding-left: 20px;
  }
}
</style>
