<template>
  <div class="mb-7">
    <div class="new-request-step-2-content">
      <div class="new-request-step-2-left">
        <NewInvestmentsNewRequestStep2Info
          :loading="isLoadingInvestmentsForSale"
          :total-debt-amount="totalDebtAmountWithDiscount"
          :discount="discount"
          :limit="limit"
          class="new-request-step-2-info-container"
          @handle-request="postRequest"
        />

        <div
          class="new-request-step-2-options-container-limit new-request-step-2-options-container-mobile step-2-options-input-container mt-7"
        >
          <div class="step-2-options-title mb-5">установить лимит</div>

          <InputMoney
            v-model="limitValue"
            :disabled="isLoadingInvestmentsForSale"
            placeholder="0"
            :vmaska="masks.digits(7)"
            class="step-2-options-limit-input potok-text-body-2"
          >
            <template #inner-icon>
              <div
                v-if="isShowClearButton && !isTablet"
                class="step-2-options-limit-input-clear"
                @click="limitValue = 0"
              >
                отменить лимит
              </div>
            </template>
          </InputMoney>
        </div>
      </div>
      <template v-if="!isLoadingFeaturesBuybacks">
        <!--подсказки в правом блоке для декстопа-->
        <NewInvestmentsNewRequestStep2Hints
          v-if="!isTablet"
          :hints="hintsModal"
          class="new-request-step-2-hints-container"
        />
        <!--кнопка вызова банера подсказок для мобилки-->
        <div v-if="isTablet" class="hints-modal-btn-container mt-5">
          <PotokButton
            size="link"
            theme="linkIcon"
            text="как это рассчитывается?"
            class="hints-modal-btn"
            @click="isShowHintsModal = true"
          >
            <template #icon-after>
              <PotokImg
                :src="ImageConstants.arrowRightDeepdive"
                height="10"
                width="10"
              />
            </template>
          </PotokButton>
        </div>
      </template>
    </div>

    <div class="new-request-step-2-info-buttons potok-text-body-1">
      <PotokButton
        :loading="isLoadingInvestmentsForSale"
        text="продать инвестиции"
        size="large"
        @click="postRequest"
      />
      <PotokButton
        class="new-request-step-2-info-buttons-cancel ml-2"
        theme="tertiary"
        size="large"
        :disabled="isLoadingRequest"
        text="назад"
        @click="back"
      />
    </div>

    <div class="new-request-step-2-info-description potok-text-body-2 mt-5">
      нажимая кнопку «продать инвестиции», я соглашаюсь с
      <span @click="openModalDescription">Условиями уступки</span>
    </div>

    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="isLoadingRequest"
      :confirm-function="postRequestConfirm"
      :request="postRequest"
      :error-message="confirmErrorMessage"
      :confirm-message="confirmMessage"
      title="подтверждение операции"
      @close="isShowModalConfirm = false"
    />

    <ModalFull
      v-if="isShowDescriptionModal"
      :title="titleForModal"
      @close="isShowDescriptionModal = false"
    >
      <template #content>
        <div v-html="textForModal" />
      </template>
    </ModalFull>

    <ModalFull v-if="isShowHintsModal" @close="isShowHintsModal = false">
      <template #content>
        <NewInvestmentsNewRequestStep2HintsContent :hints="hintsModal" />
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { InputMoney } from 'potok-uikit';

import { formatCurrency, pluralize } from '@/utils/commonUtils/libUtils';
import server from '@/server';
import masks from '@/utils/masks';
import { useStateStore } from '@/store/stateStore';
import ImageConstants from '@/constants/imageConstants';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import NewInvestmentsNewRequestStep2Hints from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep2/NewInvestmentsNewRequestStep2Hints.vue';
import NewInvestmentsNewRequestStep2Info from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep2/NewInvestmentsNewRequestStep2Info.vue';
import { useRouter } from 'vue-router';
import detectDevice from '@/composables/detectDeviceComposable';
import NewInvestmentsNewRequestStep2HintsContent from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep2/NewInvestmentsNewRequestStep2HintsContent.vue';
import { PotokButton } from 'potok-uikit';

const store = useStateStore();
const router = useRouter();
const { isTablet } = detectDevice();

const emits = defineEmits('back');

const totalDebtAmount = ref(0);
const investmentsListCount = ref(0);

const discount = ref(30);
const limit = ref(0);

const titleForModal = ref('');
const textForModal = ref('');

const hintsModal = ref(null);

const isShowModalConfirm = ref(false);
const confirmErrorMessage = ref(null);
const smsId = ref(null);

const isShowDescriptionModal = ref(false);
const isShowHintsModal = ref(false);

const limitValue = ref(0);
const isShowClearButton = ref(false);

const isLoadingRequest = ref(false);
const isLoadingInvestmentsForSale = ref(true);
const isLoadingFeaturesBuybacks = ref(true);

const totalDebtAmountWithDiscount = computed(() => {
  return totalDebtAmount.value * (1 - discount.value / 100);
});

const confirmMessage = computed(() => {
  return `подтвердите продажу ${investmentsListCount.value} ${pluralize(investmentsListCount.value, ['инвестиция', 'инвестиции', 'инвестиций'])} с дисконтом ${discount.value}% на сумму ${formatCurrency(totalDebtAmountWithDiscount.value, 0)} ₽ (расчет на дату подписания) — с лимитом ${formatCurrency(limit.value, 0)} ₽`;
});

const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const back = () => {
  emits('back');
};

const setLimit = (value) => {
  limit.value = value;
};

const getInvestmentsForSale = () => {
  isLoadingInvestmentsForSale.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };

  return server.getInvestmentsForSale
    .send(query)
    .pipe(onFetchInvestmentsForSaleSuccess, () => {
      isLoadingInvestmentsForSale.value = false;
    })
    .exec();
};

const onFetchInvestmentsForSaleSuccess = (obj) => {
  isLoadingInvestmentsForSale.value = false;
  totalDebtAmount.value = obj.data.totalMainDebtAmount;
  investmentsListCount.value = obj.data.investments.length;

  discount.value = obj.data.minDiscountPercentage;

  titleForModal.value = obj.data.assignmentTerms.fullDescriptionTitle;
  textForModal.value = obj.data.assignmentTerms.fullDescription;
};

const getFeaturesBuybacks = () => {
  isLoadingFeaturesBuybacks.value = true;

  return server.getFeaturesBuybacks
    .send()
    .pipe(onFetchGetFeaturesBuybacksSuccess, () => {
      isLoadingFeaturesBuybacks.value = false;
    })
    .exec();
};

const onFetchGetFeaturesBuybacksSuccess = (obj) => {
  isLoadingFeaturesBuybacks.value = false;
  hintsModal.value = obj.data.forSaleRequest;
};

const postRequest = () => {
  if (isLoadingRequest.value) {
    return;
  }

  isLoadingRequest.value = true;

  const query = {
    discountPercentage: discount.value,
  };
  if (limit.value) {
    query.buybackLimitAmount = limit.value;
  }

  return server.postInvestmentsSellRequest
    .send(query, {
      params: {
        companyId: store.selectedCompanyId,
      },
    })
    .pipe(onFetchPostRequestSuccess, () => {
      isLoadingRequest.value = false;
    })
    .exec();
};

const onFetchPostRequestSuccess = (obj) => {
  smsId.value = obj.data.smsId;
  isLoadingRequest.value = false;
  isShowModalConfirm.value = true;
};

const postRequestConfirm = ({ code }) => {
  confirmErrorMessage.value = null;

  const query = {
    code,
    smsId: smsId.value,
  };
  isLoadingRequest.value = true;

  return server.postInvestmentsSellRequestConfirm
    .send(query, {
      params: {
        companyId: store.selectedCompanyId,
      },
    })
    .pipe(onPostRequestConfirmSuccess, onPostRequestConfirmError)
    .exec();
};
const onPostRequestConfirmSuccess = (obj) => {
  isLoadingRequest.value = false;
  if (obj.data.isSuccessful) {
    store.state.modals.isShowNewSaleInvestmentsModal = true;
    router.push({
      name: 'newInvestmentsSellList',
    });
  } else if (obj.data.error) {
    confirmErrorMessage.value = obj.data.error;
  } else {
    confirmErrorMessage.value =
      'Неизвестная ошибка. Обратитесь в тех. поддержку';
  }
};
const onPostRequestConfirmError = () => {
  isLoadingRequest.value = false;
};

const openModalDescription = () => {
  isShowDescriptionModal.value = true;
};

watch(selectedCompany, () => {
  getInvestmentsForSale();
});

watch(limitValue, (value) => {
  const numberedValue = Number(value);
  isShowClearButton.value = !!numberedValue;
  setLimit(numberedValue);
});

const init = () => {
  getInvestmentsForSale();
  getFeaturesBuybacks();
};
init();
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;
@use '../../../scss/typography.scss' as *;

.new-request-step-2-content {
  display: flex;

  @media (max-width: $size_991) {
    flex-direction: column;
  }
}

.new-request-step-2-left {
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

.new-request-step-2-hints-container {
  width: 50%;
  margin-left: 30px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

.new-request-step-2-info-container {
  width: 100%;
  margin-right: 30px;

  @media (max-width: $size_991) {
    width: 100%;
    margin-right: 0;
  }
}

.new-request-step-2-info-description {
  text-transform: initial;
  color: $grey;

  span {
    cursor: pointer;
    color: $breakwater;
  }
}

.new-request-step-2-info-buttons-cancel {
  @media (max-width: $size_991) {
    margin-left: 0;
    margin-top: 16px;
  }
}

.new-request-step-2-info-buttons {
  display: flex;
  margin-top: 30px;

  @media (max-width: $size_991) {
    margin-top: 20px;
    flex-direction: column;
  }
}

.new-request-step-2-options-container-limit {
  position: relative;
  padding: 20px;
  width: 100%;
  border-radius: 14px;
  background-color: $white;

  @media (max-width: $size_991) {
    margin-top: 20px;
    padding: 20px 14px 14px 14px;
  }
}

.new-request-step-2-options-container-mobile {
  @media (max-width: $size_991) {
    margin-top: 20px;
  }
}

.step-2-options-input-container {
  :deep(.form-input-money-input) {
    @media (max-width: $size_991) {
      border: 1px solid $input;
      padding: 2px 10px;
      height: 44px;
    }
  }

  :deep(.new-request-step-2-options-container-icon-mobile) {
    top: 62px;
  }
}

.step-2-options-title {
  color: $grey;

  @media (max-width: $size_991) {
    @include potok-text-uppercase;
    margin-bottom: 8px;
  }
}

.step-2-options-limit-input-clear {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: $grey;
}

.hints-modal-btn-container {
  padding: 18px 14px;
  border-radius: 14px;
  background: $white;
}

:deep(.hints-modal-btn .potok-button-content) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
