/* eslint-disable */
import YandexMetrikaTracker from './YandexMetrikaTracker';
// import GoogleAnalyticsTracker from './GoogleAnalyticsTracker'
import AmplitudeTracker from './AmplitudeTracker';

export default class Tracker {
  constructor({
    ymtOptionsLKI,
    ymtOptionsLKZ,
    gatOptions,
    amplOptions,
    isProduction,
  }) {
    this.ymtLKI = ymtOptionsLKI
      ? new YandexMetrikaTracker(ymtOptionsLKI, isProduction)
      : null;
    this.ymtLKZ = ymtOptionsLKZ
      ? new YandexMetrikaTracker(ymtOptionsLKZ, isProduction)
      : null;

    // this.gat = gatOptions
    //   ? new GoogleAnalyticsTracker(gatOptions, isProduction)
    //   : null

    this.ampl = amplOptions
      ? new AmplitudeTracker(amplOptions, isProduction)
      : null;

    this.commands = {
      SEND: Symbol('SEND'),
      SET: Symbol('SET'),
      PARAMS: Symbol('PARAMS'),
    };
  }
  /**
   * Queues a command
   * @param {String} command
   * @param {...*} [opts]
   */
  queue(command, ...opts) {
    switch (command) {
      case this.commands.SET:
        this.ymtLKI && this.ymtLKI.queue('hit', ...opts);
        this.ymtLKZ && this.ymtLKZ.queue('hit', ...opts);
        break;
      case this.commands.SEND:
        this.ymtLKI && this.ymtLKI.queue('reachGoal', ...opts);
        this.ymtLKZ && this.ymtLKZ.queue('reachGoal', ...opts);
        this.ampl && this.ampl.queue('logEvent', opts[0]);
        break;
      case this.commands.PARAMS:
        this.ymtLKI && this.ymtLKI.queue('params', ...opts);
        this.ymtLKZ && this.ymtLKZ.queue('params', ...opts);
        this.ampl && this.ampl.queue('setUserId', opts[0].userId);
        break;
      default:
        throw new Error('Unsupported command');
    }
  }
}
