<template>
  <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col_item dashboard_block">
      <div class="dashboard_block_content block_content">
        <template v-if="props.loadingBalance">
          <div class="balances-item col-md-6">
            <div class="property" style="margin-top: 15px">
              <Loader />
            </div>
          </div>
        </template>
        <template v-if="!props.loadingBalance">
          <DashboardBalancesActive @deposit-money="emit('depositMoney')" />
        </template>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col_item dashboard_block">
      <div class="dashboard_block_content block_content" data-tg-id="3">
        <DashboardBalancesMoney
          @deposit-money="emit('depositMoney')"
          @show-cashbox-setting="emit('showCashboxSetting')"
        />
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col_item dashboard_block">
      <div class="dashboard_block_content block_content" data-tg-id="2">
        <DashboardBalancesStrategy />
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col_item dashboard_block">
      <div class="dashboard_block_content block_content">
        <DashboardBalancesResult :loading="loadingResult" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { Loader } from 'potok-uikit';
import DashboardBalancesMoney from '../DashboardBalancesMoney.vue';
import DashboardBalancesActive from '../DashboardBalancesActive.vue';
import DashboardBalancesResult from '../DashboardBalancesResult.vue';
import DashboardBalancesStrategy from '../DashboardBalancesStrategy.vue';

const emit = defineEmits(['depositMoney', 'showCashboxSetting']);
const props = defineProps(['loadingBalance', 'loadingResult']);
</script>
<style lang="scss" scoped>
.dashboard_block_content {
  height: 396px;
}

.col_item {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 14px;
  padding-left: 15px;
}
</style>
