import { pluralize } from '@/utils/commonUtils/utils';
import constants from '@/constants';

export const paymentFreqMobile = function (loanPaymentFreq) {
  return loanPaymentFreq
    ? '— каждые ' +
        loanPaymentFreq +
        ' ' +
        pluralize(loanPaymentFreq, ['день', 'дня', 'дней'])
    : '';
};
export const paymentFreq = function (loanPaymentFreq) {
  return loanPaymentFreq
    ? 'каждые ' +
        loanPaymentFreq +
        ' ' +
        pluralize(loanPaymentFreq, ['день', 'дня', 'дней'])
    : '';
};

export const showPlus = (value) => {
  let v = parseInt(value) || null;
  if (v) {
    return '<span>+</span>';
  }
  return '';
};

const isBuybackInvestment = (item) =>
  item.purchaseDeals &&
  item.purchaseDeals.length > 0 &&
  item.purchaseDeals[0].type ===
    constants.investmentPurchaseDealTypes.withFutureBuyback;

export const contentInvestmentDesktop = (item) => {
  let content = `
    <div>
      <a href="/loans/${item.projectId}" style="color: #04CCD9; font-size: 14px; line-height: 16px">
        ${item.borrowerShortName}
      </a>
    </div>
    <div class="" style="color: #999999">
      инв.
      <a href="/investment/${item.id}" style="color: #04CCD9; font-size: 14px; line-height: 16px">
        ${item.number}
      </a>
      в заём
      <a href="/loans/${item.projectId}" style="color: #04CCD9; font-size: 14px; line-height: 16px">
        ${item.projectNumber}
      </a>
    </div>
  `;
  if (isBuybackInvestment(item)) {
    content += `<p style="color: #999999">с обратным выкупом</p>`;
  }
  content = `<div class="tableitems__investment">${content} </div>`;
  return content;
};

export const contentInvestmentMobile = (item) => {
  let content = `
    <div>
      <a href="/loans/${item.projectId}" style="color: #04CCD9; font-size: 14px; line-height: 16px">
        ${item.borrowerShortName}
      </a>
    </div>
    <div style="color: #999999">
      инв.
      <a href="/investment/${item.id}" style="color: #04CCD9; font-size: 14px; line-height: 16px">
        ${item.number}
      </a>
      в заём
      <a href="/loans/${item.projectId}" style="color: #04CCD9; font-size: 14px; line-height: 16px">
        ${item.projectNumber}
      </a>
    </div>
  `;
  if (isBuybackInvestment(item)) {
    content += `<p style="color: #999999">с обратным выкупом</p>`;
  }
  content = `<div>${content}</div>`;
  return content;
};
