import Countdown from '@/utils/classes/Countdown';
import { ref, onMounted } from 'vue';

export default function countdownComposable() {
  const countdown = new Countdown(new Date(), 60, 0);
  const leftTime = ref(0);

  const countdownReset = () => {
    countdown.stop();
    countdown.reset();
    countdown.start();
  };

  onMounted(() => {
    countdown.onTick = (val) => {
      leftTime.value = val;
    };
  });

  return {
    leftTime,
    countdown,
    countdownReset,
  };
}
