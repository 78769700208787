<template>
  <section>
    <PotokMessage>
      <div>
        <div class="alert-title">Загрузите паспорт</div>
        <div>
          <div>
            <div>
              Загрузите паспорт, иначе возможность инвестирования будет
              недоступна.
            </div>
          </div>
          <div style="padding-top: 0; margin-top: 10px">
            <button
              type="button"
              class="button button-secondary"
              @click="goToFillPassports()"
            >
              Загрузить паспорт
            </button>
          </div>
        </div>
      </div>
    </PotokMessage>
  </section>
</template>
<script setup>
import PotokMessage from '@/components/_generic/PotokMessage.vue';

const emits = defineEmits(['openRejectedPassport']);

const goToFillPassports = () => {
  emits('openRejectedPassport');
};
</script>
