import constants from '@/constants';

const titleGuard = (to) => {
  // Берем тайтл и меты, и присваиваем документ нужный. Если в мете его нет - ставим дефолтный
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + constants.defaultTitlePostfix;
  } else if (document.title !== constants.defaultTitle) {
    document.title = constants.defaultTitle;
  }
};

export default titleGuard;
