<template>
  <div class="assets-returns">
    <div class="assets-returns-head mb-5">
      <h2 class="potok-text-body-1-bold my-0">структура активов</h2>
    </div>

    <AnalyticsLoadingContainer
      :loading="props.loading"
      loading-text="пожалуйста, подождите, загружаем данные"
      help-mobile-title="структура активов"
    >
      <div class="chart-wrapper">
        <div class="chart-wrapper-desc">
          <div>
            <div class="item-title">
              {{ props.data?.portfolio?.label }}
            </div>
            <div class="item-row">
              <div class="item-row-tag-funded" />
              <div class="item-row-title">
                {{ props.data?.portfolio?.funded?.label }}
              </div>
              <div class="item-row-value">
                &nbsp;—&nbsp;{{
                  formatCurrency(props.data?.portfolio?.funded?.value, 0)
                }}&nbsp;₽
              </div>
            </div>
            <div class="item-row">
              <div class="item-row-tag-overdue" />
              <div class="item-row-title">
                {{ props.data?.portfolio?.overdue?.label }}
              </div>
              <div class="item-row-value">
                &nbsp;—&nbsp;{{
                  formatCurrency(props.data?.portfolio?.overdue?.value, 0)
                }}&nbsp;₽
              </div>
            </div>
            <div class="item-row">
              <div class="item-row-tag-restructured" />
              <div class="item-row-title">
                {{ props.data?.portfolio?.restructured?.label }}
              </div>
              <div class="item-row-value">
                &nbsp;—&nbsp;{{
                  formatCurrency(props.data?.portfolio?.restructured?.value, 0)
                }}&nbsp;₽
              </div>
            </div>
            <div class="item-row-divider" />
            <div class="item-title">
              {{ props.data?.funds?.label }}
            </div>
            <div class="item-row">
              <div class="item-row-tag-total" />
              <div class="item-row-title">всего</div>
              <div class="item-row-value">
                &nbsp;—&nbsp;{{
                  formatCurrency(props.data?.funds?.value, 0)
                }}&nbsp;₽
              </div>
            </div>
            <div class="item-row-funds">
              <div class="item-row-funds-item">
                <div class="item-row-funds-item-title">
                  {{ props.data?.funds?.freeWithoutCashbox?.label }}
                </div>
                <div class="item-row-funds-item-value">
                  &nbsp;—&nbsp;{{
                    formatCurrency(
                      props.data?.funds?.freeWithoutCashbox?.value,
                      0,
                    )
                  }}&nbsp;₽
                </div>
              </div>
              <div class="item-row-funds-item">
                <div class="item-row-funds-item-title">
                  {{ props.data?.funds?.reserved?.label }}
                </div>
                <div class="item-row-funds-item-value">
                  &nbsp;—&nbsp;{{
                    formatCurrency(props.data?.funds?.reserved?.value, 0)
                  }}&nbsp;₽
                </div>
              </div>
              <div class="item-row-funds-item">
                <div class="item-row-funds-item-title">
                  {{ props.data?.funds?.cashboxAmount?.label }}
                </div>
                <div class="item-row-funds-item-value">
                  &nbsp;—&nbsp;{{
                    formatCurrency(props.data?.funds?.cashboxAmount?.value, 0)
                  }}&nbsp;₽
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="doughnut-wrapper">
          <Doughnut
            v-if="isShowGraph"
            :data="dataChart"
            :options="options"
            class="doughnut"
          />
          <div class="doughnut-desc">
            <div class="doughnut-desc-title">
              {{ props.data?.portfolio?.label }}
            </div>
            <div class="doughnut-desc-value mt-2">
              {{ formatCurrency(totalSum, 0) }} ₽
            </div>
            <div class="doughnut-desc-title mt-2">100%</div>
          </div>
        </div>
      </div>
    </AnalyticsLoadingContainer>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { Doughnut } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';

import { formatCurrency } from '@/utils/commonUtils/utils';
import AnalyticsLoadingContainer from './AnalyticsLoadingContainer.vue';

Chart.register(...registerables);

const props = defineProps({
  loading: Boolean,
  data: {
    type: Object,
  },
});

const options = ref({
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    tooltip: {
      callbacks: {
        label(context) {
          const label = context.dataset.labels[context.dataIndex] || '';
          if (context.parsed !== null) {
            return `${label} ${context.parsed}%`;
          }
          return label;
        },
      },
    },
    legend: {
      display: false,
    },
  },
});

const isShowGraph = ref(true);

const totalSum = computed(() => {
  return props.data?.portfolio?.value + props.data?.funds?.value;
});

const items = computed(() => {
  if (!props.data) {
    return [];
  }

  return [
    props.data?.portfolio?.funded?.percentage,
    props.data?.portfolio?.overdue?.percentage,
    props.data?.portfolio?.restructured?.percentage,
    props.data?.funds?.percentage,
  ];
});

const itemsLabel = computed(() => {
  if (!props.data) {
    return [];
  }

  return [
    props.data?.portfolio?.funded?.label,
    props.data?.portfolio?.overdue?.label,
    props.data?.portfolio?.restructured?.label,
    props.data?.funds?.label,
  ];
});

const dataChart = {
  labels: [],
  datasets: [
    {
      label: [],
      labels: [],
      data: [],
      backgroundColor: ['#173B57', '#EE3D48', '#FFC11E', '#04CCD9'],
      cutout: '90%',
    },
  ],
};

const chartUpdate = () => {
  isShowGraph.value = false;
  dataChart.datasets[0].data = items.value;
  dataChart.datasets[0].labels = itemsLabel.value;
  nextTick(() => {
    isShowGraph.value = true;
  });
};

watch(
  () => {
    return items.value;
  },
  () => {
    chartUpdate();
  },
);

chartUpdate();
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.chart-wrapper {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $size_767) {
    flex-direction: column-reverse;
  }
}

.chart-wrapper-desc {
  flex: 1;
}

.doughnut {
  max-height: 224px;
  max-width: 224px;
}

.item-title {
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: $deep_dive;
  margin: 10px 0;
}

.item-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 4px 0;
}

.item-row-divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid $dividers;
  margin: 20px 0;
}

.item-row-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
  word-break: break-all;
}

.item-row-value {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: lowercase;
  color: $deep_dive;
}

.item-row-tag-funded {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $breakwater;
  margin-right: 5px;
  margin-top: 3px;
}

.item-row-tag-overdue {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $red;
  margin-right: 5px;
  margin-top: 3px;
}

.item-row-tag-restructured {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffc11e;
  margin-right: 5px;
  margin-top: 3px;
}

.item-row-tag-total {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $tiffany;
  margin-right: 5px;
  margin-top: 3px;
}

.item-row-funds {
  display: flex;
  flex-wrap: wrap;
}

.item-row-funds-item {
  background-color: $background;
  padding: 4px 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-top: 8px;
}

.item-row-funds-item-title {
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: $grey;
}

.item-row-funds-item-value {
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: $breakwater;
}

.doughnut-wrapper {
  position: relative;
  max-width: 224px;
  margin: auto;
}

.doughnut-desc {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  text-align: center;
}

.doughnut-desc-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
}

.doughnut-desc-value {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: $deep_dive;
}
</style>
