<template>
  <div
    v-on-clickaway="onClickaway"
    class="accountmenu"
    @click.prevent="onClickToggleMenu"
  >
    <div
      :class="{ header__menulist__show: isShowAccountList }"
      class="accountmenu__button"
    >
      <span v-if="modelValue" class="accountmenu__text">
        {{ getAccountName(modelValue) }}
      </span>
      <span v-if="!modelValue" class="accountmenu__placeholder">
        {{ props.placeholder }}
      </span>
      <i
        :class="{
          accountmenu__arrow_up: isShowAccountList,
          accountmenu__arrow_down: !isShowAccountList,
        }"
      ></i>
    </div>
    <div v-if="isShowAccountList" class="accountmenu__list">
      <div class="header_scroll_menu">
        <div
          v-for="(item, index) in props.items"
          :key="item.id"
          class="accountmenu__item_wrapper"
          @click.prevent.stop="onClickChooseCompany(item)"
          @touchstart.prevent.stop="onClickChooseCompany(item)"
        >
          <div class="accountmenu__item hover_accountmenu">
            <div>
              <div class="accountmenu_item_lk">
                ЛС {{ item.investorAccountNumber }}
              </div>
              <div class="accountmenu_item_name">
                {{ getShortAccountName(item) }}
              </div>
              <div class="accountmenu_item_balance">
                {{ formatCurrency(item.totalBalance, 0) }} ₽
              </div>
            </div>
          </div>
          <div
            v-if="index < props.items.length - 1"
            style="border-top: 1px solid #ebedf4"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { directive as vOnClickaway } from 'vue3-click-away';

import { formatCurrency } from '@/utils/commonUtils/utils';
import headerComposable from '@/composables/headerComposable';

const { getAccountName, getShortAccountName } = headerComposable();

const props = defineProps({
  items: Array,
  placeholder: String,
  modelValue: Object,
});
const emit = defineEmits(['update:modelValue', 'change']);
const isShowAccountList = ref(false);

const onClickChooseCompany = (item) => {
  isShowAccountList.value = false;
  emit('update:modelValue', item);
  emit('change');
};

const onClickToggleMenu = () => {
  isShowAccountList.value = !isShowAccountList.value;
};

const onClickaway = () => {
  isShowAccountList.value = false;
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.accountmenu__button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f4f8;
  border-radius: 8px;
  padding: 18px 20px;
  min-height: 60px;
  position: relative;
}
.accountmenu__text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
}
.accountmenu__placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $grey;
}
.accountmenu__arrow_down {
  width: 12px;
  height: 12px;
  background: url('@img/angle-down.svg') no-repeat;
  background-size: contain;
}

.accountmenu__arrow_up {
  width: 12px;
  height: 12px;
  background: url('@img/angle-down.svg') no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}
.accountmenu__list {
  padding: 20px 10px;
  position: absolute;
  top: 120px;
  left: 15px;
  z-index: 100;
  width: 96%;
  margin-bottom: 0;
  list-style: none;
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 12px;
}

.accountmenu__item {
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
}
.hover_accountmenu {
  &:hover {
    background: #dedee04d;
  }
}

.accountmenu_item_lk {
  padding: 0;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
}
.accountmenu_item_name {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
  padding: 4px 0;
}
.accountmenu_item_balance {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  margin-top: 7px;
}
</style>
