<template>
  <section style="margin-top: 10px">
    <AnalyticsHistoryOperationFilterDesktop
      v-if="!isMobile"
      :date-since="props.dateSince"
      :date-to="props.dateTo"
      :type="props.type"
      :investment-type="props.investmentType"
      :loading="loading"
      @get-account-statement="getAccountStatement"
      @change-date-range="changeDateRange"
      @change-type="changeType"
      @change-investment-type="changeInvestmentType"
    />
    <AnalyticsHistoryOperationFilterMobile
      v-if="isMobile"
      :date-since="props.dateSince"
      :date-to="props.dateTo"
      :type="props.type"
      :investment-type="props.investmentType"
      :loading="loading"
      @get-account-statement="getAccountStatement"
      @change-date-range="changeDateRange"
      @change-type="changeType"
      @change-investment-type="changeInvestmentType"
      @reset-filters="resetFilters"
    />
  </section>
</template>
<script setup>
import detectDevice from '@/composables/detectDeviceComposable';

import AnalyticsHistoryOperationFilterDesktop from './AnalyticsHistoryOperationFilter/AnalyticsHistoryOperationFilterContainers/AnalyticsHistoryOpeartionFilterDesktop.vue';
import AnalyticsHistoryOperationFilterMobile from './AnalyticsHistoryOperationFilter/AnalyticsHistoryOperationFilterContainers/AnalyticsHistoryOperationFilterMobile.vue';

const { isMobile } = detectDevice();

const props = defineProps({
  dateSince: Object,
  dateTo: Object,
  type: String,
  investmentType: String,
  loading: Boolean,
});
const emit = defineEmits([
  'getAccountStatement',
  'changeDateRange',
  'changeType',
  'changeInvestmentType',
  'resetFilters',
]);

const getAccountStatement = () => {
  emit('getAccountStatement');
};
const changeDateRange = (dateRange) => {
  emit('changeDateRange', dateRange);
};
const changeType = (type) => {
  emit('changeType', type);
};
const changeInvestmentType = (investmentType) => {
  emit('changeInvestmentType', investmentType);
};
const resetFilters = () => {
  emit('resetFilters');
};
</script>
<style lang="scss" scoped></style>
