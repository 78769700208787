import { parseISO, isSameDay } from 'date-fns';

const filterItemsRefunded = (items, filters) => {
  let filteredItems = JSON.parse(JSON.stringify(items));
  for (let field in filters) {
    if (filters[field]) {
      if (field === 'search') {
        const term = filters[field].toLowerCase();
        filteredItems = filteredItems.filter((i) => {
          return (
            i.borrowerShortName.toLowerCase().includes(term) ||
            i.projectNumber.toLowerCase().includes(term)
          );
        });
      }
      if (field === 'purchaseDeals') {
        filteredItems = filteredItems.filter((i) => {
          const isBuyback =
            i.purchaseDeals && i.purchaseDeals.length > 0 ? true : false;
          const loanRate = isBuyback
            ? i.purchaseDeals[0].purchasedInvestmentRate
            : i.loanRate * 100;
          return parseInt(loanRate) == parseInt(filters[field]);
        });
      }
      if (field === 'loanFundedAt') {
        filteredItems = filteredItems.filter((i) => {
          return isSameDay(parseISO(i.loanFundedAt), filters[field]);
        });
      }
      if (field === 'loanRefundedAt') {
        filteredItems = filteredItems.filter((i) => {
          return isSameDay(parseISO(i.loanRefundedAt), filters[field]);
        });
      }
      if (field === 'myInvestmentAmount') {
        filteredItems = filteredItems.filter((i) => {
          return parseInt(i.myInvestmentAmount) == parseInt(filters[field]);
        });
      }
      if (field === 'myInvestmentRepayment') {
        filteredItems = filteredItems.filter((i) => {
          return parseInt(i.myInvestmentRepayment) == parseInt(filters[field]);
        });
      }
      if (field === 'myInvestmentIncome') {
        filteredItems = filteredItems.filter((i) => {
          return parseInt(i.myInvestmentIncome) == parseInt(filters[field]);
        });
      }
    }
  }
  return filteredItems;
};

export default filterItemsRefunded;
