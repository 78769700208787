import { computed } from 'vue';
import { useStateStore } from '@/store/stateStore';
import { useRouter } from 'vue-router';

import constants from '@/constants';

export default function headerComposable() {
  const store = useStateStore();
  const router = useRouter();

  const selectedCompany = computed(() => {
    return store.selectedCompany;
  });

  const firstIncomeAt = computed(() => {
    return store.state.user.firstIncomeAt;
  });

  const isHasCompaniesRoot = computed(() => {
    return !!store.companiesRoot.length > 0;
  });

  const companySelectedShortName = computed(() => {
    if (!selectedCompany.value || !selectedCompany.value.fullName) {
      return 'П';
    }
    return companyShortName(selectedCompany.value);
  });

  const companyShortName = (companyName) => {
    if (!companyName || !companyName.fullName) {
      return 'П';
    }
    return companyName.abbreviatedName;
  };

  const addInvestor = () => {
    if (!firstIncomeAt.value) {
      return store.pushAlert({
        message: constants.messages.finishReg,
        title: 'Внимание!',
      });
    }
    router.push({
      name: 'investorAdd',
    });
  };

  const getName = (item) => {
    let autoInvestTitle = 'автоинвест выключен';
    if (item.autoinvestSettings) {
      if (constants.strategesTitle[item.autoinvestSettings.strategy]) {
        autoInvestTitle =
          constants.strategesTitle[item.autoinvestSettings.strategy];
      }
    }

    return autoInvestTitle;
  };
  const getAccountName = (item) => {
    if (!item) {
      return;
    }
    const autoInvestTitle = getName(item);

    let lk = '';
    if (item.investorAccountNumber) {
      lk = item.investorAccountNumber;
    }
    return `ЛС ${lk} — ${autoInvestTitle}`;
  };

  const getShortAccountName = (item) => {
    return getName(item);
  };

  return {
    companySelectedShortName,
    isHasCompaniesRoot,
    companyShortName,
    addInvestor,
    getAccountName,
    getShortAccountName,
  };
}
