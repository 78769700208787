import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { STATUSES } from '@/constants/Investments/investmentsConstants';

export default function investmentsLoadComposable() {
  const route = useRoute();
  const store = useStateStore();

  const activeStatus = ref(null);
  const items = reactive({
    funded: [],
    refunded: [],
    default: [],
    restructured: [],
    softOverdue: [],
    sold: [],
    buyback_current: [],
  });

  const projectsCount = reactive({
    funded: 0,
    refunded: 0,
    default: 0,
    restructured: 0,
    softOverdue: 0,
    sold: 0,
    investmentsTotalAmount: 0,
  });

  const loadingCompanyInvestments = ref(false);
  const loadingProjectsCount = ref(false);

  const isLoading = computed(() => {
    return loadingProjectsCount.value || loadingCompanyInvestments.value;
  });

  const selectedCompanyId = computed(() => {
    return store.selectedCompanyId;
  });
  const companies = computed(() => {
    return store.companies;
  });

  const getCompanyInvestmentsAsync = () => {
    if (!selectedCompanyId.value) {
      return;
    }
    let query = {};
    loadingCompanyInvestments.value = true;

    let pipeline = server.getCompanyInvestments;

    if (activeStatus.value === STATUSES.buyback_current) {
      pipeline = server.getInvestmentsSold;
    } else if (activeStatus.value === STATUSES.sold) {
      query.projectStatusKey = 'total';
      query.showOnlyBoughtBack = true;
    } else {
      query.projectStatusKey = activeStatus.value;
    }

    pipeline
      .send(query, {
        params: { id: selectedCompanyId.value },
      })
      .pipe(onGetCompanyInvestmentsAsyncSuccess.bind(this), () => {
        loadingCompanyInvestments.value = false;
      })
      .exec();
  };
  const onGetCompanyInvestmentsAsyncSuccess = (obj) => {
    loadingCompanyInvestments.value = false;
    if (obj && obj.response && obj.response.status === 200) {
      items[activeStatus.value] = obj.data;
    }
  };

  const getAllProjectsCount = () => {
    loadingProjectsCount.value = true;
    const query = {};

    if (selectedCompanyId.value) {
      query.companyId = selectedCompanyId.value;
    }

    return server.getProjectsMyInvestmentsCountAll
      .send(query)
      .pipe(onGetAllProjectsCountAsyncSuccess.bind(this), () => {
        loadingProjectsCount.value = false;
      })
      .exec();
  };

  const onGetAllProjectsCountAsyncSuccess = ({ data }) => {
    loadingProjectsCount.value = false;
    for (const key in data) {
      projectsCount[key] = data[key];
    }
    if (!activeStatus.value || projectsCount[activeStatus.value] === 0) {
      activeStatus.value = getDefaultActiveStatus();
    }
  };

  const areThereAnyProjects = computed(() => {
    const sum = Object.keys(projectsCount).reduce((acc, count) => {
      return acc + projectsCount[count];
    }, 0);

    return sum > 0;
  });

  const getDefaultActiveStatus = () => {
    if (
      route.query &&
      route.query.status &&
      projectsCount[route.query.status] != undefined
    ) {
      if (projectsCount[route.query.status] > 0) {
        return route.query.status;
      }
    }
    const firstElement = Object.entries(projectsCount).find((i) => i[1] > 0);
    if (firstElement) {
      return firstElement[0];
    }
  };

  const getItemsByStatus = ({ items, status }) => {
    return {
      items: STATUSES[status] ? items[status] : [],
      status: status,
    };
  };

  watch(selectedCompanyId, () => {
    getAllProjectsCount();
    getCompanyInvestmentsAsync();
  });
  watch(activeStatus, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      getCompanyInvestmentsAsync();
    }
  });
  watch(companies, () => {
    getAllProjectsCount();
  });

  onMounted(() => {
    getAllProjectsCount();
  });

  return {
    items,
    activeStatus,
    projectsCount,
    isLoading,
    areThereAnyProjects,
    getItemsByStatus,
  };
}
