import storage from '@/utils/localStorage/storage';

export const saveProjects = (projects) => {
  /**
   * Сохраняем займы в хранилище
   * делается в основном для удаления из хранилища ранее просмотреных
   * займов и уже не приходящих от сервера
   */

  let oldProject = storage.getProjects();
  if (oldProject) {
    oldProject = JSON.parse(oldProject);
  } else {
    oldProject = {};
  }
  const items = {};
  projects.value.forEach((project) => {
    if (oldProject[project.id] != undefined) {
      items[project.id] = oldProject[project.id];
    } else {
      items[project.id] = false;
    }
  });
  storage.setProjects(items);
};

export const isViewedItem = (projectId) => {
  /**
   * Отображаем иконку просмотреный займ,
   * по данным из хранилища
   */
  let items = storage.getProjects();
  if (items) {
    items = JSON.parse(items);
  } else {
    items = {};
  }
  return items[projectId];
};
