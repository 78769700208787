<template>
  <div>
    <div v-if="props.companiesRoot.length">
      <div class="header_scroll_menu">
        <div
          v-for="item in companiesRoot"
          :key="item.id"
          class="header__menulist__item hover_header_item"
          @click.prevent.stop="onClickChooseCompany(item)"
          @touchstart.stop
          @touchmove.stop
          @touchcancel.stop
        >
          <div
            :class="{
              company_list_item_selected: isSelectedCompany(item),
            }"
            style="display: flex; align-items: center"
          >
            <div
              class="header__menulist__item_button"
              style="margin-right: 10px"
            >
              {{ companyShortName(item) }}
            </div>
            {{ item.shortName }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isUserRegistrated"
      class="header__menulist__item hover_header_item"
      style="padding: 0"
    >
      <div
        href="#"
        class="link_btn"
        style="color: #10273d; width: 100%; display: flex; align-items: center"
        @click.prevent.stop="onClickAddInvestor()"
        @touchstart.prevent.stop="onClickAddInvestor()"
      >
        <i class="icon_circle_plus" style="margin-right: 9px"></i>
        добавить инвестора
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';

import { useStateStore } from '@/store/stateStore';
import headerComposable from '@/composables/headerComposable';

const store = useStateStore();

const props = defineProps(['companiesRoot']);
const emit = defineEmits(['chooseCompanyById', 'addInvestor']);

const { companyShortName } = headerComposable();

const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});

const onClickChooseCompany = (item) => {
  emit('chooseCompanyById', item);
};
const onClickAddInvestor = () => {
  emit('addInvestor');
};

const isSelectedCompany = (item) => {
  if (!selectedCompany.value || !selectedCompany.value) {
    return false;
  }
  if (!selectedCompany.value.parentId && selectedCompany.value.id === item.id) {
    return true;
  }
  if (
    selectedCompany.value.parentId &&
    selectedCompany.value.parentId === item.id
  ) {
    return true;
  }
  return false;
};
</script>
<style lang="scss" scoped>
.company_list_item_selected {
  color: #04ccd9;
  .header__menulist__item_button {
    color: #04ccd9;
    background-color: rgba(4, 204, 217, 0.1);
  }
}
</style>
