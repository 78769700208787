import tracker from '@/tracker';

import {
  ColumnsDefault,
  ColumnsTotalDefault,
} from '@/utils/investments/investmentsUtils/columns_default';
import {
  ColumnsFunded,
  ColumnsTotalFunds,
} from '@/utils/investments/investmentsUtils/columns_funded';
import {
  ColumnsRefunded,
  ColumnsTotalRefunded,
} from '@/utils/investments/investmentsUtils/columns_refunded';
import {
  ColumnsRestructured,
  ColumnsTotalRestructured,
} from '@/utils/investments/investmentsUtils/columns_restructured';
import {
  ColumnsOverdue,
  ColumnsTotalOverdue,
} from '@/utils/investments/investmentsUtils/columns_overdue';
import {
  ColumnsBuybackCurrent,
  ColumnsTotalBuybackCurrent,
} from '@/utils/investments/investmentsUtils/columns_buyback_current';
import {
  ColumnsBoughtBack,
  ColumnsTotalBoughtBack,
} from '@/utils/investments/investmentsUtils/columns_sold';

import { STATUSES } from '../../constants/Investments/investmentsConstants';

export default function investmentsUtils() {
  const emitTrackerEvent = ({ name }) => {
    switch (name) {
      case STATUSES.funded:
        tracker.queue(
          tracker.commands.SEND,
          'Investor_LK_My_Investments_Current',
        );
        break;
      case STATUSES.refunded:
        tracker.queue(
          tracker.commands.SEND,
          'Investor_LK_My_Investments_Returned',
        );
        break;
      case STATUSES.restructured:
        tracker.queue(
          tracker.commands.SEND,
          'Investor_LK_My_Investments_Restructed',
        );
        break;
      case STATUSES.default:
        tracker.queue(
          tracker.commands.SEND,
          'Investor_LK_My_Investments_Default',
        );
        break;
      case STATUSES.softOverdue:
        tracker.queue(
          tracker.commands.SEND,
          'Investor_LK_My_Investments_SoftOverdue',
        );
        break;
    }
  };

  const columns = ({ status }) => {
    switch (status) {
      case STATUSES.funded:
        return ColumnsFunded;
      case STATUSES.default:
        return ColumnsDefault;
      case STATUSES.restructured:
        return ColumnsRestructured;
      case STATUSES.softOverdue:
        return ColumnsOverdue;
      case STATUSES.refunded:
        return ColumnsRefunded;
      case STATUSES.sold:
        return ColumnsBoughtBack;
      case STATUSES.buyback_current:
        return ColumnsBuybackCurrent;
    }
    return [];
  };

  const totalColumns = ({ items, status }) => {
    switch (status) {
      case STATUSES.funded:
        return ColumnsTotalFunds(items);
      case STATUSES.default:
        return ColumnsTotalDefault(items);
      case STATUSES.restructured:
        return ColumnsTotalRestructured(items);
      case STATUSES.softOverdue:
        return ColumnsTotalOverdue(items);
      case STATUSES.refunded:
        return ColumnsTotalRefunded(items);
      case STATUSES.sold:
        return ColumnsTotalBoughtBack(items);
      case STATUSES.buyback_current:
        return ColumnsTotalBuybackCurrent(items);
    }
    return [];
  };

  return {
    emitTrackerEvent,
    columns,
    totalColumns,
  };
}
