<template>
  <div class="tableitems__wrapper">
    <div
      v-for="(item, index) in props.items"
      :key="index"
      class="tableitems__item"
    >
      <div class="tableitems__item__element">
        <div class="tableitems__item__element_label">Дата</div>
        <div class="tableitems__item__element_value">
          {{ formatDate(item.createdAt, 'dd.MM.yyyy HH:mm', 'Europe/Moscow') }}
        </div>
      </div>
      <div class="tableitems__item__element">
        <div class="tableitems__item__element_label">Тип</div>
        <div class="tableitems__item__element_value">
          {{ item.title }}
        </div>
      </div>
      <div class="tableitems__item__element">
        <div class="tableitems__item__element_label">Приход</div>
        <div class="tableitems__item__element_value">
          {{ formatCurrency(getIncome(item), 2) }} ₽
        </div>
      </div>
      <div class="tableitems__item__element">
        <div class="tableitems__item__element_label">Расход</div>
        <div class="tableitems__item__element_value">
          {{ formatCurrency(getOutcome(item), 2) }} ₽
        </div>
      </div>
      <div class="tableitems__item__element">
        <div class="tableitems__item__element_label">Номер договора</div>
        <div class="tableitems__item__element_value">
          {{ item.projectNumber }}
        </div>
      </div>
      <div class="tableitems__item__element">
        <div class="tableitems__item__element_label">Тип инвестиции</div>
        <div class="tableitems__item__element_value">
          <template
            v-if="thereIsNoInvestmentTypeInTheTransaction(item.isManual)"
            >-</template
          >
          <template v-else>
            <template v-if="item.isManual">Ручная</template>
            <template v-else>Авто</template>
          </template>
        </div>
      </div>
      <div class="tableitems__item__element tableitems__item__element__full">
        <div class="tableitems__item__element_label">Комментарий</div>
        <div class="tableitems__item__element_value">
          <span>{{ item.description }}</span>
          <div v-if="item.title === 'Расход по цессии' && showLinkByDate(item)">
            <div v-if="item.isReceiptPrint">
              <a @click.prevent="getReceiptIdAsync(item)">Скачать чек</a>
            </div>
            <div v-else>Чек еще не сформирован</div>
          </div>
        </div>
      </div>
      <div class="tableitems__item__element tableitems__item__element__full">
        <div class="tableitems__item__element_label">
          Наименование/ФИО контрагента
        </div>
        <div class="tableitems__item__element_value">{{ item.borrower }}</div>
      </div>
    </div>
    <p
      v-if="!props.loadingItems && props.items.length === 0"
      style="margin: 20px 0"
    >
      По заданым параметрам ничего не найдено
    </p>
  </div>
</template>
<script setup>
import { isAfter, parseISO } from 'date-fns';

import { formatCurrency, formatDate } from '@/utils/commonUtils/utils';

const props = defineProps(['items', 'loadingItems']);
const emit = defineEmits(['getreceipt']);

const thereIsNoInvestmentTypeInTheTransaction = (value) => {
  return value === undefined;
};
const getIncome = (item) => {
  return item.amount > 0 ? item.amount : 0;
};
const getOutcome = (item) => {
  return item.amount < 0 ? item.amount : 0;
};
const showLinkByDate = (item) => {
  return isAfter(parseISO(item.createdAt), new Date(2020, 7, 28));
};
const getReceiptIdAsync = (item) => {
  emit('getreceipt', item);
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.tableitems__item {
  background-color: white;
  box-shadow: 0px 1px 4px #e5e9f2;
  border-radius: 6px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px 16px;
  padding-bottom: 10px;

  .tableitems__item__element {
    flex: 1 1 50%;
    border-bottom: 1px solid #f5f6fa;
    padding-top: 12px;
    &:last-child {
      border-bottom: initial;
    }

    .tableitems__item__element_label {
      font-size: 12px;
      line-height: 24px;
      font-weight: normal;
      color: $grey-fields;
      margin-bottom: 6px;
    }
    .tableitems__item__element_value {
      padding-bottom: 7px;
    }
  }
  .tabkeitems__item__element__devided {
    display: flex;
    flex: 1 1 100%;
    .tableitems__item__element_label {
      flex: 1;
    }
    .tableitems__item__element_value {
      flex: 1;
    }
  }
}
.tableitems__item__totaltitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  flex: 1 1 100%;
  padding: 16px 0px;
}
.tableitems__item__element__full {
  flex: 2 1 100% !important;
}
</style>
