'use strict';

import { extend, isString } from '../lang';
import Action from './Action';

/**
 * @type {Object}
 * @see http://github.github.io/fetch/#options
 * @see https://learn.javascript.ru/fetch
 */
const OPTIONS = {
  cache: 'default',
  credentials: 'omit',
  mode: 'same-origin', // DON'T USE "no-cors" !!!
  method: 'POST',
  headers: {},
};

export default class Server {
  /**
   * @param {Object} actions
   * @param {Object} pipes
   */
  constructor(actions, pipes) {
    this.options = extend({}, OPTIONS);
    this.pipes = pipes;
    this.addActions(actions);
  }
  /**
   * Adds methods to server object
   * @param {Object} actions
   */
  addActions(actions) {
    for (let key in actions) {
      let action = actions[key];
      if (isString(action)) {
        this.addAction(key, { url: action });
      } else {
        this.addAction(key, action); // action is object
      }
    }
  }
  /**
   * Adds method to server object
   * @param {String} key
   * @param {Object} options
   */
  addAction(key, options) {
    const options_ = extend({}, options);
    const Constructor = this.constructor.Action();
    this[key] = new Constructor(options_, this); // DI
  }

  onAfterComplete() {}

  onBeforeSend() {}
  /**
   * @param {Object} error
   */
  onError() {
    // no op
  }
  /**
   * DI
   * @constructor
   */
  static Action() {
    return Action;
  }
}
