<template>
  <div>
    <div class="row row_first">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.search"
          class="form-control filter_item"
          placeholder="поиск по заемщику, id займа"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.daysOverdue"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="просрочка"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.soldBy"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="од на момент продажи"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentDebt"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="остаток од"
          @keyup="updateFilters"
        />
      </div>
    </div>
    <div class="row row_second">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentIncome"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="факт. доход"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentExpectingIncome"
          v-maska="masks.digits"
          class="form-control filter_item"
          placeholder="прогноз дохода"
          @keyup="updateFilters"
        />
      </div>
    </div>
  </div>
</template>
<script>
import masks from '@/utils/masks';

export default {
  emits: ['updateFilters'],
  data() {
    return {
      form: {
        search: null,
        daysOverdue: null,
        soldBy: null,
        myInvestmentDebt: null,
        myInvestmentIncome: null,
        myInvestmentExpectingIncome: null,
      },
    };
  },
  computed: {
    masks() {
      return {
        purchaseDeals: masks.digits(2),
        digits: masks.digits(15),
      };
    },
  },
  methods: {
    updateFilters() {
      this.$emit('updateFilters', this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
@use '../../../../../scss/variables.scss' as *;

.row {
  @media (max-width: $size_767) {
    margin: 0;
  }
}
.row_second {
  margin-top: 20px;
  @media (max-width: $size_767) {
    margin-top: 0px;
  }
}
.col-xs-12 {
  @media (max-width: $size_767) {
    padding: 0;
  }
}
</style>
