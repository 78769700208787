/* eslint-disable */
import { ref } from 'vue';
import { useStateStore } from '@/store/stateStore';
import { defaultNewProjectFilters } from '@/store/default';

import { copyAsJson } from '@/utils/commonUtils/utils';

export default function loansFiltersComposable() {
  const store = useStateStore();

  const riskTypes = ref([]);
  const loanTypes = ref([]);
  const borrowTypes = ref([]);

  const filters = ref(defaultNewProjectFilters);

  const makeFilter = (items, filters) => {
    let loanTypes = {};

    filters.loanType.forEach((i) => {
      if (i.slug === 'factoring' && i.value) {
        loanTypes['factoring'] = true;
      }
      if (i.slug === 'tender' && i.value) {
        loanTypes['tender'] = true;
        loanTypes['tender_no_purpose'] = true;
      }
      if (i.slug === 'main' && i.value) {
        loanTypes['main'] = true;
      }
    });
    loanTypes = Object.keys(loanTypes);
    if (loanTypes.length > 0) {
      items = items.filter((i) => i.loanType && loanTypes.includes(i.loanType));
    }

    let riskTypes = {};
    filters.riskType.forEach((i) => {
      if (i.slug === 'standard' && i.value) {
        riskTypes['standard'] = true;
      }
      if (i.slug === 'additional' && i.value) {
        riskTypes['additional'] = true;
      }
    });
    if (Object.keys(riskTypes).length > 0) {
      items = items.filter((i) => {
        if (riskTypes['additional'] && !riskTypes['standard']) {
          return i.isManualInvestExplicit == true;
        }
        if (riskTypes['standard'] && !riskTypes['additional']) {
          return i.isManualInvestExplicit == false;
        }
        return i;
      });
    }

    let borrowType = {};
    filters.borrowType.forEach((i) => {
      if (i.slug === 'new' && i.value) {
        borrowType['new'] = true;
      }
      if (i.slug === 'repeated' && i.value) {
        borrowType['repeated'] = true;
      }
    });
    if (Object.keys(borrowType).length > 0) {
      items = items.filter((i) => {
        if (borrowType['repeated'] && !borrowType['new']) {
          return i.mmRefundedLoansCount > 0 && i.loanType !== 'high_risk';
        }
        if (borrowType['new'] && !borrowType['repeated']) {
          return i.mmRefundedLoansCount == 0 || i.loanType === 'high_risk';
        }
        return i;
      });
    }

    return items;
  };

  const updateFilters = () => {
    filters.value.riskType = riskTypes.value;
    filters.value.loanType = loanTypes.value;
    filters.value.borrowType = borrowTypes.value;
    store.setInvestorFilterPreferences(filters.value);
  };

  const onChangeFilters = (_filters) => {
    filters.value = _filters;
  };

  const resetFilters = () => {
    riskTypes.value.forEach((i) => {
      i.value = false;
    });
    loanTypes.value.forEach((i) => {
      i.value = false;
    });
    borrowTypes.value.forEach((i) => {
      i.value = false;
    });
    updateFilters();
  };

  const initFilterData = () => {
    if (
      store.state.preferences &&
      store.state.preferences.invertorPage &&
      store.state.preferences.invertorPage.filters &&
      Array.isArray(store.state.preferences.invertorPage.filters.riskType)
    ) {
      filters.value = copyAsJson(store.state.preferences.invertorPage.filters);
    }
    riskTypes.value = filters.value.riskType;
    loanTypes.value = filters.value.loanType;
    borrowTypes.value = filters.value.borrowType;
  };

  return {
    riskTypes,
    loanTypes,
    borrowTypes,
    filters,
    initFilterData,
    makeFilter,
    updateFilters,
    onChangeFilters,
    resetFilters,
  };
}
