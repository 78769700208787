<template>
  <div>
    <div class="step_title">Найдите свое ИП</div>
    <p class="step_description">
      Выберите в списке свое — ваш ИНН
      <span style="color: #4b64c4">{{ user.inn }}</span>
    </p>
    <div>
      <InvestorAddStep_2FindCompany
        v-if="isUserLoaded"
        v-model:formCompany="formCompany"
        v-model:isFindCompany="isFindCompany"
        v-model:isFindCompanyFailure="isFindCompanyFailure"
        v-model:isInvalidSelectedCompanyType="isInvalidSelectedCompanyType"
        :expected-company-type="'INDIVIDUAL'"
      />
    </div>
    <div>
      <PotokDivider marginY="20" />
      <button
        class="button button-secondary"
        type="button"
        :disabled="isDisabledContinue"
        @click="onClickContinue"
      >
        Добавить это ИП
      </button>

      <accept-rules-ip />
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from 'vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { copyAsJson } from '@/utils/commonUtils/utils';
import InvestorAddStep_2FindCompany from '@/components/InvestorAdd/InvestorAddStep_2/InvestorAddStep_2FindCompany.vue';
import AcceptRulesIp from '@/components/InvestorAdd/InvestorAddStep_2/InvestorAddStep_2_1/AcceptRulesIp.vue';

const store = useStateStore();
const emits = defineEmits(['nextStep']);

const loading = ref(false);
const formCompany = reactive({
  shortName: '',
  fullName: '',
  formalAddress: '',
  inn: '',
  ogrn: '',
  kpp: '',
  directorFio: '',
  directorPosition: '',
  opf: '',
  registerDateAt: null,
  isRealCompany: false,
});
const isFindCompany = ref(false);
const isFindCompanyFailure = ref(false);
const isInvalidSelectedCompanyType = ref(false);

const user = computed(() => {
  return store.state.user;
});
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const isDisabledContinue = computed(() => {
  return (
    !isFindCompany.value ||
    isFindCompanyFailure.value ||
    isInvalidSelectedCompanyType.value ||
    loading.value
  );
});
const onClickContinue = () => {
  addThisCompany();
};
const addThisCompany = () => {
  const query = Object.assign(
    {
      birthDateAt: user.value.birthDateAt,
    },
    copyAsJson(formCompany),
  );
  loading.value = true;
  return server.postCompanies
    .send(query)
    .pipe(
      onAddInfoCompaniesAsyncSuccess.bind(this),
      onAddInfoCompaniesAsyncError.bind(this),
    )
    .exec();
};
const onAddInfoCompaniesAsyncSuccess = (obj) => {
  loading.value = false;
  let data = obj.data || {};
  if (data.isSuccessful) {
    emits('nextStep', {
      nextStepId: '3_1',
    });
  }
};
const onAddInfoCompaniesAsyncError = () => {
  loading.value = false;
};
</script>
<style lang="scss" scoped></style>
