<template>
  <div class="total_wrapper">
    <div class="icon_trend_down"></div>
    <template v-if="!props.loading">
      <div class="content_title">Всего расход</div>
      <div class="content_value" style="color: #ee3d48">
        {{ formatCurrency(outcome, 2) }} ₽
      </div>
    </template>
    <template v-if="loading">
      <Loader style="margin-top: 20px" />
    </template>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';
import { Loader } from 'potok-uikit';

const props = defineProps({
  loading: Boolean,
  outcome: [String, Number],
});
</script>
<style></style>
