<template>
  <div class="row">
    <DashboardOnboardRefillBalanceAccountDesktop
      v-if="!isMobile"
      :fields="fields"
      :payment-values="paymentValues"
      :data="props.data"
    />

    <DashboardOnboardRefillBalanceAccountMobile
      v-if="isMobile"
      :fields="fields"
      :payment-values="paymentValues"
      :data="props.data"
    />
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';

import { useStateStore } from '@/store/stateStore';
import detectDevice from '@/composables/detectDeviceComposable.js';
import DashboardOnboardRefillBalanceAccountDesktop from './DashboardOnboardRefillBalanceAccountDesktop.vue';
import DashboardOnboardRefillBalanceAccountMobile from './DashboardOnboardRefillBalanceAccountMobile.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const { isMobile } = detectDevice();
const store = useStateStore();

const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const payeeName = 'ООО "Поток.Диджитал"';
const payeeInn = '9701046627';
const payeeKpp = '770101001';
const payeePersonalAcc = '40702810301600011889';
const payeeBankName = 'АО "Альфа-Банк"';
const payeeBankBic = '044525593';
const payeeBankCorrespAcc = '30101810200000000593';
const accountNumber = computed(() => {
  if (selectedCompany.value && selectedCompany.value) {
    return selectedCompany.value.investorAccountNumber;
  }
  return null;
});
const purpose = computed(() => {
  return `Пополнение лицевого счета ${accountNumber.value}. Без НДС`;
});
const getFullPurpose = `<p>Пополнение лицевого счета ${accountNumber.value}. Без НДС.</p>`;

const paymentValues = {
  payeeName,
  payeeInn,
  payeeKpp,
  payeePersonalAcc,
  payeeBankName,
  payeeBankBic,
  payeeBankCorrespAcc,
  purpose: purpose.value,
  getFullPurpose,
};
const fields = ref([
  {
    name: 'получатель',
    value: payeeName,
    copied: false,
  },
  {
    name: 'инн',
    value: payeeInn,
    copied: false,
  },
  {
    name: 'кпп',
    value: payeeKpp,
    copied: false,
  },
  {
    name: 'банк',
    value: payeeBankName,
    copied: false,
  },
  {
    name: 'бик',
    value: payeeBankBic,
    copied: false,
  },
  {
    name: 'корр счет',
    value: payeeBankCorrespAcc,
    copied: false,
  },
  {
    name: 'номер счета',
    value: '40702810301600011889',
    copied: false,
  },
  {
    name: 'назначение',
    value: getFullPurpose,
    copied: false,
  },
]);
</script>
<style lang="scss" scoped></style>
