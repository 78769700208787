export const defaultFilters = Object.freeze({
  riskType: {
    standard: false,
    additional: false,
  },
  loanType: {
    factoring: false,
    tender: false,
    main: false,
  },
  borrowType: {
    new: false,
    repeated: false,
  },
});
export const defaultNewProjectFilters = {
  riskType: [
    {
      slug: 'standard',
      value: false,
      title: 'Стандартный',
    },
    {
      slug: 'additional',
      value: false,
      title: 'Повышенный',
    },
  ],
  loanType: [
    {
      slug: 'factoring',
      value: false,
      title: 'Факторинг',
    },
    {
      slug: 'tender',
      value: false,
      title: 'Госзаказ',
    },
    {
      slug: 'main',
      value: false,
      title: 'Развитие бизнеса',
    },
  ],
  borrowType: [
    {
      slug: 'new',
      value: false,
      title: 'Новые',
    },
    {
      slug: 'repeated',
      value: false,
      title: 'Повторные',
    },
  ],
};
export const defaultInvestorPrefecences = Object.freeze({
  isShowAutoinvest: true,
  isShowFilters: true,
  isShowResult: true,
  filters: defaultFilters,
  sortingOptions: 'date_minus',
});

export const defaultStore = Object.freeze({
  alert: {
    callback: undefined,
    message: undefined,
    title: undefined,
  },
  app: {
    version: `v${APP_VERSION}`, // eslint-disable-line no-undef
  },
  balances: {},
  preferences: {
    companyId: undefined,
    invertorPage: { ...defaultInvestorPrefecences },
  },
  buffer: {
    rawPhone: undefined,
    fullName: undefined,
    depositOperationId: undefined,
    investorCompanyTypeId: undefined,
    investorCompanyId: undefined,
    project: {
      id: undefined,
      loanAmount: '0.0000',
      loanTerm: 0,
      loanRisingTerm: 0,
      borrower: {
        id: undefined,
      },
    },
    investment: {
      id: undefined,
      amount: 0,
      loanTerm: 0,
    },
  },
  confirm: {
    callback: undefined,
    message: undefined,
    title: undefined,
  },
  dictionaries: {
    companies: [],
  },
  error: undefined,
  features: {
    general: {
      remainingBalanceInterestPromo: {
        enabled: false,
      },
      appRating: {
        enabled: false,
      },
      enhancedDiversification: {
        enabled: false,
      },
      statusBonus: {
        enabled: false,
      },
      welcomeBonus: {
        enabled: false,
      },
      investingPotokLoansBanner: {
        enabled: false,
      },
      exclusivePotokLoansOffer: {
        enabled: false,
      },
      additionalStepForEnableStrategies: {
        enabled: false,
      },
      inactiveUser: {
        enabled: false,
      },
    },
    remainingBalanceInterestPromo: undefined,
  },
  loaders: {
    isUserLoading: undefined,
    isFeaturesLoading: true,
    isPlatformStatFutureLoading: undefined,
    isPulseProjectStatsLoading: undefined,
  },
  menuNotification: {
    counter: 0,
    list: [],
  },
  modals: {
    isShowModalRefferal: undefined,
    isShowBonusFromBalanceModal: undefined,
    isActiveBonusFromBalanceModal: undefined,
    isShowNewSaleInvestmentsModal: undefined,
  },
  notification: {
    timer: 5000,
    message: '',
    title: '',
    isError: undefined,
    btnCallback: null,
    btnText: '',
  },
  result: {},
  resultDate: {},
  session: {
    login: undefined,
    token: undefined,
    startSession: undefined,
  },
  tip: {
    callback: undefined,
    message: undefined,
    title: undefined,
    type: undefined,
  },
  tours: {
    isBuybacksTourCompleted: undefined,
  },
  user: {
    utm: [],
  },
});
