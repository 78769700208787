<template>
  <tr class="potok-table-row" :class="{ 'potok-table-row-hover': props.hover }">
    <slot />
  </tr>
</template>

<script setup>
const props = defineProps({
  hover: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.potok-table-row {
  border-color: $dividers;
}

.potok-table-row-hover {
  &:hover {
    cursor: pointer;
    background-color: $background;
  }
}
</style>
