<template>
  <div class="project-label-pif" @click.prevent>
    <VDropdown>
      <button class="info-icon-hint" style="display: flex; align-items: center">
        <div class="project__tag project__tag-circle pif">
          <div class="project__tag-icon">
            <i class="icon_galka" />
          </div>
        </div>
        <span class="project-label-pif__text">Поток</span>
      </button>
      <template #popper>
        <div class="popup">
          <div v-close-popper class="popup-close"></div>
          <div class="popup-text" style="text-transform: none">
            займ проинвестирован средствами МФК «Поток.Финанс» (ООО) и другими
            средствами акционеров
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@use '../../../../../../scss/variables.scss' as *;

.project-label-pif {
  top: 2px;
  border: 1px solid #dedee1;
  box-sizing: border-box;
  border-radius: 6px;
  color: #313132;
  padding: 0 8px;
  height: 20px;
  display: flex;
  align-items: center;
  text-transform: none;

  @media (max-width: $size_767) {
    margin: 5px 0;
  }

  .project__tag-circle {
    margin-right: 4px;
    display: flex;
    align-items: center;
    .project__tag-icon {
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }
  .project-label-pif__text {
    font-style: normal;
    text-transform: none;
  }
}
.icon_galka {
  height: 4px;
}
</style>
