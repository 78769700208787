import { downloadFile, parseFilename } from '@/utils/commonUtils/utils';
import constants from '@/constants';
import server from '@/server';

export default function documentUtils() {
  const formatDocumentTitle = (item) => {
    return constants.documentTypesTitle[item.type];
  };
  const downloadSignedDocumentsAsync = (item) => {
    const params = {
      id: item.id,
    };
    return server.getDocument
      .send(null, { params })
      .pipe(onFileDownloadedAsyncSuccess)
      .exec();
  };
  const onFileDownloadedAsyncSuccess = ({ data, response }) => {
    const filename = parseFilename(response);
    downloadFile(filename, data);
  };

  return {
    formatDocumentTitle,
    downloadSignedDocumentsAsync,
    onFileDownloadedAsyncSuccess,
  };
}
