const messageHelp = `
<div class="refill-modal-help">
  <div class="refill-modal-help-item">
    <div class="refill-modal-help-number">1</div>скачайте qr код на ваше устройство</div>
    <ul class="refill-modal-help-subitem">
      <li>кликните на скачать qr код</li>
      <li>или сделайте скриншот страницы с qr кодом</li>
    </ul>
    <div class="divider">
  </div>

  <div class="refill-modal-help-item">
    <div class="refill-modal-help-number">2</div>откройте мобильное приложение вашего банка</div>
    <ul class="refill-modal-help-subitem">
      <li>запустите в нем функцию для оплаты по qr коду</li>
      <li>теперь выберите способ оплаты по фотографии</li>
    </ul>
    <div class="divider">
  </div>

  <div class="refill-modal-help-item">
    <div class="refill-modal-help-number">3</div>загрузите qr код</div>
    <div class="divider">
  </div>

  <div class="refill-modal-help-item">
    <div class="refill-modal-help-number">4</div>переведите денежные средства</div>
  </div>
</div>`;
export default messageHelp;
