<template>
  <div class="hints-content">
    <div class="hints-content-item mb-5">
      <div class="potok-text-body-1-bold mb-2">текущий дисконт</div>
      <div class="potok-text-body-2">
        {{ props.hints?.discountPercentage?.fullDescription }}
      </div>
    </div>

    <div class="hints-content-item mb-5">
      <div class="potok-text-body-1-bold mb-2">сумма продажи с дисконтом</div>
      <div class="potok-text-body-2">
        {{ props.hints?.totalMainDebtAmount?.fullDescription }}
      </div>
    </div>

    <div class="hints-content-item mb-5">
      <div class="potok-text-body-1-bold mb-2">лимит</div>
      <div class="potok-text-body-2">
        {{ props.hints?.limit?.fullDescription }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  hints: {
    type: [Object],
    required: false,
  },
});
</script>

<style scoped>
.hints-content {
  text-transform: initial;
}
</style>
