<template>
  <PotokPage title="Добавление инвестора">
    <div v-if="isUserLoaded && inited">
      <InvestorAddDesktop
        v-if="!isMobile"
        :current-step-id="currentStepId"
        :steps="steps"
        @next-step="nextStep"
      />
      <InvestorAddMobile
        v-if="isMobile"
        :current-step-id="currentStepId"
        :steps="steps"
        @next-step="nextStep"
      />
    </div>
  </PotokPage>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import constants from '@/constants';
import detectDevice from '@/composables/detectDeviceComposable';
import { PotokPage } from 'potok-uikit';

import { useStateStore } from '@/store/stateStore';
import InvestorAddMobile from './InvestorAdd/InvestorAddContainers/InvestorAddMobile.vue';
import InvestorAddDesktop from './InvestorAdd/InvestorAddContainers/InvestorAddDesktop.vue';

const store = useStateStore();

const { isMobile } = detectDevice();

const inited = ref(false);
const currentStepId = ref(null);
const steps = ref([
  {
    id: '1_1',
    step: '1_1',
    titleId: 1,
    title: 'Выбор формы компании',
    nextStepId: '2_1',
    visiblePassedStepsIds: [],
    visibleNextStepsIds: ['2_1', '3_1'],
  },
  {
    id: '2_1',
    step: '2_1',
    titleId: 2,
    title: 'Поиск компании',
    nextStepId: '3_1',
    visiblePassedStepsIds: ['1_1'],
    visibleNextStepsIds: ['3_1'],
    prevStepIds: ['1_1'],
  },
  {
    id: '2_2',
    step: '2_2',
    titleId: 2,
    title: 'Поиск компании',
    nextStepId: '3_2',
    visiblePassedStepsIds: ['1_1'],
    visibleNextStepsIds: ['3_2'],
    prevStepIds: ['1_1'],
  },
  {
    id: '3_1',
    step: '3_1',
    titleId: 3,
    title: 'Присоединение к правилам',
    visiblePassedStepsIds: ['1_1', '2_1'],
  },
  {
    id: '3_2',
    step: '3_2',
    titleId: 3,
    title: 'Присоединение к правилам',
    visiblePassedStepsIds: ['1_1', '2_2'],
  },
]);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});

watch(
  () => {
    return selectedCompany.value;
  },
  () => {
    detectCurrentStep();
  },
);
onMounted(() => {
  detectCurrentStep();
});

const detectCurrentStep = () => {
  let _currentStepId = '1_1';
  if (!selectedCompany.value) {
    return;
  }
  if (selectedCompany.value.typeId === constants.companyTypes.entrepreneur) {
    if (!selectedCompany.value.isAgreeRules) {
      _currentStepId = '3_1';
    }
  }
  if (selectedCompany.value.typeId === constants.companyTypes.company) {
    if (!selectedCompany.value.isAgreeRules) {
      _currentStepId = '3_2';
    }
  }
  currentStepId.value = _currentStepId;
  inited.value = true;
};
const nextStep = (obj) => {
  if (obj.nextStepId) {
    currentStepId.value = obj.nextStepId;
  }
};
</script>
<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

:deep(.step_title) {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  color: #313132;
  margin-bottom: 12px;
  @media (max-width: $size_767) {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
}
:deep(.step_description) {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5c5c5c;
  max-width: 636px;
  @media (max-width: $size_767) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
