<template>
  <div class="row" style="margin-top: -15px">
    <div class="col-md-12 tab_menu">
      <div style="display: flex; flex-wrap: wrap">
        <ul class="block-tabs">
          <li>
            <a
              :class="{ 'tab-active': isTab(tabMenu[0]) }"
              href=""
              @click.prevent="changeTab(tabMenu[0])"
              >активные {{ props.count.activeProjects }}</a
            >
          </li>
          <li>
            <a
              :class="{ 'tab-active': isTab(tabMenu[1]) }"
              href=""
              @click.prevent="changeTab(tabMenu[1])"
              >зарезервированные {{ props.count.investmentProjects }}</a
            >
          </li>
          <li>
            <a
              :class="{ 'tab-active': isTab(tabMenu[2]) }"
              href=""
              @click.prevent="changeTab(tabMenu[2])"
              >собранные {{ props.count.completeProjects }}</a
            >
          </li>
          <li>
            <a
              :class="{ 'tab-active': isTab(tabMenu[3]) }"
              href=""
              @click.prevent="changeTab(tabMenu[3])"
              >всего {{ props.count.allProjects }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const MENU_ACTIVE = 'Active';
const MENU_INVESTMENT = 'Investment';
const MENU_COMPLETE = 'Complete';
const MENU_ALL = 'All';

const tabMenu = ref([MENU_ACTIVE, MENU_INVESTMENT, MENU_COMPLETE, MENU_ALL]);

const emit = defineEmits(['selectMenu']);
const props = defineProps(['selectedMenu', 'count']);

const changeTab = (menuName) => {
  emit('selectMenu', menuName);
};
const isTab = (menuName) => {
  return props.selectedMenu === menuName ? true : false;
};
</script>
<style lang="scss" scoped>
.block-tabs {
  width: 100%;
}
</style>
