/**
 * @param {Object} error
 * @returns {Error}
 */
function throwError(error) {
  throw error;
}

export default class PromisePipeline {
  /**
   * @param {Function} initFn
   */
  constructor(initFn) {
    this.__pipes = [[], [], []];
    this.__init = initFn;
  }
  /**
   * @param {Function} success
   * @param {Function} [error]
   */
  pre(success, error = throwError) {
    this.__pipes[0].push([success, error]);
    return this;
  }
  /**
   * @param {Function} success
   * @param {Function} [error]
   */
  pipe(success, error = throwError) {
    this.__pipes[1].push([success, error]);
    return this;
  }
  /**
   * @param {Function} success
   * @param {Function} [error]
   */
  post(success, error = throwError) {
    this.__pipes[2].push([success, error]);
    return this;
  }
  /**
   * @returns {Promise.<T>}
   */
  exec() {
    const [a, b, c] = this.__pipes;
    const pipeline = [...a, ...b, ...c];
    return pipeline.reduce(
      (accumulator, currentValue) => accumulator.then(...currentValue),
      this.__init(),
    );
  }
}
