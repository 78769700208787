import { createWebHistory, createRouter } from 'vue-router';
import authenticationGuard from '@/router/guards/authenticationGuard';
import onBoardingGuard from '@/router/guards/onBoardingGuard';
import titleGuard from '@/router/guards/titleGuard';
import trackerGuard from '@/router/guards/trackerGuard';
import routes from './routes/index';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authenticationGuard);
router.beforeEach(onBoardingGuard);
router.beforeEach(titleGuard);
router.afterEach(trackerGuard);

export default router;
