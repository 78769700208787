<template>
  <div class="wrapper__content wrapper__content__has_background">
    <div class="wrapper__header">
      <div class="wrapper__header__left" />
      <header class="header header__container">
        <component :is="headerComponentName" />
      </header>
      <div class="wrapper__header__right" />
    </div>

    <div class="global-messages">
      <PotokMessage
        v-if="
          store.state.features.general.inactiveUser?.enabled &&
          inactiveUserMessageText
        "
      >
        <div class="inactive-user-message">
          <span>
            {{ inactiveUserMessageText }}
          </span>
          <PotokButton
            theme="linkIconUpload"
            size="link"
            no-full-width-tablet
            :text="inactiveUserButtonText"
            class="messages-button"
            @click="toRefillPage"
          />
        </div>
      </PotokMessage>
    </div>

    <main>
      <router-view name="content" />
    </main>

    <Footer />

    <tips-handler />

    <DebugTeleport v-if="isRunTeleport" />

    <UiNotification
      :timer="notificationData.timer"
      :title="notificationData.title"
      :message="notificationData.message"
      :is-error="notificationData.isError"
      :btn-callback="notificationData.btnCallback"
      :btn-text="notificationData.btnText"
    />

    <ModalRefferalMessage v-if="isShowModalRefferal" />
    <BonusForDepositModal
      v-if="isShowBonusForDepositModal"
      @close="isShowBonusForDepositModal = false"
    />
    <TelegramDialog
      v-if="
        !store.state.loaders.isUserLoading && store.state.user.isShowTgBanner
      "
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { parseISO } from 'date-fns';
import PotokMessage from '@/components/_generic/PotokMessage.vue';

import TipsHandler from '@/components/_generic/modals/TipsHandler.vue';
import ModalRefferalMessage from '@/components/_generic/modals/ModalRefferalMessage.vue';
import UiNotification from '@/components/_generic/notification/UiNotification.vue';

import Footer from '@/components/Footer/Footer.vue';
import HeaderUnauth from '@/components/Header/HeaderUnauth.vue';
import Header from '@/components/Header/Header.vue';
import DebugTeleport from '@/components/Teleport/Teleport.vue';

import { useStateStore } from '@/store/stateStore';
import { useRouter } from 'vue-router';
import TelegramDialog from '@/components/dialogs/TelegramDialog.vue';
import server from '@/server';
import {
  formatDateInTimeZone,
  isAvailableBetweenDates,
} from '@/utils/date/dateUtils';
import BonusForDepositModal from '@/components/_modals/BonusForDepositModal';

const store = useStateStore();
const router = useRouter();

const isRunTeleport = ref(false);

const inactiveUserMessageText = ref('');
const inactiveUserButtonText = ref('');

const isShowBonusForDepositModal = ref(false);

const headerComponentName = computed(() => {
  return store.isUnauthenticated ? HeaderUnauth : Header;
});
const isShowModalRefferal = computed(() => {
  return store.state.modals.isShowModalRefferal;
});
const notificationData = computed(() => {
  return store.state.notification;
});

if (process.env.VUE_APP_NODE_ENV === 'mock') {
  isRunTeleport.value = true;
}

const toRefillPage = () => {
  router.push({ name: 'refill' });
};

const getFeaturesAsync = () => {
  return server.getFeatures
    .send()
    .pipe(onGetFeaturesAsyncSuccess, () => {
      store.state.loaders.isFeaturesLoading = false;
    })
    .exec();
};

const onGetFeaturesAsyncSuccess = ({ data }) => {
  if (!store.state.features) {
    store.state.features = { general: {} };
  }
  store.state.features.general = data;

  if (store.state.features.general.inactiveUser?.enabled) {
    getInactiveUserFeature();
  }
  store.state.loaders.isFeaturesLoading = false;
};

getFeaturesAsync();

const getInactiveUserFeature = () => {
  return server.getFeaturesInactiveUser
    .send()
    .pipe(onGetFeaturesInactiveUserSuccess, () => {})
    .exec();
};

const onGetFeaturesInactiveUserSuccess = ({ data }) => {
  inactiveUserButtonText.value = data.buttonLabel;
  inactiveUserMessageText.value = data.cardDescription;
};

const openBonusForDepositModal = () => {
  isShowBonusForDepositModal.value = true;
};

onMounted(() => {
  const now = new Date();
  const nowInMoscow = formatDateInTimeZone(
    now,
    'Europe/Moscow',
    'yyyy-MM-dd HH:mm:ss',
  );
  const nowInMoscowDate = parseISO(nowInMoscow);

  const isLiveNow = isAvailableBetweenDates(
    nowInMoscowDate,
    '2024-10-14 00:00:00',
    '2024-10-25 00:00:00',
  );

  if (isLiveNow) {
    setTimeout(() => {
      store.setNotification({
        title: 'Внимание! Акция! ',
        message: 'Получи кэшбек х2 до 30000 руб!',
        timer: 'infinity',
        isError: false,
        btnCallback: openBonusForDepositModal,
        btnText: 'подробнее →',
      });
    }, 1000);
  }
});
</script>

<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.global-messages {
  width: 1180px;
  margin: 0 auto;
  padding: 80px 15px 0 15px;

  @media (max-width: $size_1200) {
    width: 970px;
  }
  @media (max-width: $size_991) {
    width: 750px;
  }
  @media (max-width: $size_767) {
    width: 100%;
    padding: 44px 15px 0 15px;
  }
}

.inactive-user-message {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: $size_767) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.messages-button {
  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}
</style>
