import { formatCurrency } from '@/utils/commonUtils/utils';

export const createRateText = (rate) => {
  const finalRate = rate * 100;
  let digits = Number.isInteger(finalRate) ? 0 : 1;
  // Если finalRate при умножении получило не корректное значение типа "28.0000000000000004", проверяем это и фиксим digits
  if (digits === 1) {
    const fixedRate = Number(finalRate.toFixed(1));
    if (Number.isInteger(fixedRate)) {
      digits = 0;
    }
  }
  return `${formatCurrency(finalRate, digits)}`;
};
