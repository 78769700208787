export default {
  getAssetGeneral: {
    url: '/api/analytics',
    method: 'GET',
  },
  getAssetSum: {
    url: '/api/analytics/asset-sum',
    method: 'GET',
  },
  getAssetReturns: {
    url: '/api/analytics/investments-returns',
    method: 'GET',
  },
  getAssetBalance: {
    url: '/api/analytics/balance-change',
    method: 'GET',
  },
  getAssetDefaults: {
    url: '/api/analytics/defaults-and-restructures',
    method: 'GET',
  },
  getAssetStructure: {
    url: '/api/analytics/asset-structure',
    method: 'GET',
  },
  getAssetDiversification: {
    url: '/api/analytics/investments-diversification',
    method: 'GET',
  },
};
