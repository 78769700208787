<template>
  <div class="total_wrapper">
    <div class="icon_trend_up"></div>
    <template v-if="!props.loading">
      <div class="content_title">Всего приход</div>
      <div class="content_value" style="color: #04ccd9">
        {{ formatCurrency(props.income, 2) }} ₽
      </div>
    </template>
    <template v-if="props.loading">
      <Loader style="margin-top: 20px" />
    </template>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/utils';
import { Loader } from 'potok-uikit';

const props = defineProps({
  loading: Boolean,
  income: [String, Number],
});
</script>
<style></style>
