import { ref } from 'vue';
import { useStateStore } from '@/store/stateStore';
import server from '@/server';
import constants from '@/constants';
import FUTURE_RATE_INFO from '@/components/AutoInvest/htmlForModals/future-rate';

export function autoInvestUtils() {
  const store = useStateStore();

  const message = ref(null);
  const messageTitle = ref(null);

  const settingsId = ref(null);
  const newCompanyId = ref(null);
  const confirmErrorMessage = ref(null);
  const confirmMessage = ref(null);

  const isShowDynamicModals = ref(false);
  const selectedForDynamicModalsStrategy = ref(null);

  const selectedStrategy = ref(null);

  const loading = ref(true);
  const loadingConnectStrategy = ref(false);
  const loadingInited = ref(false);

  const strategies = ref(null);

  const connectStrategyCallback = ref(null);
  const choosedStrategyForConnect = ref(null);
  const isShowModalConfirm = ref(false);

  const selectedEnhancedDiversification = ref(false);

  const getStrategies = () => {
    loading.value = true;

    const query = {
      companyId: store.selectedCompanyId,
    };

    return server.getStrategies
      .send(query)
      .pipe(
        (obj) => {
          const strategiesResponse = obj.data;
          const modifiedStrategies = [];

          const keys = Object.keys(strategiesResponse);

          if (!keys.length) {
            strategies.value = [];
            return;
          }

          for (let i = 0; i < keys.length; i++) {
            const newItem = {
              ...strategiesResponse[keys[i]],
              serverName: keys[i],
              isChosen: false,
            };

            modifiedStrategies.push(newItem);
          }

          strategies.value = modifiedStrategies;
          chooseStrategy(constants.strategiesServerNames.withoutRisk);
          loading.value = false;
        },
        () => {
          loading.value = false;
        },
      )
      .exec();
  };

  const getBonusFromBalance = () => {
    loading.value = true;

    const query = {
      companyId: store.selectedCompanyId,
    };
    return server.getFeaturesRemainingBalance
      .send(query)
      .pipe(onGetBonusFromBalanceSuccess, () => {
        loading.value = false;
      })
      .exec();
  };

  const onGetBonusFromBalanceSuccess = ({ data }) => {
    loading.value = false;
    store.state.features.remainingBalanceInterestPromo = data;
  };

  const chooseStrategy = (serverName) => {
    const prevChosenStrategy = strategies.value.find((item) => item.isChosen);
    if (prevChosenStrategy) {
      prevChosenStrategy.isChosen = false;
    }

    const newChosenStrategy = strategies.value.find(
      (item) => item.serverName === serverName,
    );
    newChosenStrategy.isChosen = true;
  };

  const getAutoInvestSettingsAsync = (disableLoading) => {
    if (!store.selectedCompanyId) {
      return;
    }
    if (!disableLoading) {
      loading.value = true;
    }
    return server.getCompanyAutoInvestSettings
      .send(null, { params: { id: store.selectedCompanyId } })
      .pipe(
        onGetAutoInvestSettingsSuccess.bind(this),
        onGetAutoInvestSettingsError.bind(this),
      )
      .exec();
  };
  const onGetAutoInvestSettingsSuccess = (obj) => {
    loadingInited.value = true;
    loading.value = false;
    message.value = null;
    messageTitle.value = null;
    const data = obj.data || {};
    selectedStrategy.value = {
      strategy: data.strategy,
      selectedStrategyDate: data.confirmedAt,
      isEnhancedDiversification: data.isEnhancedDiversification,
    };

    if (data.document && data.document.id) {
      selectedStrategy.value.selectedStrategyDocumentId = data.document.id;
    }
  };
  const onGetAutoInvestSettingsError = () => {
    loading.value = false;
    loadingInited.value = false;
  };

  /**
   * connectStrategy не должна принимать ни какие переменные так как вызывается внутри компонента
   * TwoFactorAuth без переменных
   */
  const connectStrategy = () => {
    confirmErrorMessage.value = null;
    const query = {
      strategy: choosedStrategyForConnect.value.serverName,
      isEnhancedDiversification: selectedEnhancedDiversification.value,
    };

    loadingConnectStrategy.value = true;
    return server.postCompanyAutoInvestSettings
      .send(query, { params: { id: store.selectedCompanyId } })
      .pipe(
        onPostAutoInvestSettingsSuccess.bind(this),
        onPostAutoInvestSettingsError.bind(this),
      )
      .exec();
  };
  const onPostAutoInvestSettingsSuccess = (obj) => {
    if (connectStrategyCallback.value) {
      connectStrategyCallback.value();
      connectStrategyCallback.value = null;
    }
    loadingConnectStrategy.value = false;
    const data = obj.data || {};
    if (!data.isSuccessful) {
      message.value = data.message;
      messageTitle.value = 'Произошла ошибка';
    }
    if (data.isSuccessful && data.settingsId) {
      settingsId.value = data.settingsId;
      isShowModalConfirm.value = true;
      if (data.companyId) {
        newCompanyId.value = data.companyId;
      }
      confirmMessage.value =
        choosedStrategyForConnect.value.confirmMessage ||
        `подтвердите подключение стратегии «${choosedStrategyForConnect.value.name}»
      ${selectedEnhancedDiversification.value ? ' c' : ' без'} усиленной диверсификации
      `;
    }
  };
  const onPostAutoInvestSettingsError = () => {
    loadingConnectStrategy.value = false;
    if (connectStrategyCallback.value) {
      connectStrategyCallback.value();
      connectStrategyCallback.value = null;
    }
  };

  const onClickShowCondition = ({ condition, name }) => {
    message.value = condition;
    const regexp = />(.*?)</g;
    let matchAll = name.matchAll(regexp);
    matchAll = Array.from(matchAll);
    messageTitle.value = matchAll[0][1];
  };

  const onClickShowFutureRateDesc = () => {
    message.value = FUTURE_RATE_INFO;
    messageTitle.value = 'что такое ожидаемая доходность?';
  };

  const onCloseMessage = () => {
    message.value = null;
    messageTitle.value = null;
    loading.value = false;
  };

  return {
    loading,
    loadingConnectStrategy,
    loadingInited,
    message,
    messageTitle,
    selectedStrategy,
    strategies,
    settingsId,
    confirmErrorMessage,
    confirmMessage,
    connectStrategyCallback,
    choosedStrategyForConnect,
    isShowModalConfirm,
    selectedEnhancedDiversification,
    isShowDynamicModals,
    getStrategies,
    getBonusFromBalance,
    chooseStrategy,
    getAutoInvestSettingsAsync,
    connectStrategy,
    onClickShowCondition,
    onClickShowFutureRateDesc,
    onCloseMessage,
    selectedForDynamicModalsStrategy,
    newCompanyId,
  };
}
