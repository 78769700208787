export default class Pipe {
  constructor(fulfilled, rejected) {
    this[0] = fulfilled;
    this[1] = rejected;
    this.length = 2;
  }
  static from(fulfilled, rejected) {
    return new Pipe(fulfilled, rejected);
  }
}
