<template>
  <section class="settings-investors">
    <SettingsInvestorsItem
      v-for="item in companiesRoot"
      :key="item.id"
      :item="item"
      class="settings-investors-item mb-7"
    />
    <PotokButton
      v-if="isUserLoaded && isFirstIncomeAt"
      size="large"
      text="добавить инвестора"
      @click="goAddCompany"
    />
  </section>
</template>
<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import SettingsInvestorsItem from '../../components/Settings/SettingsInvestors/SettingsInvestorsItem.vue';

import { useStateStore } from '@/store/stateStore';

const router = useRouter();
const store = useStateStore();

const isFirstIncomeAt = computed(() => {
  return store.isFirstIncomeAt;
});
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const companiesRoot = computed(() => {
  return store.companiesRoot;
});
const goAddCompany = () => {
  router.push({
    name: 'investorAdd',
  });
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.settings-investors {
  .settings-investors-item {
    @media (max-width: $size_767) {
      margin-bottom: 20px;
    }
  }
}
</style>
