<template>
  <section>
    <PotokMessage>
      <div v-html="props.message"></div>
    </PotokMessage>
  </section>
</template>
<script setup>
import PotokMessage from '@/components/_generic/PotokMessage.vue';

const props = defineProps({
  message: {
    type: String,
    default: '',
  },
});
</script>
<style lang="scss" scoped></style>
