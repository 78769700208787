export default {
  postProjects: {
    url: `/api/projects`,
    method: 'POST',
    body: {
      companyId: null,
      loanWantedAmount: 0,
      loanTerm: 0,
      tenderNumber: '',
      tenderLaw: '',
    },
  },
  getProjectsRisingFunds: {
    url: `/api/projects/rising-funds`,
    method: 'GET',
  },
  getProjectsOverdue: {
    url: `/api/projects/overdue`,
    method: 'GET',
  },
  getProjectIdNew: {
    url: `/api/projects/:id/new`,
    method: 'GET',
  },
  getProjectsTotalIncome: {
    url: `/api/projects/total-income`,
    method: 'GET',
  },
  getProjectsMyInvestments: {
    url: `/api/projects/my/investments`,
    method: 'GET',
  },
  getProjectsMyInvestmentsCount: {
    url: `/api/projects/my/investments/count`,
    method: 'GET',
  },
  postProjectInvestmentCreate: {
    url: `/api/projects/investment/create`,
    method: 'POST',

    body: {
      projectId: null,
      companyId: null,
      amount: 0,
    },
  },
  postProjectInvestmentSign: {
    url: `/api/projects/investment/sign`,
    method: 'POST',
    body: {
      investmentId: null,
    },
  },
  postProjectInvestmentConfirm: {
    url: `/api/projects/investment/confirm`,
    method: 'POST',
    body: {
      id: null,
    },
  },
  postProjectCancelInvestmentSign: {
    url: `/api/projects/investment/cancel/sign`,
    method: 'POST',
    body: {
      investmentId: null,
    },
  },
  postProjectCancelInvestmentConfirm: {
    url: `/api/projects/investment/cancel/confirm`,
    method: 'POST',
    body: {
      investmentId: null,
    },
  },
  getProjects$IdAccept$InvestmentId: {
    url: `/api/projects/:id/accept/:investmentId`,
    method: 'GET',
  },
  postProjectsCancelSign: {
    url: `/api/projects/cancel/sign`,
    method: 'POST',
    body: {
      id: null,
    },
  },
  postProjectsCancelConfirm: {
    url: `/api/projects/cancel/confirm`,
    method: 'POST',
    body: {
      id: null,
      code: '',
    },
  },
  postProjectsAcceptConfirm: {
    url: `/api/projects/accept/confirm`,
    method: 'POST',
    body: {
      id: null,
      code: '',
    },
  },
  getProjectsCessionContracts$InvestmentId: {
    url: `/api/projects/cession-contracts/:investmentId`,
    method: 'GET',
  },
  getProjectsLogPublic: {
    url: `/api/projects/log/public`,
    method: 'GET',
  },
  getIndividualStatement: {
    url: `/api/projects/my/investments/individual-statement`,
    method: 'POST',
  },
  getProjectsMyInvestmentsCountAll: {
    url: `/api/projects/my/investments/count/all`,
    method: 'GET',
  },
  getProjectsRaisingStats: {
    url: `/api/platform/statistics/projects`,
    method: 'GET',
  },
};
