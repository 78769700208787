import { ref } from 'vue';
import contants from '@/constants.js';

export function diversificationUtils() {
  const isEnhancedDiversification = ref(null);
  const isShowModalConfirmDiversification = ref(false);
  const optionsDiversification = ref({});

  const isSelectedStrategyInSecondMarket = (strategyName) => {
    return contants.STRATEGY_SECOND_MARKET.includes(strategyName);
  };
  /**
   *
   * @param {*} options
   *  selectedStrategy: текущая выбранная стратегия,
   *  isEnhancedDiversification: текущий уровень диверсикации,
   *  isConnectButton: признак нажатия на кнопке подключить стратегию
   */
  const showModalDiversification = (options) => {
    optionsDiversification.value = options;
    // открываем модальное окно
    isShowModalConfirmDiversification.value = true;
  };

  return {
    isEnhancedDiversification,
    isShowModalConfirmDiversification,
    optionsDiversification,
    showModalDiversification,
    isSelectedStrategyInSecondMarket,
  };
}
