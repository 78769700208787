import { formatNumber, formatPhone } from '@/utils/commonUtils/utils';

export const convertRawPhoneToLogin = (phone) => {
  const sanitized = formatNumber(phone);
  return sanitized.length === 10 ? '7' + sanitized : sanitized;
};

export const decorateFormatPhone = (phone) => {
  if (phone.length === 10) {
    return formatPhone('7' + phone);
  }
  return '+7' + phone;
};
