import { formatCurrency, formatDate } from '@/utils/commonUtils/utils';
import {
  showPlus,
  contentInvestmentDesktop,
  contentInvestmentMobile,
} from './investmentsColumnsUtils.js';

export const ColumnsTotalRestructured = (items) => {
  let myInvestmentAmount = 0;
  let myInvestmentRepayment = 0;
  let myInvestmentIncome = 0;
  let myInvestmentDebt = 0;
  items.forEach((i) => {
    myInvestmentAmount += i.myInvestmentAmount;
    myInvestmentRepayment += i.myInvestmentRepayment;
    myInvestmentIncome += i.myInvestmentIncome;
    myInvestmentDebt += i.myInvestmentDebt;
  });
  return {
    mobileTitle: 'Всего по реструктурированным инвестициям',
    mobileStyle: {
      myInvestmentIncome: {
        color: '#04CCD9',
      },
    },
    myInvestmentAmount: formatCurrency(myInvestmentAmount, 0) + ' ₽',
    myInvestmentRepayment: formatCurrency(myInvestmentRepayment, 0) + ' ₽',
    myInvestmentIncome: `${myInvestmentIncome > 0 ? '+' : ''} ${
      formatCurrency(myInvestmentIncome, 0) + ' ₽'
    }`,
    myInvestmentDebt: formatCurrency(myInvestmentDebt, 0) + ' ₽',
  };
};

export const ColumnsRestructured = [
  {
    title: 'Инвестиция',
    title_mobile: ' ',
    is_mobile_full: true,
    th_desktop_style: {
      'padding-left': '20px',
    },
    content_desktop(item) {
      return contentInvestmentDesktop(item);
    },
    content_mobile(item) {
      return contentInvestmentMobile(item);
    },
  },
  {
    title: 'Ставка',
    sort_slug: 'stavka',
    sort: true,
    content_desktop(item) {
      const isBuyback =
        item.purchaseDeals && item.purchaseDeals.length > 0 ? true : false;
      const loanRate = isBuyback
        ? item.purchaseDeals[0].purchasedInvestmentRate
        : item.loanRate * 100;
      return `
        ${formatCurrency(loanRate, 2)} %
      `;
    },
  },
  {
    title: 'Дата инвест.',
    title_mobile: 'Дата инвестирования',
    sort: true,
    sort_slug: 'invest_date',
    content_desktop(item) {
      return `
      ${formatDate(item.loanFundedAt, 'dd.MM.yyyy')}
      `;
    },
  },
  {
    slug: 'myInvestmentAmount',
    title: 'Вложено',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.myInvestmentAmount, 0) + ' ₽'}
      `;
    },
  },
  {
    slug: 'myInvestmentRepayment',
    title: 'Погашено',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.myInvestmentRepayment, 0) + ' ₽'}
      `;
    },
  },
  {
    slug: 'myInvestmentIncome',
    title: 'Доход',
    th_desktop_style: {
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      <div ${
        showPlus(item.myInvestmentIncome) ? ' style="color: #04CCD9;"' : ''
      }">
      ${showPlus(item.myInvestmentIncome)}
      ${formatCurrency(item.myInvestmentIncome, 0)}
      ₽
      </div>
      `;
    },
  },
  {
    slug: 'myInvestmentDebt',
    title: 'Остаток осн. долга',
    sort: true,
    sort_slug: 'osd',
    th_desktop_style: {
      'padding-right': '15px',
      'text-align': 'left',
      'justify-content': 'flex-start',
    },
    td_desktop_style: {
      'padding-right': '15px',
      'text-align': 'left',
    },
    content_desktop(item) {
      return `
      ${formatCurrency(item.myInvestmentDebt, 0) + ' ₽'}
      `;
    },
  },
];
