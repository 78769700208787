<template>
  <ModalFull :title="props.title" @close="close">
    <template #content>
      <div class="subtitle" v-html="props.confirmMessage"></div>

      <two-factor-auth
        :phone="login"
        :request="request"
        :loading="loading"
        input-letter-spacing
        :authentication="authentication"
        :error-message="props.errorMessage"
        @on-enter="onEnter"
        @close="close"
      >
        <PotokButton
          :disabled="isPending"
          :loading="props.loading"
          text="подписать"
          size="large"
          full-width-desktop
          @click="postConfirmAsync"
        />
      </two-factor-auth>
    </template>
  </ModalFull>
</template>

<script setup>
import { computed, reactive, onMounted } from 'vue';

import useVuelidate from '@vuelidate/core';
import validators from '@/validators';

import TwoFactorAuth from '@/components/_generic/TwoFactorAuth.vue';
import TwoFactorAuthentication from '@/components/_generic/TwoFactorAuthentication';
import { focusDomElementById } from '@/utils/commonUtils/utils';
import { useStateStore } from '@/store/stateStore';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { PotokButton } from 'potok-uikit';

const emit = defineEmits(['close']);
const props = defineProps({
  title: {
    type: String,
    default: 'Подписание документа',
  },
  confirmFunction: {
    type: Function,
  },
  request: {
    type: Function,
    required: false,
  },
  errorMessage: {
    type: String,
    required: false,
  },
  confirmMessage: {
    type: String,
    default: 'Подтвердите ваше действие',
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const rulesValidations = {
  authentication: {
    smsCode: {
      required: validators.required,
      exactLength: validators.minLength(4),
      numeric: validators.numeric,
    },
  },
};

const store = useStateStore();
const authentication = reactive(new TwoFactorAuthentication());
const v$ = useVuelidate(rulesValidations, { authentication: authentication });

const steps = computed(() => {
  return TwoFactorAuthentication.steps;
});
const isPending = computed(() => {
  return v$.value.authentication.smsCode.$invalid || props.loading;
});
const login = computed(() => {
  return store.state.session.login;
});

onMounted(() => {
  authentication.setStep(steps.value.ACTIVE);
  focusDomElementById('smsCode');
});

const close = () => {
  emit('close');
};
const postConfirmAsync = () => {
  props.confirmFunction({
    code: authentication.smsCode,
  });
};

const onEnter = () => {
  if (isPending.value) {
    return;
  }
  postConfirmAsync();
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

:deep(.modal_block_container) {
  max-width: 440px;
}
.modal-container {
  min-width: 426px;
  background: #ffffff;
  border-radius: 6px;
  @media (max-width: $size_767) {
    min-width: 200px;
  }
}
:deep(.modal_title) {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  width: 100%;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: none;
  color: $grey;
  margin-bottom: 20px;
}
.button {
  width: auto;
  height: auto;
  line-height: initial;
}
</style>
