<template>
  <div class="balances_strategy">
    <div>
      <template v-if="loadingAutoInvest || loadingStrategies">
        <Loader />
      </template>
      <template v-if="!loadingAutoInvest && !loadingStrategies">
        <div class="content_title">
          <div>
            <div class="content_title_text">СТРАТЕГИЯ</div>
            <p
              v-if="constants.strategesTitle[currentStrategyAutoInvest]"
              class="content_main_value strategy-name"
            >
              {{ constants.strategesTitle[currentStrategyAutoInvest] }}
            </p>
            <p v-if="!isAutoInvest" class="content_main_value strategy-name">
              не выбрана
            </p>
          </div>
          <div>
            <i class="icon_active_investments"></i>
          </div>
        </div>

        <div class="content_block_items">
          <div class="strategy-description">
            <p class="potok-text-body-1 potok-color-grey">
              {{ getStrategiesDescription }}
            </p>
          </div>
          <PotokDivider margin-y="12" />
          <div v-if="isHasiversification" class="strategy-diversification">
            <div class="potok-text-body-2 potok-color-grey mt-2">
              диверсификация
            </div>
            <div
              v-if="!isEnhancedDiversification"
              class="potok-text-body-1 potok-color-deepdive mt-2"
            >
              стандартная
            </div>
            <div
              v-if="isEnhancedDiversification"
              class="potok-text-body-1 potok-color-deepdive mt-2"
            >
              усиленная
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="mt-3">
      <button
        v-if="isAutoInvest"
        type="button"
        class="button button-secondary-outline button-balances"
        @click="onClickAutoinvestSettings"
      >
        настроить стратегию
      </button>
      <button
        v-if="!isAutoInvest"
        :disabled="loadingAutoInvest"
        type="button"
        class="button button-secondary-outline button-balances"
        @click="onClickAutoInvestSwitcher"
      >
        включить АИ
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStateStore } from '@/store/stateStore';
import { Loader } from 'potok-uikit';

import server from '@/server';
import tracker from '@/tracker';
import { formatCurrency } from '@/utils/commonUtils/utils';
import constants from '@/constants';

const store = useStateStore();
const router = useRouter();

const loadingAutoInvest = ref(true);
const loadingStrategies = ref(true);
const currentStrategyAutoInvest = ref(null);
const isEnhancedDiversification = ref(false);
const strategy = ref({});

const isAutoInvest = computed(
  () => currentStrategyAutoInvest.value !== 'manual',
);
const selectedCompany = computed(() => store.selectedCompany);
const selectedCompanyId = computed(() => store.selectedCompanyId);

const getStrategiesDescription = computed(() => {
  if (
    currentStrategyAutoInvest.value === 'without_risk' &&
    strategy.value.without_risk
  ) {
    return `средства инвестируются по ставке ${createRateText(
      strategy.value.without_risk?.currentRate?.rate,
    )} годовых, дефолты выкупаются Потоком`;
  }
  if (
    currentStrategyAutoInvest.value === 'conservative' &&
    strategy.value.conservative
  ) {
    return `свободные средства инвестируются в займы пониженного риска по ставке ${createRateText(
      strategy.value.conservative?.currentRate?.rate,
    )} годовых`;
  }
  if (
    currentStrategyAutoInvest.value === 'fixed_fair_rate' &&
    strategy.value.fixed_fair_rate
  ) {
    return `свободные средства инвестируются по ставке ${createRateText(
      strategy.value.fixed_fair_rate?.currentRate?.rate,
    )} годовых`;
  }
  if (
    currentStrategyAutoInvest.value === 'liquid_fair_rate' &&
    strategy.value.liquid_fair_rate
  ) {
    return `средства распределяются в инвестиции с погашением до 6 месяцев по ставке ${createRateText(
      strategy.value.liquid_fair_rate?.currentRate?.rate,
    )} годовых`;
  }
  if (
    currentStrategyAutoInvest.value === 'alfa_liquid' &&
    strategy.value.alfa_liquid
  ) {
    return `средства распределяются в инвестиции с погашением до 6 месяцев по ставке ${createRateText(
      strategy.value.alfa_liquid?.currentRate?.rate,
    )} годовых`;
  }
  if (
    currentStrategyAutoInvest.value === 'alfa_basic' &&
    strategy.value.alfa_basic
  ) {
    return 'инвестиции в заемщиков без повышенного риска';
  }
  if (currentStrategyAutoInvest.value === 'default_borrower_distribution') {
    return 'свободные средства распределяются в новые займы';
  }
  if (currentStrategyAutoInvest.value === 'personal_borrower_distribution') {
    return 'автоинвестирование работает в соответствии с заданными вами настройками';
  }
  if (currentStrategyAutoInvest.value === 'aggressive') {
    return 'инвестиции в заемщиков по максимальной ставке';
  }
  if (currentStrategyAutoInvest.value === 'manual') {
    return 'свободные деньги не инвестируются';
  }
  return null;
});

const isHasiversification = computed(() => {
  if (
    constants.STRATEGY_SECOND_MARKET.includes(currentStrategyAutoInvest.value)
  ) {
    return true;
  }
  return false;
});

const createRateText = (rate) => {
  const finalRate = rate * 100;
  let digits = Number.isInteger(finalRate) ? 0 : 1;
  // Если finalRate при умножении получило не корректное значение типа "28.0000000000000004", проверяем это и фиксим digits
  if (digits === 1) {
    const fixedRate = Number(finalRate.toFixed(1));
    if (Number.isInteger(fixedRate)) {
      digits = 0;
    }
  }
  return `${formatCurrency(finalRate, digits)}%`;
};

const getAutoInvestSettingsAsync = () => {
  if (!selectedCompany.value) {
    return;
  }
  loadingAutoInvest.value = true;
  return server.getCompanyAutoInvestSettings
    .send(null, { params: { id: selectedCompany.value.id } })
    .pipe(onGetAutoInvestSettingsSuccess, onGetAutoInvestSettingsError)
    .exec();
};
const onGetAutoInvestSettingsSuccess = (obj) => {
  if (obj.response.status === 204) {
    currentStrategyAutoInvest.value = 'manual';
    loadingAutoInvest.value = false;
    return;
  }

  const data = obj.data || {};
  currentStrategyAutoInvest.value = data.strategy;
  isEnhancedDiversification.value = data.isEnhancedDiversification;

  loadingAutoInvest.value = false;
};
const onGetAutoInvestSettingsError = () => {
  loadingAutoInvest.value = false;
};
const onClickAutoinvestSettings = () => {
  const obj = {
    name: 'autoInvest',
  };
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Autoinvest_Setting');
  router.push(obj);
};
const onClickAutoInvestSwitcher = () => {
  const obj = {
    name: 'autoInvest',
  };
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Autoinvest_Tune_On');
  router.push(obj);
};

const getStrategiesAsync = () => {
  loadingStrategies.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };
  return server.getStrategies
    .send(query)
    .pipe(
      (obj) => {
        loadingStrategies.value = false;
        strategy.value = obj.data || {};
      },
      () => {
        loadingStrategies.value = false;
      },
    )
    .exec();
};

watch(selectedCompanyId, () => {
  getAutoInvestSettingsAsync();
  if (selectedCompanyId.value) {
    getStrategiesAsync();
  }
});

if (selectedCompanyId.value) {
  getAutoInvestSettingsAsync();
  getStrategiesAsync();
}
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.balances_strategy {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  button {
    text-transform: none;
  }
  @media (max-width: $size_767) {
    padding-bottom: 10px;
  }

  .content_block_items {
    color: $grey;
    text-transform: none;
    @media (max-width: $size_767) {
      margin-top: 18px;
    }
  }
}

.button-balances {
  position: absolute;
  bottom: 10px;
}
.strategy-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  text-transform: initial;
}
.strategy-description {
  min-height: 121px;
}
.strategy-diversification {
  padding: 14px 0;
  position: absolute;
  bottom: 70px;
  left: 0;
}
</style>
