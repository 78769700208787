<template>
  <div class="block-menu-mobile">
    <div class="block-menu-mobile-item">
      <CSelect
        v-model="localSelectedComponent"
        :items="menuItems()"
        @update:model-value="changeTab()"
      >
      </CSelect>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';

import { CSelect } from 'potok-uikit';
import constants from '@/constants';

const props = defineProps({
  currentComponent: {
    type: String,
    required: true,
  },
  statusId: {
    type: String,
  },
});
const emit = defineEmits(['changeTab']);
const localSelectedComponent = ref();

const menuItems = () => {
  const items = [{ value: 'LoanParams', title: 'параметры займа' }];
  if (
    hasStatus(
      constants.projectStatus.cession1st,
      constants.projectStatus.cession2nd,
    )
  ) {
    items.push({ value: 'LoanOverdue', title: 'события цессии' });
  }
  items.push({ value: 'LoanAboutBorrower', title: 'о заемщике' });
  items.push({ value: 'LoanHistory', title: 'история в потоке' });
  return items;
};

const changeTab = () => {
  emit('changeTab', localSelectedComponent.value);
};
const hasStatus = (...ids) => {
  return ids.indexOf(props.statusId) !== -1;
};

onMounted(() => {
  localSelectedComponent.value = props.currentComponent;
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.block-menu-mobile {
  width: 100%;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
  .block-menu-select {
    height: 44px;
    margin: 14px 0;
    font-size: 14px;
    line-height: 24px;
    background-color: $grey-primary;
    border: 1px solid $grey-border;
    box-sizing: border-box;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@img/arrow-down-grey-default.svg') !important;
    background-position: right 15px center;
    background-repeat: no-repeat;
  }

  .block-menu-mobile-item {
    width: 100%;
  }
}
</style>
