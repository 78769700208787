<template>
  <PotokTable :columns="columns" :rows="props.rows">
    <template #body="props">
      <PotokTableRow hover class="loans-first-table-row">
        <PotokTableCell>
          {{ 'Picture' }}
        </PotokTableCell>

        <PotokTableCell>
          {{ props.row.borrower.shortName }}
        </PotokTableCell>

        <PotokTableCell>
          {{
            createRateText(props.row.loanRate) +
            ' / ' +
            createRateText(props.row.loanRate) +
            '%'
          }}
        </PotokTableCell>

        <PotokTableCell>
          {{ props.row.rating }}
        </PotokTableCell>

        <PotokTableCell>
          {{ props.row.loanAmount }}
        </PotokTableCell>

        <PotokTableCell>
          {{ 0 }}
        </PotokTableCell>

        <PotokTableCell>
          {{ props.row.isExperiencedBorrower ? 'опытный' : 'впервые' }}
        </PotokTableCell>

        <PotokTableCell class="loans-first-table-goal">
          <span class="loans-first-table-goal-text">развитие</span>
          <PotokButton
            text="инвестировать"
            class="loans-first-table-btn"
            @click="toInvestmentPage(props.row.id)"
          />
        </PotokTableCell>
      </PotokTableRow>
    </template>
  </PotokTable>
</template>

<script setup>
import { useRouter } from 'vue-router';

import PotokTable from '@/components/_generic/table/PotokTable/PotokTable.vue';
import PotokTableCell from '@/components/_generic/table/PotokTable/PotokTableCell.vue';
import PotokTableRow from '@/components/_generic/table/PotokTable/PotokTableRow.vue';

import { createRateText } from '@/utils/strategies/strategiesUtils';

const router = useRouter();

const props = defineProps(['rows']);

const columns = [
  {
    label: 'заемщик',
  },
  {
    label: 'ставка',
    dropdownMessage: 'номинальная / эффективная',
  },
  {
    label: 'сумма займа',
  },
  {
    label: 'остаток сбора',
  },
  {
    label: 'опыт в потоке',
  },
  {
    label: 'цель займа',
  },
];

const toInvestmentPage = (id) => {
  router.push({ name: 'loanInvestment', params: { id } });
};
</script>

<style lang="scss" scoped>
.loans-first-table-goal {
  display: flex;
  align-items: center;
}
.loans-first-table-goal-text {
  display: initial;
  height: fit-content;
}
.loans-first-table-goal .loans-first-table-btn {
  opacity: 0;
}

.loans-first-table-row {
  &:hover {
    .loans-first-table-goal-text {
      display: none;
    }
    .loans-first-table-btn {
      opacity: 1;
    }
  }
}
</style>
