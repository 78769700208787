<template>
  <div class="loan-history">
    <template v-if="loadingBorrowerHistory">
      <Loader class="loader" />
    </template>

    <template v-else>
      <GeneralTable
        :items="sortedItems"
        :columns="columnsHistory"
        :sort-column="sortColumn"
        :sort-column-direction="sortColumnDirection"
        class="table-history"
        @select-sort-column="selectSortColumn"
      />
      <p class="table-history-footer potok-text-body-2">
        всего {{ items.length }}
        {{ pluralize(items.length, ['займ', 'займа', 'займов']) }} на сумму
        <span>{{ generalSum }} ₽</span>, из них возвращено
        {{ returnedItem.count }}
        {{ pluralize(returnedItem.count, ['займ', 'займа', 'займов']) }} на
        <span>{{ returnedItem.amount }} ₽</span>.
        <br />
        остаток основного долга –
        <span>{{ generalBalanceOfMainDebt }} ₽</span>
      </p>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

import server from '@/server';
import { Loader } from 'potok-uikit';
import GeneralTable from '@/components/_generic/table/GeneralTable.vue';
import {
  formatCurrency,
  formatDate,
  pluralize,
} from '@/utils/commonUtils/utils';
import constants from '@/constants';

const props = defineProps(['project']);

const loadingBorrowerHistory = ref(false);
const items = ref([]);
const sortColumn = ref('fundedAt');
const sortColumnDirection = ref('down');

// columns start
const getStatusClass = (statusId) => {
  return constants.projectStatusClass[statusId];
};

const historyStatus = (status) => {
  return `
  <div class="${getStatusClass(status)} project__tag">
    <span class="project__tag-icon"
      >${getStatusTitle(status)}
    </span>
  </div>
  `;
};

const columnsHistory = [
  {
    title: 'ID займа',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'number',
    content_desktop(item) {
      if (item.projectId) {
        return `<a href="/loans/${item.projectId}" target="_blank">${item.number}</a>`;
      }
      return `<span>${item.number}</span>`;
    },
  },
  {
    title: 'Статус',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'statusId',
    content_desktop(item) {
      return `
      ${historyStatus(item.statusId)}
      `;
    },
  },
  {
    title: 'Дата выдачи',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'fundedAt',
    content_desktop(item) {
      return `
      ${formatDate(item.fundedAt)}
      `;
    },
  },
  {
    title: 'Сумма',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'amount',
    content_desktop(item) {
      return `
      <span>${formatCurrency(item.amount, 0)} </span>
                    ₽
      `;
    },
  },
  {
    title: 'Ставка',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'rate',
    content_desktop(item) {
      return `
      <span>${formatCurrency(item.rate * 100, 2)} % </span>

      `;
    },
  },
  {
    title: 'Срок',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'term',
    content_desktop(item) {
      return `
      <span>
      ${item.term + ' ' + pluralize(item.term, ['день', 'дня', 'дней'])}
      </span>

      `;
    },
  },
  {
    title: 'Остаток ОД',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'balanceOfMainDebt',
    content_desktop(item) {
      return `
      <span>${formatCurrency(item.balanceOfMainDebt, 0)} </span> ₽
      `;
    },
  },
  {
    title: 'Кол-во просрочек',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'countOfOverdues',
    content_desktop(item) {
      return `
      <span>
      ${
        item.countOfOverdues +
        ' ' +
        pluralize(item.maxOverdue, ['раз', 'раз', 'раз'])
      }
      </span>

      `;
    },
  },
  {
    title: 'Макс просрочка',
    is_mobile_full: true,
    sort: true,
    sort_slug: 'maxOverdue',
    content_desktop(item) {
      return `
      <span>
        ${item.maxOverdue + ' ' + pluralize(item.maxOverdue, ['день', 'дня', 'дней'])}
      </span>
      `;
    },
  },
];

// columns end

const generalBalanceOfMainDebt = computed(() => {
  let sum = 0;
  items.value.forEach((item) => {
    sum += item.balanceOfMainDebt;
  });
  return formatCurrency(sum, 0);
});
const generalSum = computed(() => {
  let sum = 0;
  items.value.forEach((item) => {
    sum += item.amount;
  });
  return formatCurrency(sum, 0);
});
const returnedItem = computed(() => {
  let count = 0;
  let amount = 0;
  items.value.forEach((item) => {
    if (item.statusId === constants.PROJECT__STATUS__REFUNDED) {
      count += 1;
      amount += item.amount;
    }
  });
  return {
    count,
    amount: formatCurrency(amount, 0),
  };
});
const sortedItems = computed(() => {
  const _items = JSON.parse(JSON.stringify(items.value));
  return sortItems(_items);
});

onMounted(() => {
  getBorrowerHistoryAsync();
});

const getBorrowerHistoryAsync = () => {
  if (!props.project || !props.project.borrower || !props.project.borrower.id) {
    return;
  }
  loadingBorrowerHistory.value = true;
  server.getBorrowerHistory
    .send(null, { params: { borrowerId: props.project.borrower.id } })
    .pipe(onGetBorrowerHistoryAsyncSuccess, () => {
      loadingBorrowerHistory.value = false;
    })
    .exec();
};
const onGetBorrowerHistoryAsyncSuccess = ({ data }) => {
  loadingBorrowerHistory.value = false;
  items.value = data;
};
const sortItems = (items) => {
  if (!sortColumn.value) {
    return items;
  }
  return sortItemsLogic(items, {
    sortColumn: sortColumn.value,
    sortColumnDirection: sortColumnDirection.value,
  });
};

const getStatusTitle = (statusId) => {
  return constants.projectStatusTitle[statusId];
};

const sortItemsLogic = (items, options) => {
  const sortColumn = options.sortColumn;
  const sortColumnDirection = options.sortColumnDirection;

  const sortNumWithDirection = (a, b) => {
    if (sortColumnDirection === 'up') {
      return a - b;
    } else {
      return b - a;
    }
  };

  const sortStrWithDirection = (a, b) => {
    if (sortColumnDirection === 'up') {
      if (a > b) {
        return -1;
      } else {
        return 1;
      }
    } else {
      if (a < b) {
        return -1;
      } else {
        return 1;
      }
    }
  };

  return items.sort((a, b) => {
    if (sortColumn === 'fundedAt') {
      return sortNumWithDirection(
        new Date(a[sortColumn]),
        new Date(b[sortColumn]),
      );
    } else if (sortColumn === 'statusId') {
      return sortStrWithDirection(
        getStatusTitle(a[sortColumn]),
        getStatusTitle(b[sortColumn]),
      );
    } else {
      return sortNumWithDirection(a[sortColumn], b[sortColumn]);
    }
  });
};

const selectSortColumn = (data) => {
  if (
    !sortColumn.value ||
    (sortColumn.value && sortColumn.value != data.sort_slug)
  ) {
    sortColumn.value = data.sort_slug;
    sortColumnDirection.value = 'up';
  } else if (
    sortColumn.value === data.sort_slug &&
    sortColumnDirection.value === 'up'
  ) {
    sortColumnDirection.value = 'down';
  } else if (
    sortColumn.value === data.sort_slug &&
    sortColumnDirection.value === 'down'
  ) {
    sortColumn.value = null;
    sortColumnDirection.value = 'up';
  }
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loan-history {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px #e5e9f2;
  border-radius: 6px;
  margin: 30px 0;
  padding: 20px 20px 20px 20px;

  @media (max-width: $size_767) {
    padding: 16px;
  }
}
.loader {
  margin: 20px;
}
.project__tag {
  @media (max-width: $size_767) {
    margin: 0;
  }
  .project__tag-icon {
    @media (max-width: $size_767) {
      margin: 0 !important;
    }
  }
}
.table-history {
  width: 100%;
}
.table-history-footer {
  margin-top: 20px;
  font-style: normal;
  color: $grey-second;
  span {
    color: $black;
    font-weight: 600;
  }
}
</style>
