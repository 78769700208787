<template>
  <div class="profitable-content-items">
    <AnalyticsAssetsProfitableResult :data="props.data" />
    <AnalyticsAssetsProfitableProfit :data="props.data" />
    <AnalyticsAssetsProfitableCleanProfit :data="props.data" />
  </div>
</template>
<script setup>
import AnalyticsAssetsProfitableResult from './AnalyticsAssetsProfitableResult.vue';
import AnalyticsAssetsProfitableProfit from './AnalyticsAssetsProfitableProfit.vue';
import AnalyticsAssetsProfitableCleanProfit from './AnalyticsAssetsProfitableCleanProfit.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});
</script>
<style lang="scss" scoped>
.profitable-content-items {
  display: flex;
  justify-content: space-between;
}
</style>
