<template>
  <div>
    <ModalFull :title="props.title" @close="close">
      <template #content>
        <div class="block-menu-mobile-item">
          <CSelect
            v-model="selectedSortField"
            placeholder="Сортировать"
            :items="sortFields"
          />
          <InvestmentsFilterTableComponent
            :status="props.status"
            :filters="props.filters"
            @update-filters="updateFilters"
          />
        </div>
      </template>
      <template #actions>
        <div>
          <button
            type="button"
            class="button button-secondary-outline"
            @click="applyFilters"
          >
            Применить
          </button>
          <button
            type="button"
            class="button button-white"
            @click="resetFilters"
          >
            Сбросить
          </button>
        </div>
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import InvestmentsFilterTableComponent from '../../InvestmentsFilterTable/InvestmentsFilterTableComponent.vue';
import { CSelect } from 'potok-uikit';
import { STATUSES } from '../../../../../constants/Investments/investmentsConstants.js';

const SORTING_FIELD_ALL = [
  {
    title: 'сортировка по умолчанию',
    value: 'default',
  },
  {
    title: 'от большей ставки к меньшей',
    value: 'stavka_plus',
  },
  {
    title: 'от меньшей ставки к большей',
    value: 'stavka_minus',
  },
  {
    title: 'от большей даты инвест. к меньшей',
    value: 'invest_date_plus',
  },
  {
    title: 'от меньшей даты инвест. к большей',
    value: 'invest_date_minus',
  },
  {
    title: 'по дате возврата — убывание',
    value: 'vozvrat_plus',
  },
  {
    title: 'по дате возврата — возрастание',
    value: 'vozvrat_minus',
  },
  {
    title: 'от большего остатка к меньшему',
    value: 'osd_plus',
  },
  {
    title: 'от меньшего остатка к большему',
    value: 'osd_minus',
  },
];

const SORTING_FIELD_REFUNDED = [
  {
    title: 'сортировка по умолчанию',
    value: 'default',
  },
  {
    title: 'от большей ставки к меньшей',
    value: 'stavka_plus',
  },
  {
    title: 'от меньшей ставки к большей',
    value: 'stavka_minus',
  },
  {
    title: 'от большей даты инвест. к меньшей',
    value: 'invest_date_plus',
  },
  {
    title: 'от меньшей даты инвест. к большей',
    value: 'invest_date_minus',
  },
];

const props = defineProps(['title', 'status', 'filters', 'mobileSortField']);
const emit = defineEmits(['close', 'updateSortField']);

const selectedSortField = ref('default');
const reactiveData = reactive({
  localFilters: {},
});

const sortFields = computed(() => {
  switch (props.status) {
    case STATUSES.funded:
      return SORTING_FIELD_ALL;
    case STATUSES.restructured:
      return SORTING_FIELD_ALL;
    case STATUSES.refunded:
      return SORTING_FIELD_REFUNDED;
    case STATUSES.softOverdue:
      return SORTING_FIELD_ALL;
    case STATUSES.default:
      return SORTING_FIELD_REFUNDED;
  }
  return SORTING_FIELD_ALL;
});
onMounted(() => {
  document.body.style.overflow = 'hidden';
});
onUnmounted(() => {
  document.body.style.overflow = null;
});

const close = () => {
  emit('close');
};
const updateFilters = (filterForm) => {
  reactiveData.localFilters = filterForm;
};
const applyFilters = () => {
  emit('close', {
    sortField: selectedSortField.value,
    filters: reactiveData.localFilters,
  });
};
const resetFilters = () => {
  reactiveData.localFilters = {};
  selectedSortField.value = 'default';
  emit('close', {
    sortField: selectedSortField.value,
    filters: reactiveData.localFilters,
  });
};
</script>
<style lang="scss" scoped>
@use '../../../../../scss/variables.scss' as *;

.button {
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.button-white {
  margin-top: 10px;
}
</style>
