import { parseISO } from 'date-fns';

export const getDate = (date) => {
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let monthDate = date.getMonth() + 1;
  if (monthDate < 10) {
    monthDate = `0${monthDate}`;
  }
  const yearDate = date.getFullYear();
  return `${yearDate}-${monthDate}-${day}`;
};

export const getDateSince = (dateSince) => {
  const stringDateSince = getDate(dateSince);
  const dateSinceToMoscowTimezone = `${stringDateSince}T00:00:00.000+0300`;
  return parseISO(dateSinceToMoscowTimezone).toISOString();
};

export const getDateTo = (dateTo) => {
  const stringDateTo = getDate(dateTo);
  const dateToMoscowTimezone = `${stringDateTo}T23:59:59.999+0300`;
  return parseISO(dateToMoscowTimezone).toISOString();
};
