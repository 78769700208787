<template>
  <section class="investments-sell-item page mt-7">
    <Loader v-if="isLoading" />

    <template v-else>
      <!-- header -->
      <div class="investments-sell-item-header mb-7">
        <div
          class="investments-sell-item-header-created-at potok-text-body-2 mb-1"
        >
          создана {{ sellItemCreatedAt }}
        </div>
        <div class="investments-sell-item-header-bottom">
          <div class="investments-sell-item-header-title potok-text-h1">
            {{ isTablet ? 'заявка' : 'заявка на продажу' }}
            {{ sellItem?.number }}
          </div>
          <!-- оферта на десктопе -->
          <div
            v-if="!isTablet && sellItem.documentId"
            class="investments-sell-item-header-offer-download potok-text-body-1"
            @click="downloadOffer"
          >
            <i class="icon-download" />
            оферта на продажу
          </div>
          <!-- статус на мобилке за место оферты -->
          <NewInvestmentsSellListBtnStatus
            v-if="isTablet"
            :status="sellItem.status"
          />
        </div>
      </div>

      <NewInvestmentsSellItemPanel
        :is-second-market="store.isSecondaryMarketOperator"
        :item="sellItem"
        :is-loading-operation="isLoadingOperation"
        @handle-operation="handleOperation"
      />

      <NewInvestmentsSellItemTableDesktop
        v-if="!isTablet"
        :items="investmentsListDesktop"
        :is-second-market="store.isSecondaryMarketOperator"
        :is-loading="isLoading"
        :items-count="investmentsListCount"
        @fetch-list="changeInvestmentsListPageDesktop"
      />
      <NewInvestmentsSellItemTableMobile
        v-if="isTablet"
        :items="investmentsListMobile"
        :is-loading="isLoading"
        :items-count="investmentsListCount"
        :is-second-market="store.isSecondaryMarketOperator"
        @load-more-list="moreInvestmentsListMobile"
      />
    </template>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import server from '@/server';
import { formatDate } from '@/utils/commonUtils/libUtils';
import { downloadFile, parseFilename } from '@/utils/commonUtils/utils';
import detectDevice from '@/composables/detectDeviceComposable';
import { useStateStore } from '@/store/stateStore';
import { Loader } from 'potok-uikit';
import NewInvestmentsSellItemPanel from '@/components/Investments/NewInvestmentsSellItem/NewInvestmentsSellItemPanel.vue';
import NewInvestmentsSellListBtnStatus from '@/components/Investments/NewInvestmentsSellList/NewInvestmentsSellListBtnStatus.vue';
import NewInvestmentsSellItemTableDesktop from '@/components/Investments/NewInvestmentsSellItem/NewInvestmentsSellItemTable/NewInvestmentsSellItemTableDesktop.vue';
import NewInvestmentsSellItemTableMobile from '@/components/Investments/NewInvestmentsSellItem/NewInvestmentsSellItemTable/NewInvestmentsSellItemTableMobile.vue';

const { isTablet } = detectDevice();

const store = useStateStore();
const route = useRoute();
const router = useRouter();

const isLoading = ref(true);
const isLoadingOperation = ref(false);

const sellItem = ref(null);

const investmentsFullListDesktop = ref([]);
const investmentsFullListMobile = ref([]);
const investmentsListDesktop = ref([]);
const investmentsListMobile = ref([]);

const investmentsListCount = ref(0);

const operationByOperatorStatus = ref('');

const sellItemId = computed(() => {
  return route.params.id;
});
const sellItemCreatedAt = computed(() => {
  return formatDate(
    sellItem.value?.createdAt,
    'dd.MM.yyyy HH:mm',
    'Europe/Moscow',
  );
});

const handleOperation = (obj) => {
  if (obj.status === 'cancel') {
    cancelInvestmentsSellItem();
  } else {
    operationByOperatorStatus.value = obj.status;
    putInvestmentsSellItemByOperator(obj);
  }
};

const putInvestmentsSellItemByOperator = ({ status, comment }) => {
  isLoadingOperation.value = true;

  const query = {
    status,
  };

  if (status === 'rejected' && comment) {
    query.comment = comment;
  }

  return server.putInvestmentsSellItemByOperator
    .send(query, {
      params: { id: sellItem.value.id },
    })
    .pipe(onFetchPutInvestmentsSellItemByOperatorSuccess, () => {
      isLoadingOperation.value = false;
    })
    .exec();
};
const onFetchPutInvestmentsSellItemByOperatorSuccess = () => {
  isLoadingOperation.value = false;
  const actionText =
    operationByOperatorStatus.value === 'approved'
      ? 'подтверждена'
      : 'отклонена';
  store.setNotification({
    timer: 5000,
    message: `заявка №${sellItem.value.number} ${actionText}`,
    title: `заявка ${actionText}`,
  });
  router.push({
    name: 'newInvestmentsSellList',
  });
};

const cancelInvestmentsSellItem = () => {
  isLoadingOperation.value = true;

  return server.putInvestmentsSellItem
    .send(undefined, {
      params: {
        id: sellItem.value.id,
        companyId: sellItem.value.companyId,
      },
    })
    .pipe(onFetchPutInvestmentsSellItemSuccess, () => {
      isLoadingOperation.value = false;
    })
    .exec();
};
const onFetchPutInvestmentsSellItemSuccess = () => {
  isLoadingOperation.value = false;
  store.setNotification({
    timer: 5000,
    message: `заявка №${sellItem.value.number} отменена`,
    title: 'заявка отменена',
  });
  router.push({
    name: 'newInvestmentsSellList',
  });
};

const downloadOffer = () => {
  return server.getDocument
    .send(null, {
      params: { id: sellItem.value.documentId },
    })
    .pipe(onFetchDownloadOfferSuccess, () => {})
    .exec();
};
const onFetchDownloadOfferSuccess = ({ data, response }) => {
  if (response.status === 200) {
    const filename = parseFilename(response);
    downloadFile(filename, data);
  }
};

const changeInvestmentsListPageDesktop = (obj) => {
  const startSlice =
    obj.currentPage * obj.selectedPageRange - obj.selectedPageRange;
  investmentsListDesktop.value = investmentsFullListDesktop.value.slice(
    startSlice,
    startSlice + obj.selectedPageRange,
  );
};

const moreInvestmentsListMobile = (obj) => {
  const additionalListArr = investmentsFullListMobile.value.slice(
    investmentsListMobile.value.length,
    investmentsListMobile.value.length + obj.selectedPageRange,
  );
  additionalListArr.forEach((el) => {
    investmentsListMobile.value.push(el);
  });
};

const getInvestmentsSellItemInit = () => {
  isLoading.value = true;

  const itemId = sellItemId.value;

  let query;

  if (store.isSecondaryMarketOperator) {
    query = null;
  } else {
    query = {
      companyId: store.selectedCompanyId,
    };
  }

  return server.getInvestmentsSellItem
    .send(query, {
      params: { id: itemId },
    })
    .pipe(onFetchInvestmentsSellItemInitSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};
const onFetchInvestmentsSellItemInitSuccess = (obj) => {
  isLoading.value = false;
  sellItem.value = obj.data;
  investmentsFullListDesktop.value = obj.data.salePortfolioRequestInvestments;
  investmentsFullListMobile.value = obj.data.salePortfolioRequestInvestments;
  investmentsListCount.value = obj.data.salePortfolioRequestInvestments.length;
  changeInvestmentsListPageDesktop({
    currentPage: 1,
    selectedPageRange: 20,
  });
  moreInvestmentsListMobile({
    selectedPageRange: 5,
  });
};

const init = () => {
  if (!store.isAdmin && !store.selectedCompany.isPortfolioSaleAvailable) {
    router.push({ name: 'investments' });
  }
  getInvestmentsSellItemInit();
};
init();
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;
@use '../../scss/typography.scss' as *;

.investments-sell-item {
  padding-bottom: 20px;
}
.investments-sell-item-header-created-at {
  color: $grey;
  @media (max-width: $size_991) {
    @include potok-text-uppercase;
  }
}
.investments-sell-item-header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.investments-sell-item-header-title {
  color: $deep_dive;
  @media (max-width: $size_991) {
    @include potok-text-body-1;
  }
}
.investments-sell-item-header-offer-download {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $deep_dive;
  i {
    position: relative;
    top: 1px;
  }
}
</style>
