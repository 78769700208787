<template>
  <PotokPage
    class="auto_invest_config__wrapper"
    title="ручная настройка автоинвеста"
  >
    <template v-if="loadingSettings || !isUserLoaded">
      <Loader class="mt-5" />
    </template>

    <template v-if="isUserLoaded && !loadingSettings">
      <div class="auto_invest_config">
        <div class="auto_invest_config__content">
          <div class="block_content">
            <div>
              <Checkbox
                v-model="selectedProjectStandard"
                :title="'стандартные займы'"
                :disabled="isPending"
              />
              <template v-if="selectedProjectStandard">
                <div>
                  <div class="subtitle">сумма инвестиций</div>
                  <div class="input_block">
                    <InputElement
                      v-model="maxInvestmentAmount"
                      :validation="v$.maxInvestmentAmount"
                      :disabled="isPending"
                      :placeholder="'20000000'"
                      :title="'максимальная сумма инвестиций в 1 заемщика'"
                      :style="{ width: '300px' }"
                      :vmaska="masks.digits(15)"
                    />
                  </div>
                  <div class="input_block">
                    <InputElement
                      v-model="maxDiversificationPercentage"
                      :validation="v$.maxDiversificationPercentage"
                      :disabled="isPending"
                      :placeholder="'2.0'"
                      :title="'максимальная доля инвестиций в 1 заемщика'"
                      :style="{ width: '300px' }"
                    />
                  </div>
                </div>
                <div style="margin-top: 30px">
                  <div class="subtitle">тип займов</div>
                  <Radiobox
                    v-model="selectedProjectType"
                    :items="[
                      {
                        title: 'на развитие бизнеса + госзаказ',
                        value: 'AllProjects',
                      },
                      {
                        title: 'только развитие бизнеса',
                        value: 'isBusinessDevelopment',
                      },
                      {
                        title: 'только госзаказ',
                        value: 'isGovernmentOrder',
                      },
                    ]"
                    style="margin-top: 20px"
                  />
                </div>
                <div>
                  <VDropdown placement="bottom-start">
                    <div class="subtitle">категории риска</div>
                    <template #popper>
                      <div class="popup">
                        <div v-close-popper class="popup-close"></div>
                        <div class="popup-text">
                          включает в автоинвестирование займы категории
                          "повышенный риск" с более высокой процентной ставкой.
                          подробнее про займы категории
                          <a
                            href="https://investor.potok.digital/faq#12"
                            target="_blank"
                            >“повышенный риск”</a
                          >.
                        </div>
                      </div>
                    </template>
                  </VDropdown>

                  <Radiobox
                    v-model="selectedProjectRiskCategory"
                    :items="[
                      {
                        title: 'стандартный + повышенный риск',
                        value: 'AllRisk',
                      },
                      {
                        title: 'только стандартный риск',
                        value: 'isStandard',
                      },
                      {
                        title: 'только повышенный риск',
                        value: 'isHigh',
                      },
                    ]"
                  />
                </div>
                <div>
                  <VDropdown placement="bottom-start">
                    <div class="subtitle">категории заемщиков</div>
                    <template #popper>
                      <div class="popup">
                        <div v-close-popper class="popup-close"></div>
                        <div class="popup-text">
                          ограничивает автоинвестирование только указанной
                          категорией заемщика. процентная ставка у новых
                          заемщиков, как правило, выше чем у повторных.
                          повторным заемщикам предоставляется меньшая ставка.
                        </div>
                      </div>
                    </template>
                  </VDropdown>

                  <Radiobox
                    v-model="selectedBorrowerType"
                    :items="[
                      {
                        title: 'новые + повторные заемщики',
                        value: 'AllLoan',
                      },
                      {
                        title: 'только повторные заемщики',
                        value: 'isRepeatLoan',
                      },
                      {
                        title: 'только новые заемщики',
                        value: 'isFirstLoan',
                      },
                    ]"
                  />
                </div>
              </template>
            </div>
          </div>
          <div class="block_content" style="margin-top: 30px">
            <div>
              <Checkbox
                v-model="selectedProjectFactoring"
                :title="'факторинговые займы'"
                :disabled="isPending"
              />
              <template v-if="selectedProjectFactoring">
                <div class="subtitle"></div>
                <div class="input_block">
                  <InputElement
                    v-model="maxFactoringDiversificationPercentage"
                    :validation="v$.maxFactoringDiversificationPercentage"
                    :disabled="savingSettings || !selectedProjectFactoring"
                    :placeholder="'2.0'"
                    :style="{ width: '300px' }"
                    :title="'максимальня доля инвестиций в факторинг'"
                  />
                </div>
                <div class="input_block">
                  <InputElement
                    v-model="maxFactoringLoanInvestmentAmount"
                    :validation="v$.maxFactoringLoanInvestmentAmount"
                    :disabled="savingSettings || !selectedProjectFactoring"
                    :placeholder="'20000000'"
                    :title="'максимальная сумма инвестиций в 1 займ'"
                    :style="{ width: '300px' }"
                    :vmaska="masks.digits(15)"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="button button-primary button-personal"
          style="margin-top: 30px"
          :disabled="!isEnableSaving"
          @click="onClickSaveSettings()"
        >
          сохранить настройки
        </button>
      </div>
    </template>

    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="savingSettings"
      :confirm-function="onClickConnectStrategyConfirm"
      :error-message="confirmErrorMessage"
      :confirm-message="'подтвердите изменения персональных настроек автоинвеста'"
      @close="isShowModalConfirm = false"
    />
    <ModalFull v-if="errorMessage" @close="onCloseErrorHandler">
      <template #content>
        <div v-html="errorMessage" />
      </template>
    </ModalFull>
  </PotokPage>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

import masks from '@/utils/masks';
import server from '@/server';
import useVuelidate from '@vuelidate/core';
import validators from '@/validators';
import { helpers } from '@vuelidate/validators';
import { PotokPage, Loader } from 'potok-uikit';

import Checkbox from '@/components/_generic/Checkbox.vue';
import Radiobox from '@/components/_generic/Radiobox.vue';
import InputElement from '@/components/_generic/InputElement.vue';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

import { useStateStore } from '@/store/stateStore';

const customFactoringRequired = helpers.withParams(
  { type: 'required' },
  (v, parentVm) => {
    if (parentVm.selectedProjectFactoring) {
      if (!v || v <= 0) {
        return false;
      }
    }
    return true;
  },
);

const customFactoringBetween = helpers.withParams(
  { type: 'between' },
  (v, parentVm) => {
    if (parentVm.selectedProjectFactoring) {
      if (v < 0 || v > 100) {
        return false;
      }
    }
    return true;
  },
);

const store = useStateStore();
const router = useRouter();

const loadingSettings = ref(false);
const savingSettings = ref(false);
const errorMessage = ref(null);
const confirmErrorMessage = ref(null);
const isShowModalConfirm = ref(false);
const selectedBorrowerType = ref('AllLoan');
const selectedProjectRiskCategory = ref('AllRisk');
const selectedProjectType = ref('AllProjects');
const selectedProjectStandard = ref(true);
const selectedProjectFactoring = ref(false);
const maxDiversificationPercentage = ref(2.0);
const maxInvestmentAmount = ref(50000);
const maxFactoringDiversificationPercentage = ref(20.0);
const maxFactoringLoanInvestmentAmount = ref(50000);
const isNotPersonalStrategy = ref(false);
const settingsId = ref(null);

const rulesValidations = {
  maxInvestmentAmount: {
    required: validators.required,
    minValue1000: validators.minValue(1000),
    maxValue: validators.maxValue(1000000),
    dividedBy: validators.dividedBy(1000),
  },
  maxDiversificationPercentage: {
    required: validators.required,
    between: validators.between(0, 100),
    currency: validators.currency,
  },
  maxFactoringDiversificationPercentage: {
    required: customFactoringRequired,
    between: customFactoringBetween,
    currency: validators.currency,
  },
  maxFactoringLoanInvestmentAmount: {
    required: customFactoringRequired,
    minValue400: validators.minValue(400),
  },
};
const v$ = useVuelidate(rulesValidations, {
  maxInvestmentAmount,
  maxDiversificationPercentage,
  maxFactoringDiversificationPercentage,
  maxFactoringLoanInvestmentAmount,
});

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const isPending = computed(() => {
  return loadingSettings.value || savingSettings.value;
});
const isEnableSaving = computed(() => {
  if (
    !isPending.value &&
    (selectedProjectStandard.value || selectedProjectFactoring.value) &&
    !v$.value.maxDiversificationPercentage.$invalid &&
    !v$.value.maxFactoringDiversificationPercentage.$invalid &&
    !(selectedProjectStandard.value && v$.value.maxInvestmentAmount.$invalid) &&
    !v$.value.maxFactoringLoanInvestmentAmount.$invalid
  ) {
    return true;
  }
  return false;
});

watch(selectedCompanyId, () => {
  getAutoInvestSettingsAsync();
});

onMounted(() => {
  if (selectedCompanyId.value) {
    getAutoInvestSettingsAsync();
  }
});

const getAutoInvestSettingsAsync = () => {
  isNotPersonalStrategy.value = null;
  loadingSettings.value = true;
  return server.getCompanyAutoInvestSettings
    .send(null, { params: { id: selectedCompanyId.value } })
    .pipe(
      onGetAutoInvestSettingsSuccess.bind(this),
      onGetAutoInvestSettingsError.bind(this),
    )
    .exec();
};
const onGetAutoInvestSettingsSuccess = (obj) => {
  loadingSettings.value = false;
  const result = obj.data || {};
  if (
    result.strategy !== 'personal_borrower_distribution' ||
    obj.response.status === 204
  ) {
    isNotPersonalStrategy.value = true;
  } else {
    initPersonalStrategy(result.settings);
  }
};

const onGetAutoInvestSettingsError = () => {
  loadingSettings.value = false;
};

const initPersonalStrategy = (settings) => {
  if (settings.borrowerType.isFirstLoan && settings.borrowerType.isRepeatLoan) {
    selectedBorrowerType.value = 'AllLoan';
  }
  if (
    settings.borrowerType.isFirstLoan &&
    !settings.borrowerType.isRepeatLoan
  ) {
    selectedBorrowerType.value = 'isFirstLoan';
  }
  if (
    !settings.borrowerType.isFirstLoan &&
    settings.borrowerType.isRepeatLoan
  ) {
    selectedBorrowerType.value = 'isRepeatLoan';
  }

  maxDiversificationPercentage.value = settings.maxDiversificationPercentage;
  maxFactoringDiversificationPercentage.value =
    settings.maxFactoringDiversificationPercentage;
  maxFactoringLoanInvestmentAmount.value =
    settings.maxFactoringLoanInvestmentAmount;
  maxInvestmentAmount.value = settings.maxInvestmentAmount;

  if (
    settings.projectRiskCategory.isHigh &&
    settings.projectRiskCategory.isStandard
  ) {
    selectedProjectRiskCategory.value = 'AllRisk';
  }
  if (
    !settings.projectRiskCategory.isHigh &&
    settings.projectRiskCategory.isStandard
  ) {
    selectedProjectRiskCategory.value = 'isStandard';
  }
  if (
    settings.projectRiskCategory.isHigh &&
    !settings.projectRiskCategory.isStandard
  ) {
    selectedProjectRiskCategory.value = 'isHigh';
  }

  if (
    settings.projectType.isBusinessDevelopment &&
    settings.projectType.isGovernmentOrder
  ) {
    selectedProjectType.value = 'AllProjects';
  }
  if (
    !settings.projectType.isBusinessDevelopment &&
    settings.projectType.isGovernmentOrder
  ) {
    selectedProjectType.value = 'isGovernmentOrder';
  }
  if (
    settings.projectType.isBusinessDevelopment &&
    !settings.projectType.isGovernmentOrder
  ) {
    selectedProjectType.value = 'isBusinessDevelopment';
  }
  if (
    !settings.projectType.isBusinessDevelopment &&
    !settings.projectType.isGovernmentOrder
  ) {
    selectedProjectStandard.value = false;
  }

  selectedProjectFactoring.value = settings.projectType.isTypeFactoring;
};
const generatePersonalStrategy = () => {
  let settings = {
    borrowerType: {},
    market: {
      secondary: false,
    },
    projectRiskCategory: {},
    projectType: {
      isTypeFactoring: selectedProjectFactoring.value,
    },
  };
  if (selectedProjectStandard.value) {
    if (selectedBorrowerType.value === 'AllLoan') {
      settings.borrowerType.isFirstLoan = true;
      settings.borrowerType.isRepeatLoan = true;
    }
    if (selectedBorrowerType.value === 'isFirstLoan') {
      settings.borrowerType.isFirstLoan = true;
      settings.borrowerType.isRepeatLoan = false;
    }
    if (selectedBorrowerType.value === 'isRepeatLoan') {
      settings.borrowerType.isFirstLoan = false;
      settings.borrowerType.isRepeatLoan = true;
    }
    if (selectedProjectRiskCategory.value === 'AllRisk') {
      settings.projectRiskCategory.isHigh = true;
      settings.projectRiskCategory.isStandard = true;
    }
    if (selectedProjectRiskCategory.value === 'isStandard') {
      settings.projectRiskCategory.isHigh = false;
      settings.projectRiskCategory.isStandard = true;
    }
    if (selectedProjectRiskCategory.value === 'isHigh') {
      settings.projectRiskCategory.isHigh = true;
      settings.projectRiskCategory.isStandard = false;
    }
    if (selectedProjectType.value === 'AllProjects') {
      settings.projectType.isBusinessDevelopment = true;
      settings.projectType.isGovernmentOrder = true;
    }
    if (selectedProjectType.value === 'isGovernmentOrder') {
      settings.projectType.isBusinessDevelopment = false;
      settings.projectType.isGovernmentOrder = true;
    }
    if (selectedProjectType.value === 'isBusinessDevelopment') {
      settings.projectType.isBusinessDevelopment = true;
      settings.projectType.isGovernmentOrder = false;
    }
    settings.maxDiversificationPercentage = maxDiversificationPercentage.value;
    settings.maxInvestmentAmount = maxInvestmentAmount.value;
  } else {
    settings.maxDiversificationPercentage = 2;
    settings.maxInvestmentAmount = 50000;
    settings.borrowerType.isFirstLoan = false;
    settings.borrowerType.isRepeatLoan = false;
    settings.projectRiskCategory.isHigh = false;
    settings.projectRiskCategory.isStandard = false;
    settings.projectType.isBusinessDevelopment = false;
    settings.projectType.isGovernmentOrder = false;
  }

  if (selectedProjectFactoring.value) {
    settings.maxFactoringDiversificationPercentage =
      maxFactoringDiversificationPercentage.value;
    settings.maxFactoringLoanInvestmentAmount =
      parseFloat(maxFactoringLoanInvestmentAmount.value) || 0;
  } else {
    settings.maxFactoringLoanInvestmentAmount = 50000;
    settings.maxFactoringDiversificationPercentage = 20;
  }
  return settings;
};
const onClickSaveSettings = () => {
  savingSettings.value = true;
  confirmErrorMessage.value = null;
  errorMessage.value = null;
  const query = {
    strategy: 'personal_borrower_distribution',
    settings: generatePersonalStrategy(),
  };
  return server.postCompanyAutoInvestSettings
    .send(query, { params: { id: selectedCompanyId.value } })
    .pipe(
      onPostAutoInvestSettingsSuccess.bind(this),
      onPostAutoInvestSettingsError.bind(this),
    )
    .exec();
};
const onPostAutoInvestSettingsSuccess = (obj) => {
  savingSettings.value = false;
  const result = obj.data || {};
  if (!result.isSuccessful) {
    errorMessage.value = result.message;
  }
  if (result.isSuccessful && result.settingsId) {
    settingsId.value = result.settingsId;
    isShowModalConfirm.value = true;
  }
};
const onPostAutoInvestSettingsError = () => {
  savingSettings.value = false;
};
const onClickConnectStrategyConfirm = ({ code }) => {
  confirmErrorMessage.value = null;
  savingSettings.value = true;
  const query = {
    code: code,
  };
  return server.putCompanyAutoInvestSettings
    .send(query, {
      params: { id: selectedCompanyId.value, settingsId: settingsId.value },
    })
    .pipe(
      onPutAutoInvestSettingsSuccess.bind(this),
      onPutAutoInvestSettingsError.bind(this),
    )
    .exec();
};
const onPutAutoInvestSettingsSuccess = (obj) => {
  savingSettings.value = false;
  const result = obj.data || {};
  if (result.isSuccessful) {
    isShowModalConfirm.value = false;
    store.fetchCompanies();
    router.push({
      name: 'main',
    });
  } else {
    if (result.message) {
      confirmErrorMessage.value = result.message;
    } else {
      confirmErrorMessage.value =
        'Неизвестная ошибка. Обратитесь в тех. поддержку';
    }
  }
};
const onPutAutoInvestSettingsError = () => {
  savingSettings.value = false;
};
const onCloseErrorHandler = () => {
  errorMessage.value = null;
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.auto_invest_config__wrapper {
  min-height: 200px;
  text-transform: none;
  .auto_invest_config {
    position: relative;
    margin-bottom: 40px;
    padding: 0px;

    .auto_invest_config__content {
      margin-top: 30px;

      .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $deep_dive;
        margin-top: 26px;
      }
    }
  }
  .block_content {
    background: #ffffff;
    box-shadow: 0 1px 4px #e5e9f2;
    border-radius: 14px;
    padding: 20px;
    @media (max-width: $size_767) {
      padding: 16px 12px;
    }
  }
  .input_text {
    max-width: 120px;
  }
  .text_desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5c5c5c;
  }
  .input_block {
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      margin: 10px 0;
    }
  }

  .button-personal {
    @media (max-width: $size_767) {
      width: 100%;
    }
  }

  .content_chart_description {
    margin-left: 30px;
    color: $primary;
    min-width: 140px;
  }
}
</style>
