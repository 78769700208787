import { http } from '@/libs/browser-http';
import builtInPipes from './pipes';
import users from './modules/users.js';
import companies from './modules/companies.js';
import projects from './modules/projects.js';
import documents from './modules/documents.js';
import notifications from './modules/notifications.js';
import investments from './modules/investments.js';
import borrowers from './modules/borrowers.js';
import analytics from './modules/analytics';
import features from './modules/features';
import teleport from './modules/teleport';

export function AppServer(options = {}, pipes = builtInPipes) {
  let that = new http.Server(options, pipes);

  that.addActions({
    ...users,
    ...companies,
    ...projects,
    ...documents,
    ...notifications,
    ...investments,
    ...borrowers,
    ...analytics,
    ...features,
    ...teleport,
    headAppMetadata: {
      url: `/`,
      cache: 'no-cache',
      method: 'HEAD',
      headers: {},
    },
    getReceiptByInvestmentId: {
      url: `/api/receipts/by-investment-id`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getAutoInvestDocument: {
      url: `/api/documents/:id/`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getCompanyTypes: {
      url: `/api/company-types`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getProjectStatuses: {
      url: `/api/project-statuses`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getOverdueChecklistTypes: {
      url: `/api/overdue-checklist/types`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getOverdueChecklistList$ProjectId: {
      url: `/api/overdue-checklist/list/:projectId`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getOverdueChecklistFile$DocumentId: {
      url: `/api/overdue-checklist/file/:documentId`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getCompanyReachStatuses: {
      url: `/api/company-reach-statuses`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getContractStatuses: {
      url: `/api/contract-statuses`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    getFounders: {
      url: `/api/founders/:companyId`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    },
    putFounder: {
      url: `/api/founders`,
      cache: 'no-cache',
      method: 'PUT',
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      body: null,
    },
    postFounder: {
      url: `/api/founders`,
      cache: 'no-cache',
      method: 'POST',
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      body: {
        companyId: '',
      },
    },
    getPlatformStat: {
      url: `/api/platform/statistics`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: {},
    },
    getPlatformStatFuture: {
      url: `/api/platform/statistics/future`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
    getPlatformIndexes: {
      url: `/api/platform/indexes`,
      cache: 'no-cache',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
    getStrategies: {
      url: `/api/strategies`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  });

  /**
   * @param {String} actionKeys...
   * @return {Boolean}
   */
  that.isPending = function (...actionKeys) {
    return actionKeys.some((key) => that[key].isPending);
  };

  that.isAnythingPending = function () {
    const allProps = Object.keys(that);
    const allActions = allProps.filter((prop) => {
      return that[prop].constructor.name === 'Action';
    });

    return allActions.some((key) => that[key].isPending);
  };

  return that;
}

export default AppServer();
