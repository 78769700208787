import { ref, computed, reactive, watch } from 'vue';

import InvestmentsFilterItemsFunded from './investmentsFiltersComposable/InvestmentsFilterItemsFunded.js';
import InvestmentsFilterItemsRefunded from './investmentsFiltersComposable/InvestmentsFilterItemsRefunded.js';
import InvestmentsFilterItemsRestructured from './investmentsFiltersComposable/InvestmentsFilterItemsRestructured.js';
import InvestmentsFilterItemsOverdue from './investmentsFiltersComposable/InvestmentsFilterItemsOverdue.js';
import InvestmentsFilterItemsDefault from './investmentsFiltersComposable/InvestmentsFilterItemsDefault.js';
import InvestmentsFilterItemsBuybackCurrent from './investmentsFiltersComposable/InvestmentsFilterItemsBuybackCurrent.js';
import InvestmentsFilterItemsBoughtBack from './investmentsFiltersComposable/InvestmentsFilterItemsBoughtBack.js';

import detectDevice from '@/composables/detectDeviceComposable';

import { STATUSES } from '../../constants/Investments/investmentsConstants';

export default function investmentsFiltersComposable() {
  const filters = reactive({
    filters: {},
  });
  const { isMobile } = detectDevice();

  const selectedPageRangeDesktop = ref(20);
  const selectedPageRangeMobile = ref(20);
  const currentPage = ref(1);

  const firstPageRangeDesktop = computed(() => {
    return (currentPage.value - 1) * selectedPageRangeDesktop.value;
  });
  const lastPageRangeDesktop = computed(() => {
    return (
      firstPageRangeDesktop.value + parseInt(selectedPageRangeDesktop.value)
    );
  });
  const lastPageRangMobile = computed(() => {
    return currentPage.value * selectedPageRangeMobile.value;
  });

  const pagedItems = (items) => {
    if (isMobile.value) {
      return items.slice(0, lastPageRangMobile.value);
    }
    return items.slice(firstPageRangeDesktop.value, lastPageRangeDesktop.value);
  };

  const filteredItems = ({ items, status }) => {
    if (Object.keys(filters.filters).length === 0) {
      return items;
    }
    switch (status) {
      case STATUSES.funded:
        return InvestmentsFilterItemsFunded(items, filters.filters);
      case STATUSES.default:
        return InvestmentsFilterItemsDefault(items, filters.filters);
      case STATUSES.restructured:
        return InvestmentsFilterItemsRestructured(items, filters.filters);
      case STATUSES.softOverdue:
        return InvestmentsFilterItemsOverdue(items, filters.filters);
      case STATUSES.refunded:
        return InvestmentsFilterItemsRefunded(items, filters.filters);
      case STATUSES.sold:
        return InvestmentsFilterItemsBoughtBack(items, filters.filters);
      case STATUSES.buyback_current:
        return InvestmentsFilterItemsBuybackCurrent(items, filters.filters);
    }
    return items;
  };

  const updateFilters = (filterData) => {
    if (filterData) {
      filters.filters = filterData;
    }
  };
  const updateMobileFilters = (obj, selectSortColumnMobile) => {
    if (!obj) {
      return;
    }
    if (obj.filters) {
      filters.filters = obj.filters;
    }
    if (obj.sortField) {
      selectSortColumnMobile({
        sortField: obj.sortField,
      });
    }
  };

  watch(selectedPageRangeDesktop, () => {
    currentPage.value = 1;
  });

  return {
    selectedPageRangeDesktop,
    selectedPageRangeMobile,
    currentPage,
    filters,
    pagedItems,
    filteredItems,
    updateFilters,
    updateMobileFilters,
  };
}
