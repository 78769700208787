import AbstractTracker from './AbstractTracker';
import amplitude from 'amplitude-js';

export default class AmplitudeTracker extends AbstractTracker {
  /**
   * @see https://developers.amplitude.com/docs/javascript
   * @param {Object} options
   * @param {Boolean} isProduction
   */
  constructor(options, isProduction) {
    super(options, isProduction);
  }
  /**
   * @param {Object} options
   */
  initialize(options) {
    if (amplitude) {
      this.amplitudeInstance = amplitude.getInstance();

      this.run = this[this.constructor.RUN];

      this.amplitudeInstance.init(options.apiKey);
    }
  }
  /**
   * @param {String} command
   * @param {...*} [opts]
   * @see https://developers.amplitude.com/docs/tracking-events
   */
  execCommand([command, ...opts]) {
    this.amplitudeInstance[command](...opts);
  }
}
