<template>
  <div ref="wrapper" class="horizontal-scroll-wrapper">
    <div
      class="button-container button-container-left"
      :class="{ hidden: isLeftArrayHidden }"
    >
      <button ref="leftArray" class="array-button" @click="arrayHandle('left')">
        <i class="horizontal-scroll-array array-left" />
      </button>
      <div class="button-left-gradient" />
    </div>

    <div ref="list" class="horizontal-scroll-list" @wheel="mouseWheel">
      <!--передаем сюда контейнер, внутри которого список скролящихся элементов -->
      <slot />
    </div>

    <div
      class="button-container button-container-right"
      :class="{ hidden: isRightArrayHidden }"
    >
      <div class="button-right-gradient" />
      <button
        ref="rightArray"
        class="array-button"
        @click="arrayHandle('right')"
      >
        <i class="horizontal-scroll-array array-right" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
  scrollDuration: {
    type: Number,
    default: 300,
  },
});

const isLeftArrayHidden = ref(true);
const isRightArrayHidden = ref(true);

const leftArray = ref(null);
const rightArray = ref(null);
const wrapper = ref(null);
const list = ref(null);
const listChild = ref(null);

let intervalId = null;

const checkRightArray = () => {
  isRightArrayHidden.value =
    listChild.value.scrollLeft + listChild.value.offsetWidth ===
    listChild.value.scrollWidth;
};
const checkLeftArray = () => {
  isLeftArrayHidden.value =
    listChild.value.scrollLeft + listChild.value.offsetWidth ===
    listChild.value.offsetWidth;
};

const rightScroll = (value) => {
  listChild.value.scrollLeft += value;
};

const leftScroll = (value) => {
  listChild.value.scrollLeft -= value;
};

const mouseWheel = (event) => {
  if (listChild.value.offsetWidth === listChild.value.scrollWidth) {
    return;
  }

  event.preventDefault();

  if (event.wheelDelta > 0) {
    leftScroll(30);
  } else {
    rightScroll(30);
  }

  checkRightArray();
  checkLeftArray();
};

const arrayHandle = (direction) => {
  if (intervalId) {
    return;
  }
  let delayAnimationSum = 0;
  const delayAnimation = props.scrollDuration / 30;

  intervalId = setInterval(() => {
    if (delayAnimationSum === props.scrollDuration) {
      clearInterval(intervalId);
      intervalId = null;
      checkRightArray();
      checkLeftArray();
    }

    if (direction === 'right') {
      rightScroll(3);
    } else {
      leftScroll(3);
    }
    delayAnimationSum += delayAnimation;
  }, delayAnimation);
};

onMounted(() => {
  // находим переданный в слот контейнер с элементами
  listChild.value = list.value.firstElementChild;
  checkRightArray();
});
</script>

<style scoped lang="scss">
@use '../../scss/variables.scss' as *;

.horizontal-scroll-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}
.horizontal-scroll-list {
  box-sizing: border-box;

  &::-webkit-scrollbar {
    /* Chrome/Opera/Safari */
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.array-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 20px;
  padding: 0;
  border: none;
  outline: none;
  z-index: 2;
  background: #fff;
}

.button-container {
  position: absolute;
  display: flex;
  height: 100%;
}
.button-container-left {
  left: 0;
}
.button-left-gradient {
  height: 100%;
  width: 20px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
.button-container-right {
  right: 0;
}
.button-right-gradient {
  height: 100%;
  width: 20px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

.horizontal-scroll-array {
  position: relative;
  top: 1px;
  display: block;
  height: 8px;
  width: 8px;
}
.array-left {
  background: url('@img/scroll-left-array.svg');
}
.array-right {
  background: url('@img/scroll-right-array.svg');
}

.hidden {
  display: none;
}
</style>
