export default {
  getBorrower: {
    url: `/api/borrowers/:borrowerId`,
    method: 'GET',
  },
  getBorrowerHistory: {
    url: `/api/borrowers/:borrowerId/history`,
    method: 'GET',
  },
  getBorrowerFactoring: {
    url: `/api/borrowers/:borrowerId/factoring`,
    method: 'GET',
  },
};
