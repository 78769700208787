<template>
  <section>
    <PotokMessage v-for="notification in notifications" :key="notification.id">
      <button
        type="button"
        class="modal-close notification-message-close"
        :disabled="loadingNotification"
        @click="onClickCloseNotification(notification)"
      >
        <span>&times;</span>
      </button>
      <div>
        <div class="alert-title" style="margin-top: -5px">
          {{ notification.subject }}
        </div>
        <div v-html="notification.message"></div>
      </div>
    </PotokMessage>
  </section>
</template>
<script>
import mixins from '@/mixins';
import PotokMessage from '@/components/_generic/PotokMessage.vue';

export default {
  components: {
    PotokMessage,
  },
  mixins: [mixins.common],
  data() {
    return {
      loadingNotification: false,
      notifications: [],
    };
  },
  mounted() {
    this.getNotification();
  },
  methods: {
    getNotification() {
      this.loadingNotification = true;
      const query = {
        isGlobal: true,
      };
      return this.server.getNotification
        .send(query)
        .pipe(
          this.onGetNotificationAsyncSuccess,
          this.onGetNotificationAsyncError,
        )
        .exec();
    },
    onGetNotificationAsyncSuccess(obj) {
      this.loadingNotification = false;
      if (obj.data) {
        this.notifications = obj.data;
      }
    },
    onGetNotificationAsyncError() {
      this.loadingNotification = false;
    },
    onClickCloseNotification(notification) {
      this.loadingNotification = true;
      return this.server.putNotification
        .send(
          {},
          {
            params: {
              notificationId: notification.id,
            },
          },
        )
        .pipe(
          this.onPutNotificationViewedAsyncSuccess,
          this.onPutNotificationViewedAsyncError,
        )
        .exec();
    },
    onPutNotificationViewedAsyncSuccess() {
      this.getNotification();
    },
    onPutNotificationViewedAsyncError() {
      this.loadingNotification = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-message-close {
  z-index: 2;
}
</style>
