<template>
  <div v-on-clickaway="onClickaway" class="range_wrapper">
    <div
      class="form-control range_wrapper__input"
      :class="{
        range__unactive: !isShowContent,
        range__active: isShowContent,
      }"
      @click="isShowContent = !isShowContent"
    >
      <div
        :class="{
          range__arrow_down: !isShowContent,
          range__arrow_up: isShowContent,
        }"
      ></div>
      <div v-if="selectedDateAccepted" class="range_content__select_content">
        {{ selectedDateAccepted }}
      </div>
      <div v-if="!selectedDateAccepted" class="range_content__placeholder">
        период
      </div>
    </div>
    <range-datepicker-calendar
      v-if="isShowContent"
      :init-date-since="props.initDateSince"
      :init-date-to="props.initDateTo"
      :periods="yearsItems"
      @change="changeDateRange"
      @close="isShowContent = false"
    />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { format, set, sub, lastDayOfYear } from 'date-fns';

import { directive as vOnClickaway } from 'vue3-click-away';
import RangeDatepickerCalendar from '@/components/_generic/rangedatapicker/RangeDatepickerCalendar.vue';

const emits = defineEmits(['change']);

const props = defineProps({
  initDateSince: Object,
  initDateTo: Object,
});

const yearsItems = ref([
  {
    title: format(set(new Date(), { month: 0, date: 1 }), 'yyyy'),
    range: [
      set(new Date(), { month: 0, date: 1 }),
      lastDayOfYear(set(new Date(), { month: 0, date: 1 })),
    ],
  },
  {
    title: format(
      sub(set(new Date(), { month: 0, date: 1 }), { years: 1 }),
      'yyyy',
    ),
    range: [
      sub(set(new Date(), { month: 0, date: 1 }), { years: 1 }),
      lastDayOfYear(sub(set(new Date(), { month: 0, date: 1 }), { years: 1 })),
    ],
  },
  {
    title: format(
      sub(set(new Date(), { month: 0, date: 1 }), { years: 2 }),
      'yyyy',
    ),
    range: [
      sub(set(new Date(), { month: 0, date: 1 }), { years: 2 }),
      lastDayOfYear(sub(set(new Date(), { month: 0, date: 1 }), { years: 2 })),
    ],
  },
  {
    title: format(
      sub(set(new Date(), { month: 0, date: 1 }), { years: 3 }),
      'yyyy',
    ),
    range: [
      sub(set(new Date(), { month: 0, date: 1 }), { years: 3 }),
      lastDayOfYear(sub(set(new Date(), { month: 0, date: 1 }), { years: 3 })),
    ],
  },
]);

const isShowContent = ref(false);
const selectedDateAccepted = ref(
  `${format(props.initDateSince, 'dd.MM.yyyy')} — ${format(props.initDateTo, 'dd.MM.yyyy')}`,
);

const changeDateRange = (data) => {
  selectedDateAccepted.value = `${format(data.dateSince, 'dd.MM.yyyy')} — ${format(data.dateTo, 'dd.MM.yyyy')}`;
  emits('change', data);
};

const onClickaway = () => {
  isShowContent.value = false;
};

watch(
  () => {
    return isShowContent.value;
  },
  (value) => {
    if (value && document.documentElement.clientWidth <= 767) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
    }
  },
);
</script>
<style lang="scss" scoped>
.range_wrapper__input {
  cursor: pointer;
  background-color: initial !important;
  display: flex;
  align-items: center;
}
</style>
